import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageErrorBar } from 'da-frontend-shared-ui-components/lib/Errors/PageErrorBar';

/** Display small error message */
const ErrorDisplay = React.memo(({ closeable=false, style, text }: { closeable?: boolean, style?: React.CSSProperties, text?: string }) => {
  const { t }         = useTranslation();
  const message       = text;

  if (message)
    return <PageErrorBar error={t(`errors.${message}`)} style={style} closeable={closeable} />
  else 
    return null;
});

export {
  ErrorDisplay
};