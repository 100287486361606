import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const primaryStatusLabelStyle: CSSProperties = {
  fontSize: '22px',
  lineHeight: 1.09,
};

export const primaryStatusValueStyle: CSSProperties = {
  fontSize: '30px',
  fontWeight: 'bold',
  lineHeight: '1.03',
};
