import React, { useCallback, useMemo } from 'react';
import {
  GeneratorStatusUnknown,
  GeneratorStatusActive,
  GeneratorStatusDisconnected,
} from 'da-frontend-shared-ui-components/lib/icons';
import { useTranslation } from 'react-i18next';

import { useCurrentGenerator } from '~/data/generator';

import SmallStatusPanel from '../small-status-panel';
import { OperationalStatus } from 'da-pcc-frontend-shared-domain';
import { icons } from 'da-frontend-shared-ui-components';

const { Online, Help, Fault, Offline, Warning } = icons;

export default function GeneratorConnection(props) {
  const [t] = useTranslation();
  const [{ generator }] = useCurrentGenerator();
  
  const {
    status: { status, isStandbyEnabled },
  } = generator;

  const isFault = status === OperationalStatus.Fault;
  const isOffline = status === OperationalStatus.Offline; 
  const isUnknown = status === OperationalStatus.Unknown;
  const isAvailable = !isOffline && isStandbyEnabled; 

  /* 
    If the operational status of the generator is
      unknown, indicate that to user and return; 
      
      otherwise:
    1. Check if the generator is Online or Offline.
    2. If Offline, proceed with status unavailable.
    3. If Online, check if generator is at fault.
    4. If at fault, proceed with status at fault.
    5. If not at fault, then check if isAvailable.
    6. If available, proceed with enabled.
    7. If not, proceed with disabled.
  */
  const label = useMemo(() => {
    if (isUnknown) {
      return t('generator.status.unknown');
    } else {
      return (!isOffline ? ( isFault ? t('generator.status.atfault') : (isAvailable ? t('generator.status.enabled') : t('generator.status.disabled'))) : t('generator.status.unavailable'));
    }    
  }, [isAvailable, isOffline, isFault, isUnknown, t]);
  
  /*
  In the current design of the UI, the generator-connection and generator-status components
  generatate icons in two ways:
  
  1) generator-connection passes a JSX.element version of the icon to the SmallStatusPanel 
    element Icon attribute
  2) generator-status DOES NOT use a JSX.element version of the icon as its SmallStatusPanel
    element Icon attribute
  
  The result is that if the same icon is displayed in both components, which happens when a power source
  has a status of Unknown or Offline, it will be rendered in different sizes.
  
  The work-around below returns the SmallStatusPanel element in two possible ways, depending
  on whether a JSX icon is needed or not.  This fixes the icon size mismatch problem for Unknown and
  offline power source statuses. 
  */
  const IconJSX = useCallback(() => {
    return (isFault ? <GeneratorStatusUnknown/> : <GeneratorStatusActive/>);
  }, [isFault]);
  
  if (isUnknown || isOffline) {
    let iconSVG = isOffline ? Offline : Help;
    return <SmallStatusPanel {...props} Icon={iconSVG} label={t('generator.title')} value={label} />; 
  }
  else 
    return <SmallStatusPanel {...props} Icon={IconJSX} label={t('generator.title')} value={label} />;
}
