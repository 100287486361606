import { makeStyles } from '@material-ui/styles';
import { defaultTheme } from 'da-frontend-shared-ui-components';
import { grayMed2 } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  dealerInfoContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '24rem',

    [defaultTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dealerInfoName: {
    fontWeight: 'bold',
  },
  dealerInfoDisabled: {
    color: grayMed2,
  },
});
