import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  buttonSpread: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      textDecoration: 'none',
    },
  },
  registrationContainer: {
    margin: '0 auto',
    maxWidth: '51rem',
    padding: '4rem 0',
  },
  pSpacing: {
    marginTop: '1.5rem',
  },
  optionsList: {
    listStylePosition: 'outside',
    marginLeft: 20,
    marginBottom: '1.5rem',
  },
});
