import React, { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { paths } from '~paths';
import { useRouter } from '~/utils/hooks';
import { Route } from '~/utils/routing';
import { useSession } from '~/data/session';
import { BannerAlert } from '~/ui/routes/-components/banner-alert';
import DashboardIndexRoute from '~/ui/routes/dashboard';
import ErrorRootRoute from '~/ui/routes/errors';
import LandingIndexRoute from '~/ui/routes/landing';
import PreferencesIndexRoute from '~/ui/routes/preferences';
import RegistrationIndexRoute from '~/ui/routes/registration';
import AppWrapper from './-components/AppWrapper';

const IndexRoute = () => {
  const [{ isAuthenticated }] = useSession();
  const {
    location: { search },
  } = useRouter();
  const redirectUri = useMemo(() => {
    const qs = queryString.parse(search);
    return qs.redirectUri as string;
  }, [search]);

  if (redirectUri) {
    return <Redirect to={redirectUri} />;
  }

  if (isAuthenticated) {
    return <Redirect to={paths.dashboard.generators.path} />;
  } else {
    return <LandingIndexRoute />;
  }
};

export default function RootPage() {
  return (
    <>
      <BannerAlert />
      <AppWrapper>
        <Switch>
          <Route exact path={paths.root.path} component={IndexRoute} />
          <Route path={paths.privacy.path} component={LandingIndexRoute} />
          <Route path={paths.termsConditions.path} component={LandingIndexRoute} />
          <Route path={paths.dashboard.path} component={DashboardIndexRoute} />
          <Route path={paths.preferences.path} component={PreferencesIndexRoute} />
          <Route path={paths.registration.path} component={RegistrationIndexRoute} />
          <Route path={paths.invite.path} component={RegistrationIndexRoute} />
          <Route component={ErrorRootRoute} />
        </Switch>
      </AppWrapper>
    </>
  );
}
