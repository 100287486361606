import React, { useContext, createContext, useMemo } from 'react';
import { useRouter } from '~/utils/hooks';
import { CurrentGeneratorState, CurrentGeneratorDispatch } from './types';
import { useGeneratorsList } from '../generators';

const GeneratorStateContext = createContext<CurrentGeneratorState | undefined>(undefined);
const GeneratorDispatchContext = createContext<CurrentGeneratorDispatch | undefined>(undefined);

export function GeneratorProvider({ children }) {
  const {
    match: {
      params: { id: assetId },
    },
  } = useRouter();

  const [{ loading, error, generators }, { receivedNGDIDeviceStatusMessage }] = useGeneratorsList();
  const state = useMemo(
    () => ({
      loading,
      generator: generators.find(({ id }) => id === assetId),
      error,
    }),
    [loading, error, generators, assetId]
  );

  return (
    <GeneratorStateContext.Provider value={state}>
      <GeneratorDispatchContext.Provider value={{ receivedNGDIDeviceStatusMessage }}>
        {children}
      </GeneratorDispatchContext.Provider>
    </GeneratorStateContext.Provider>
  );
}

function useCurrentGeneratorState() {
  const context = useContext(GeneratorStateContext);
  if (context === undefined) {
    throw new Error('useCurrentGeneratorState must be used within a GeneratorProvider');
  }
  return context;
}
function useCurrentGeneratorDispatch() {
  const context = useContext(GeneratorDispatchContext);
  if (context === undefined) {
    throw new Error('useCurrentGeneratorDispatch must be used within a GeneratorProvider');
  }
  return context;
}

export function useCurrentGenerator(): [CurrentGeneratorState, CurrentGeneratorDispatch] {
  return [useCurrentGeneratorState(), useCurrentGeneratorDispatch()];
}
