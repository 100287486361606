import React from 'react';
import { Snackbar } from '@material-ui/core';

interface IState {
  snackbarMessage: string;
  open: boolean;
}

interface IProps {
  snackbarMessage: string;
}

export default class Toast extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props);

    this.state = {
      snackbarMessage: props.snackbarMessage,
      open: true,
    };
  }

  public render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={() =>
          this.setState({
            open: false,
          })
        }
        message={<span>{this.state.snackbarMessage}</span>}
      />
    );
  }
}
