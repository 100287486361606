import { Slide } from '@material-ui/core';
import AlertBanner from 'da-frontend-shared-ui-components/lib/AlertBanner';
import * as React from 'react';
import { useAPI } from '~/data';
import { AsyncWaiter } from '~/ui/components/async-waiter';
/**
 * Display an alert if there is one active
 */
const BannerAlert = () => {
  const [dismissed, setDismissed] = React.useState(
    sessionStorage && sessionStorage.getItem('alertdismissed') ? true : false
  );
  const { alerts } = useAPI();

  const fn = React.useCallback(async () => {
    if (!dismissed) {
      const alert = await alerts.alerts({ selectedPlatform: 'ConnectCloud' });
      return alert.message;
    }
  }, [dismissed]);

  /// Set dismissed and store preference for duration of session
  const storeDismissed = () => {
    sessionStorage && sessionStorage.setItem('alertdismissed', 'true');
    setDismissed(true);
  };

  return (
    <AsyncWaiter fn={fn} loadingProps={{ style: { display: 'none' } }} ignoreError={true}>
      {({ value }) => {
        if (!value || dismissed) {
          return null;
        }
        return (
          <Slide direction='down' in={!dismissed}>
            <AlertBanner messageHtml={value} showClose={true} onClose={storeDismissed} />
          </Slide>
        );
      }}
    </AsyncWaiter>
  );
};

export { BannerAlert };
