import React from 'react';

export default function ResetElementLayout({ children, ...props }) {
  return (
    <div style={{ width: '100%' }} {...props}>
      {/*
          NOTE: this was originally inside an accordion component.

          this outer div is needed because the next outer element, the accordion
          summary details is *only* a display: flex, which doesn't allow for normal
          element flow (new div per line).
          without this outer div, the contents would be compressed into the sam erow.
        */}
      {children}
    </div>
  );
}
