import * as React from 'react';
import { FormHelperText } from '@material-ui/core';
import { ErrorMessage } from 'formik';

export interface ErrorHelperTextProps {
  name: string;
}

export const ErrorHelperText = ({ name }: ErrorHelperTextProps) => (
  <ErrorMessage
    name={name}
    render={(error: string) => <FormHelperText error={true}>{error}</FormHelperText>}
  />
);
