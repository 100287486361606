import React, { useCallback } from 'react';
import { AsyncWaiter } from '~/ui/components/async-waiter';

import ResponsiveContainer from '../../../components/responsive-container';
import { Grid } from '@material-ui/core';
import { useStyles } from '../styles';

const url = '/docs/privacy_policy.html';

export default function Privacy() {
  const styles = useStyles({});
  const fn = useCallback(async () => {
    const response = await fetch(url);
    const html = await response.text();

    return html;
  }, []);

  return (
    <AsyncWaiter fn={fn}>
      {({ value }) => {
        return (
          <ResponsiveContainer>
            <Grid container direction='row' justify='center' spacing={16}>
              <Grid item xs={12} md={10} lg={6}>
                <div className={styles.innerHTML} dangerouslySetInnerHTML={{ __html: value }} />
              </Grid>
            </Grid>
          </ResponsiveContainer>
        );
      }}
    </AsyncWaiter>
  );
}
