import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';

import reducer, { initialState } from './reducer';
import { NotificationsDispatch, NotificationsState } from './types';

import { useAPI } from '..';

import { fetchNotifications, needToVerify } from './actions';
import { useAsyncFn } from 'react-use';
import { NotificationInput, SendVerificationCodeInput } from 'da-pcc-frontend-shared-domain';

const NotificationsStateContext = createContext<NotificationsState | undefined>(undefined);
const NotificationsDispatchContext = createContext<NotificationsDispatch | undefined>(undefined);

export function NotificationsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { profile, notifications } = useAPI();
  const getSettings = useCallback(() => {
    dispatch(fetchNotifications.started());
    profile
      .getMe()
      .then(({ email, profile, accountSubscriptions }) =>
        dispatch(fetchNotifications.done({ result: { email, accountSubscriptions, ...profile } }))
      )
      .catch((error) => dispatch(fetchNotifications.failed({ error })));
  }, [profile, dispatch]);

  const updateNotificationSettings = useAsyncFn(
    async (notificationInput: NotificationInput) => {
      if (notificationInput.alternativeEmail === '') {
        notificationInput.notifyAlternativeEmail = false;
      }

      await notifications.postConnectCloudNotifications({ notificationInput });
      await getSettings();
    },
    [notifications, getSettings]
  );

  const sendVerificationCode = useAsyncFn(
    async (payload: SendVerificationCodeInput) => {
      dispatch(needToVerify());
      return profile.profileControllerSendVerificationCode({ sendVerificationCodeInput: payload });
    },
    [profile]
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider
        value={{ updateNotificationSettings, sendVerificationCode }}
      >
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
}

function useNotificationsState() {
  const context = useContext(NotificationsStateContext);
  if (context === undefined) {
    throw new Error('useNotificationsState must be used within a NotificationsProvider');
  }
  return context;
}

function useNotificationsDispatch() {
  const context = useContext(NotificationsDispatchContext);
  if (context === undefined) {
    throw new Error('useNotificationsDispatch must be used within a NotificationsProvider');
  }
  return context;
}

export function useNotifications(): [NotificationsState, NotificationsDispatch] {
  return [useNotificationsState(), useNotificationsDispatch()];
}
