import { subMonths } from 'date-fns';
import React, { createContext, useContext, useEffect, useCallback, useReducer } from 'react';
import { useAsyncFn } from 'react-use';
import { Loader } from 'da-frontend-shared-ui-components';
import {
  Event,
  EventSortableProperty,
  SortingDirection,
  EventStatusFilter,
  EventSeverity,
  LanguageType,
} from 'da-pcc-frontend-shared-domain';

import { useAPI } from '~/data';

import { useCurrentGenerator } from '..';

import { NGDIEventMessage } from '../realtime';

import * as actions from './actions';
import { CurrentGeneratorEventsDispatch, CurrentGeneratorEventsState } from './types';
import reducer, { initialState } from './reducer';

const GeneratorEventsStateContext = createContext<CurrentGeneratorEventsState | undefined>(
  undefined
);
const GeneratorEventsDispatchContext = createContext<CurrentGeneratorEventsDispatch | undefined>(
  undefined
);

const recentNotifications = (now: Date = new Date(), monthsAgo: number = 1) => ({
  pageSize: 20,
  startTimestamp: subMonths(now, monthsAgo).getTime(),
  sortBy: EventSortableProperty.Timestamp,
  sortDirection: SortingDirection.Desc,
});

const eventsParams = (date: Date) => ({
  ...recentNotifications(date),
  status: EventStatusFilter.All,
  severity: [EventSeverity.Information],
});

const alertsParams = (date: Date) => ({
  ...recentNotifications(date),
  status: EventStatusFilter.Active,
  severity: [EventSeverity.Fault, EventSeverity.Warning],
});

export function EventsProvider({ children }) {
  const { assets, events, sites } = useAPI();
  const [{ generator }] = useCurrentGenerator();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id: assetId, siteId } = generator;

  const fetchAlerts = useCallback(() => {
    const now = new Date();
    dispatch(actions.fetchAlerts.started());
    assets
      .getAssetEvents({ assetId, ...alertsParams(now) })
      .then((result) =>
        dispatch(
          actions.fetchAlerts.done({
            result,
          })
        )
      )
      .catch((error) => dispatch(actions.fetchAlerts.failed({ error })));
  }, [assetId, assets, dispatch]);

  const fetchEvents = useCallback(() => {
    const now = new Date();
    dispatch(actions.fetchEvents.started());
    assets
      .getAssetEvents({ assetId, ...eventsParams(now) })
      .then((result) =>
        dispatch(
          actions.fetchEvents.done({
            result,
          })
        )
      )
      .catch((error) => dispatch(actions.fetchEvents.failed({ error })));
  }, [assetId, assets, dispatch]);

  // The UI code expects useAsyncFn here since it relies on the AsyncState which is provided
  // This allows for tracking loading, etc.  Might refactor this later but for now this works fine
  const acknowledgeAlerts = useAsyncFn(
    async (events: Event[]) => {
      const now = new Date();
      dispatch(actions.acknowledgeAlert.started(events));
      await sites
        .siteControllerAcknowledgeSiteEvents({
          siteId,
          acknowledgeEventInput: {
            equipmentId: assetId,
            timestamps: events.map(({ timestamp }) => timestamp.getTime()),
          },
        })
        .then(() =>
          assets
            .getAssetEvents({ assetId, ...alertsParams(now) })
            .then((result) => dispatch(actions.acknowledgeAlert.done({ params: events, result })))
        )
        .catch((error) => dispatch(actions.acknowledgeAlert.failed({ params: events, error })));
    },
    [siteId, assetId, alertsParams, dispatch, actions, sites, assets]
  );

  const acknowledge: [any, (event: Event) => Promise<void>] = useAsyncFn(
    async (event: Event) => {
      const [, fn] = acknowledgeAlerts;
      return fn([event]);
    },
    [acknowledgeAlerts]
  );

  const acknowledgeAll: [any, (events: Event[]) => Promise<void>] = useAsyncFn(
    async (events: Event[]) => {
      const [, fn] = acknowledgeAlerts;
      return fn(events);
    },
    [acknowledgeAlerts]
  );

  const receivedNGDIEventMessage = useCallback(
    async (message: NGDIEventMessage) => {
      if (!message.samples) {
        console.warn('Received event message with no samples'); // tslint:disable-line no-console
      }
      for (const sample of message.samples) {
        for (const faultCode of sample.convertedEquipmentFaultCodes) {
          for (const activeFault of faultCode.activeFaultCodes) {
            if (activeFault.spn === '1001') {
              continue;
            }
            const eventMessage = await events.getTranslatedEventMessage({
              eventTranslationRequest: {
                assetId: message.equipmentId,
                code: Number(activeFault.spn),
                language: LanguageType.English,
                message: sample.convertedDeviceParameters.message || '',
                messageParts: sample.convertedDeviceParameters.messageParts,
              },
            });
            const ev: Event = {
              timestamp: new Date(sample.dateTimestamp),
              assetId: message.equipmentId,
              siteId: message.groupId,
              isAcknowledged: false,
              message: eventMessage,
              assetName: '',
              severity: activeFault.severity,
              code: Number(activeFault.spn),
            };

            dispatch(actions.prependNewEvent(ev));
          }
        }
      }
    },
    [dispatch, events]
  );

  useEffect(() => {
    fetchAlerts();
    fetchEvents();
  }, [fetchAlerts, fetchEvents]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <GeneratorEventsStateContext.Provider value={state}>
      <GeneratorEventsDispatchContext.Provider
        value={{ fetchAlerts, fetchEvents, receivedNGDIEventMessage, acknowledgeAll, acknowledge }}
      >
        {children}
      </GeneratorEventsDispatchContext.Provider>
    </GeneratorEventsStateContext.Provider>
  );
}

function useCurrentGeneratorEventsState() {
  const context = useContext(GeneratorEventsStateContext);
  if (context === undefined) {
    throw new Error('useCurrentGeneratorEventsState must be used within a EventsProvider');
  }
  return context;
}
function useCurrentGeneratorEventsDispatch() {
  const context = useContext(GeneratorEventsDispatchContext);
  if (context === undefined) {
    throw new Error('useCurrentGeneratorEventsDispatch must be used within a EventsProvider');
  }
  return context;
}

export function useCurrentGeneratorEvents(): [
  CurrentGeneratorEventsState,
  CurrentGeneratorEventsDispatch
] {
  return [useCurrentGeneratorEventsState(), useCurrentGeneratorEventsDispatch()];
}
