import { defaultTheme } from 'da-frontend-shared-ui-components';
import { makeStyles } from '@material-ui/styles';

const spacing = defaultTheme.spacing.unit;

export const useNavStyles = makeStyles({
  link: {
    display: 'block',
    textDecoration: 'none',
    marginBottom: spacing,
  },
  icon: {
    marginRight: spacing,
  },
  navButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
});
