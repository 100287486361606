import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';
import { white, redPrimary } from 'da-frontend-shared-ui-components/lib/styles/colors';
import { defaultTheme } from 'da-frontend-shared-ui-components';

import { defaultSeparation } from '~/ui/styles/defaults';

export const containerStyles: CSSProperties = {
  display: 'grid',
  gridGap: defaultSeparation,
  justifyItems: 'center',
};

export const dangerButtonStyle: CSSProperties = {
  background: redPrimary,
  color: white,
};

export const useStyles = makeStyles({
  formLabelGridItem: {
    position: 'relative',
    padding: '5px',
    alignSelf: 'center',
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  formInputGridItem: {
    padding: '5px',
  },
});
