import _i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUs from 'translations/en/translations.json';
import config from '~/config';

_i18n
  // https://github.com/i18next/i18next-icu
  .use(ICU)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // need a custom plugin that allows local translations _as well as_ remote ones
  // currently the XHR plugin makes everything remote only (it tries to make requsets)
  // to the backend even when translations already exist
  .init({
    resources: {},
    fallbackLng: 'en-US',
    // lowerCaseLng: true,
    // nsSeparator: false,
    // keySeparator: false,

    // common namespace for the app
    ns: ['translations'],
    defaultNS: 'translations',

    // debug: true,

    interpolation: {
      // react already does escaping
      escapeValue: false,
    },

    backend: {
      queryStringParam: {v: config.appVersion},
    }
  });

_i18n.addResourceBundle('en-US', 'translations', enUs, true, true);

export const i18n = _i18n;
