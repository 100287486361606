import React from 'react';
import { AsyncWaiter } from '~/ui/components/async-waiter';

import ResponsiveContainer from '../../../components/responsive-container';
import { Grid, Typography as T } from '@material-ui/core';
import { useStyles } from '../styles';

const url = '/docs/terms_and_conditions.html';

const withStyles = (Component: any) => {
  return React.forwardRef((props, ref) => {
    const styles = useStyles({});
    return <Component styles={styles} ref={ref} />;
  });
};

class WrappedTermsConditions extends React.Component<{
  title?: string;
  styles: Record<'innerHTML', string>;
}> {
  public fn = async () => {
    const response = await fetch(url);
    const html = await response.text();
    return html;
  };

  public render() {
    const { title, styles } = this.props;
    return (
      <AsyncWaiter fn={this.fn}>
        {({ value }) => {
          return (
            <ResponsiveContainer>
              <Grid container direction='row' justify='center' spacing={16}>
                <Grid item xs={12} md={10} lg={6}>
                  {title && <T variant='h1'>{title}</T>}
                  <div className={styles.innerHTML} dangerouslySetInnerHTML={{ __html: value }} />
                </Grid>
              </Grid>
            </ResponsiveContainer>
          );
        }}
      </AsyncWaiter>
    );
  }
}

export default withStyles(WrappedTermsConditions);
