import { colors } from 'da-frontend-shared-ui-components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: any) => ({
  pageHeader: {
    marginBottom: '1.25rem',
  },
  pageTitle: {
    margin: '0.625rem 0',
  },
  pageSection: {
    margin: '0.625rem 0',
  },
  dividerMain: {
    width: '100%',
  },
  dividerHighlight: {
    width: '16rem',
    height: '0.25rem',
    backgroundColor: colors.teal,
  },
  submitButton: {
    marginRight: '1.25rem',
  },
  flexEnd: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  adornedTextField: {
    position: 'absolute',
    left: '100%',
  },
  resetPassword: {
    margin: ' 0 0 0 0.625rem',
  },
  fullWidth: {
    width: '100%',
  },
  options: {
    fontFamily: 'Roboto, Arial',
    margin: '0.25rem 0',
  },
}));

export const alignLabels: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const radios: CSSProperties = {
  root: '0.3125rem 0.625rem',
};

export const alignRight: CSSProperties = {
  textAlign: 'right',
};

export const alignCenter: CSSProperties = {
  textAlign: 'center',
};

export const root: CSSProperties = {
  flexGrow: 1,
  padding: '1.25rem 1.25rem 3.125rem',
};

export const container: CSSProperties = {
  width: '100%',
  padding: '1.25rem 1.875rem',
};

export const input: CSSProperties = {
  borderRadius: '0rem',
  width: '10%',
};

export const labelAsterisk: CSSProperties = {
  color: colors.redError,
  alignSelf: 'flex-end',
};
