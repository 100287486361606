import React from 'react';
import { Redirect } from 'react-router';

import { paths } from '~paths';

import { useGeneratorsList } from '../context';

interface IProps {
  children?: any;
}

export function ValidateData({ children }: IProps) {
  const [{ generators }] = useGeneratorsList();

  return generators.length === 0 ? (
    <Redirect to={paths.preferences.generators.new.path} />
  ) : (
    children
  );
}
