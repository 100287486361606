import { CSSProperties } from 'react';
import { grayMed2 } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const topNavIconStyle: CSSProperties = {
  fontSize: '0.75rem',
  paddingRight: '5px',
};

export const mainNavStyle: CSSProperties = {
  paddingRight: '5px',
};

export const helpHeaderStyle: CSSProperties = {
  color: grayMed2,
  fontSize: '0.6875rem',
  textTransform: 'uppercase',
  fontWeight: 600,
  cursor: 'pointer',
};
