import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { paths } from '~paths';
import { useDealer } from '~/data/dealer';
import { Route } from '~/utils/routing';
import List from './list';
import Show from './show';

export default function PreferencesDealersRoute() {
  const [{ managedDealer, requestedDealer }] = useDealer();

  return (
    <Switch>
      <Route
        exact
        path={paths.preferences.dealers.path}
        component={() => {
          if (managedDealer) {
            return <Redirect to={paths.preferences.dealers.show.with({ id: managedDealer.id })} />;
          } else if (requestedDealer) {
            return (
              <Redirect
                to={paths.preferences.dealers.show.pending.with({ id: requestedDealer.id })}
              />
            );
          } else {
            return <List />;
          }
        }}
      />
      <Route path={paths.preferences.dealers.show.path} component={Show} />
    </Switch>
  );
}
