import * as React from 'react';

import { alignRight } from './styles';

export interface LabelProps {
  htmlFor?: string;
  hasError: boolean;
  children?: React.ReactNode;
  style?: any;
}

export const FormLabel = ({ children, htmlFor, hasError, ...props }: LabelProps) => (
  <label
    htmlFor={htmlFor}
    style={{ ...alignRight, ...props.style }}
    className={hasError ? 'error' : ''}
    {...props}
  >
    {children}
  </label>
);

export default FormLabel;
