import { fetchConfig } from './actions';
import { IConfigState } from './types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export const initialState: IConfigState = {
  isLoading: false,
  error: null,
};

const configReducer = reducerWithInitialState(initialState)
  .case(fetchConfig.started, (state) => ({
    ...state,
    isLoading: true,
  }))
  .case(fetchConfig.failed, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }))
  .case(fetchConfig.done, (state, { result }) => ({
    ...state,
    ...result,
    isLoading: false,
  }));

export default configReducer;
