import React from 'react';
import { Button, Typography as T, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import { useConfig } from '~/data/config';
import TagManager from 'react-gtm-module';

export default function HelpPopover() {
  const { t } = useTranslation();
  const classes = useStyles({});
  const [{ connectCloudSupportAddress }] = useConfig();

  return (
    <Grid
      container
      direction='column'
      justify='space-between'
      alignItems='flex-start'
      className={classes.container}
    >
      <Grid className={classes.body}>
        <T variant='h4' className={classes.title}>
          {t('header.helpDialog.title')}
        </T>
      </Grid>

      <div className={classes.divider} />

      <Grid className={classes.body}>
        <T className={classes.description}>{t('header.helpDialog.description')}</T>
        <Button
          component='a'
          href={`mailto:${connectCloudSupportAddress}`}
          variant='contained'
          color='secondary'
          className={classes.contactButton}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                description: "Contact Cummins Button Clicked",
                event: "Record CC Help Request",
              }
            });
          }}
        >
          {t('header.helpDialog.contactButton')}
        </Button>
      </Grid>
    </Grid>
  );
}
