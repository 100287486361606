import React from 'react';
import { useTranslation } from 'react-i18next';
import T from '@material-ui/core/Typography';

import { useCurrentGenerator } from '~/data/generator';

import RoutableAccordion from '~/ui/components/routable-accordion';

import ResetElementLayout from '~/ui/components/reset-element-layout';

import { useDate } from '~/utils/date';

import { TitleText } from '../-components/generator-management';

import { layoutStyle, titleStyle, labelStyle, valueStyle } from './styles';

import { paths } from '~paths';

export default function Maintenance() {
  const { t } = useTranslation();
  const { format } = useDate();
  const [{ generator }] = useCurrentGenerator();
  const {
    id,
    status: { lastMaintenance, engineRuntime },
  } = generator;
  const maintenancePath = paths.dashboard.generators.show.maintenance.with({ id });

  return (
    <RoutableAccordion
      {...{
        path: maintenancePath,
        exitPath: paths.dashboard.generators.show.with({ id }),
        headerContent: <TitleText>{t('generator.maintenance')}</TitleText>,
      }}
    >
      <ResetElementLayout>
        <T variant='h3' style={titleStyle}>
          {t('maintenance.preventativeTitle')}
        </T>
        <div style={layoutStyle}>
          <T variant='h4' style={labelStyle}>
            {t('maintenance.lastServiceDateLabel')}
          </T>
          <T style={valueStyle}>
            {lastMaintenance ? format(lastMaintenance) : t('maintenance.lastServiceDateNever')}
          </T>

          <div>
            <T variant='h4' style={labelStyle}>
              {t('maintenance.currentGeneratorRuntime')}
            </T>
            <T variant='body2'>{t('maintenance.currentRuntimeDescription')}</T>
          </div>
          <T style={valueStyle}>
            {engineRuntime || 0} {t('units.hours')}
          </T>
        </div>
      </ResetElementLayout>
    </RoutableAccordion>
  );
}
