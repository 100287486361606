import * as React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@material-ui/core';
import config from '~/config';
import Backsplash from './-components/backsplash';
import BrowserError from './-components/browsererror';
import useStyles from './styles';
import { paths } from '~paths';
import { Errors } from '~/ui/components/errors/errors';

const backsplashImageRingBuffer = [
  require('./images/backsplash0.png'), // tslint:disable-line no-var-requires
];

const Landing = () => {
  const [t] = useTranslation();
  const styles = useStyles({});
  const isEdge = window.navigator.userAgent.indexOf("Edge") != -1 || window.navigator.userAgent.indexOf("Edg/") != -1;
  const isExplorer = window.navigator.userAgent.indexOf("Trident") != -1 && !isEdge;
  const isFirefox = window.navigator.userAgent.indexOf("Firefox") != -1;
  let majorVersion = parseInt(navigator.appVersion, 10);
  const PccLink = t('landing.landingWelcome.PccLink');

  function browserOk() {
    if (isFirefox) {
      let offset = navigator.userAgent.indexOf("Firefox");
      majorVersion = parseInt(navigator.userAgent.substring(offset + 8));
    }
    if (isEdge && window.navigator.userAgent.indexOf("Edg/") != -1) {
      let offset = navigator.userAgent.indexOf("Edg/");
      majorVersion = parseInt(navigator.userAgent.substring(offset + 4));
    }
    if (isEdge && majorVersion < 17) { return false; }
    if (isExplorer) { return false; }
    if (isFirefox && majorVersion <= 53) { return false; }
    return true;
  }

  return (
    <>
      <BrowserError open={!browserOk()} />
      <Backsplash backsplashImages={backsplashImageRingBuffer}>
        <Grid className={styles.darkendBackground}>
          <Grid item>
            <Grid container direction='row' justify='center'>
              <Grid item xs={12} className={styles.headlineContainer}>
                <Grid container justify='center'>
                  <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
                    <Typography variant='h1' className={styles.whiteText} gutterBottom>
                      {t(`landing.landingWelcome.Headline`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
                <Typography variant='body2' className={styles.whiteText} gutterBottom>
                  {t(`landing.landingWelcome.Subhead`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={styles.marginTop}>
            <Grid container justify='center' wrap='wrap' direction='row'>
              <Button
                variant='contained'
                color='primary'
                className={`${styles.marginRight} ${styles.btnRed}`}
                href={config.paths.login}
              >
                {t(`landing.buttons.SignIn`)}
              </Button>
              <Link to={paths.registration.termsConditions.path} className={styles.noUnderline}>
                <Button variant='contained' color='primary' className={styles.btnGrey}>
                  {t(`landing.buttons.Register`)}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='center'>
              <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
                <Typography variant='body2' className={styles.note}>
                  <Trans
                    i18nKey={`landing.landingWelcome.Note`}
                    values={{ PccLink: PccLink }}>
                    If you are a <strong>DEALER</strong>, please utilize <a href={config.paths.pccUrl}>{{ PccLink }}</a> for remote monitoring.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Backsplash>
    </>
  );
};

export default Landing;
