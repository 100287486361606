import { useCallback } from 'react';
import * as uuid from 'uuid';
import { DeviceTypeCapability } from 'da-pcc-frontend-shared-domain';
import { useCurrentGenerator } from '../context';

export default function useCommandId() {
  const [{ generator }] = useCurrentGenerator();

  return useCallback(() => {
    // Arrow Devices do not implement command ack properly
    // The command ack should return a commandId which can be used as a correlation id
    // however the Arrow devices will only actually return the deviceId.
    // To work around this limitation. The deviceId will be used as the commandId for Arrow Devices
    // so that we can correlate commands on either end.
    // Obviously there are some limitations with this workaround, such as limiting the number of concurrent commands to 1
    // Since we will have no way to differenciate between two inflight commands
    if (generator.capabilities.includes(DeviceTypeCapability.ConnectCloud)) {
      return generator.id;
    } else {
      return uuid.v4();
    }
  }, [generator.capabilities, generator.id]);
}
