import React, { useCallback } from 'react';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Grid, Typography as T } from '@material-ui/core';

import DealerLinks from '~/ui/components/resources/dealers/links';
import DealerInfo from '~/ui/components/resources/dealers/contact';

import DeauthorizeModal from './-components/deauthorize-modal';
import { paths } from '~paths';
import { useDealer } from '~/data/dealer';
import { formatAddress } from '~/utils/address';
import { useRouter } from '~/utils/hooks';

import { useStyles } from '../../styles';
import { ccglobalStyles } from '~/ui/styles/defaults';
import ResponsiveContainer from '~/ui/components/responsive-container';

export default function ShowIndex() {
  const [t] = useTranslation();
  const classes = useStyles({});
  const cc = ccglobalStyles({});
  const { history } = useRouter();
  const [{ managedDealer: dealer }] = useDealer();

  const handleDeauthorized = useCallback(() => {
    history.push(paths.preferences.dealers.path);
  }, [history]);

  if (!dealer) {
    return <Redirect to={paths.preferences.dealers.path} />;
  }

  return (
    <ResponsiveContainer xs={12} lg={8}>
      <div className={cc.titleSpacing}>
        <T variant='h1'>{t('dealers.details.title')}</T>
      </div>

      <Grid container spacing={16} className={classes.dealerContainer}>
        <Grid item xs={12} md={6}>
          <DealerInfo name={dealer.name} address={formatAddress(dealer.address)} />
        </Grid>

        <Grid item xs={12} md={4}>
          <DealerLinks
            email={dealer.contactInformation.email}
            phone={dealer.contactInformation.phone}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.dealerAction}>
          <DeauthorizeModal dealer={dealer} onDeauthorize={handleDeauthorized} />
        </Grid>
      </Grid>

      {dealer.locations.map((location, index) => (
        <Grid container spacing={16} className={classes.dealerContainer}>
          <Grid item xs={12}>
            <T variant='h3' className={classes.dealerSubHeading}>
              {t('dealers.details.otherLocations')}
            </T>
          </Grid>

          <div key={index}>
            <Grid item xs={12} style={{ padding: '8px' }}>
              <DealerInfo name={dealer.name} address={formatAddress(location)} />
            </Grid>
          </div>
        </Grid>
      ))}
    </ResponsiveContainer>
  );
}
