import * as React from 'react';
import { Grid } from '@material-ui/core';
import { merge } from 'lodash';

import { defaultLabelSizeProps, defaultInputSizeProps, FieldLayoutProps, SizeProps } from './types';

export interface InputContainerProps extends FieldLayoutProps {
  label?: React.ReactNode;
  value?: React.ReactNode;
}
export default function InputContainer({
  xsinput,
  sminput,
  mdinput,
  lginput,
  xlinput,
  xslabel,
  smlabel,
  mdlabel,
  lglabel,
  xllabel,
  label,
  value,
}: InputContainerProps) {
  const labelProps: SizeProps = {
    xs: xslabel,
    sm: smlabel,
    md: mdlabel,
    lg: lglabel,
    xl: xllabel,
  };

  const inputProps: SizeProps = {
    xs: xsinput,
    sm: sminput,
    md: mdinput,
    lg: lginput,
    xl: xlinput,
  };

  return (
    <Grid container alignItems={'center'} justify={'flex-start'} spacing={16}>
      <Grid item container {...merge(defaultLabelSizeProps, labelProps)} justify={'flex-end'}>
        {label}
      </Grid>
      <Grid item {...merge(defaultInputSizeProps, inputProps)}>
        {value}
      </Grid>
    </Grid>
  );
}
