import { CSSProperties } from 'react';
import { colors } from 'da-frontend-shared-ui-components';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '1rem 0 0',
  },
  primaryAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '1rem 0 0',
  },
}));

export const switchStyle: CSSProperties = {
  height: 'auto',
};

export const onStyle: CSSProperties = {
  color: colors.green,
  fontWeight: 'bold',
  fontSize: '18px',
  marginRight: '1rem',
};
