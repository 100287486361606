import React from 'react';
import T from '@material-ui/core/Typography';

import { useClasses } from './styles';

interface IProps {
  title: string;
  data: { key: string; value: string }[];
}

export default function InfoTable({ title, data }: IProps) {
  const s = useClasses({});

  return (
    <>
      <T className={s.header} variant='h3'>
        {title}
      </T>

      <table className={s.table}>
        <tbody>
          {data.map((datum) => {
            return (
              <tr key={datum.key} className={s.row}>
                <th className={`${s.key} ${s.cell}`}>
                  <T>{datum.key}</T>
                </th>
                <td className={`${s.value} ${s.cell}`}>
                  <T>{datum.value}</T>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
