import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';

export const timeStyle: CSSProperties = {
  textAlign: 'right',
};

export const useClasses = makeStyles({
  buttonContainer: {
    '& > button': {
      width: '100%',
    },
  },
});
