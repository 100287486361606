import React, { useState } from 'react';
import { Drawer, Grid, Hidden } from '@material-ui/core';
import { ChevronArrowRight } from 'da-frontend-shared-ui-components/lib/icons';

import Navigation from '../navigation';

import { useStyles } from './styles';

export default function OffCanvas({ children }) {
  const classes = useStyles({});
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button className={classes.drawerButton} onClick={() => setOpen(true)}>
        <ChevronArrowRight className={classes.buttonChevron} />
      </button>

      <Grid container spacing={16}>
        <Grid item md={3}>
          <nav className={classes.nav}>
            <Navigation />
          </nav>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container direction='column'>
            {children}
          </Grid>
        </Grid>
      </Grid>

      <Hidden mdUp implementation='css'>
        <Drawer open={isOpen} onClose={() => setOpen(false)}>
          <nav className={classes.drawerNav}>
            <Navigation afterClick={() => setOpen(false)} />
          </nav>
        </Drawer>
      </Hidden>
    </>
  );
}
