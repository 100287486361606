import React, { CSSProperties } from 'react';

import PageError from './page';
import { Errors } from './errors';
import MissingInviteError from './MissingInviteError';

interface IState {
  error?: any;
  hasError?: Error | any;
}

interface IProps {
  style?: CSSProperties;
  children?: any;
}

// https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  private getError(message: string) {
    const { style } = this.props;
    const { error } = this.state;
    
    if (message === Errors.MissingInvite || message == Errors.NoCCUser || message == Errors.NoAppAccess) {
      return <MissingInviteError style={style} />
    } else {
      return <PageError style={style} error={error} />;
    }
  }

  public render() {
    const { style } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return error.message ? this.getError(error.message) : <PageError style={style} error={error} />;
    }

    return this.props.children;
  }
}
