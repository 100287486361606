import { useTranslation } from 'react-i18next';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import isToday from 'date-fns/is_today';
// NOTE: switch to date-fns-tz when date-fns v2 is released.
//       also, date-fns-tz does not bundle time-zone info
//       and relies on the i18n api.
import formatToTimeZone from 'date-fns/format';
import en from 'date-fns/locale/en';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';

const locales = {
  'en-US': en,
  es,
  fr,
};

export function useFullDateTimeFormat() {
  const {
    i18n: { language },
  } = useTranslation();
  const fullEnUs = 'ddd, MMM Do, YYYY h:mm A';
  const fullDateTime = {
    'en-US': fullEnUs,
  };

  return fullDateTime[language] || fullEnUs;
}

export function useShortDateTimeFormat() {
  const {
    i18n: { language },
  } = useTranslation();
  const shortEnUs = 'MMM D, YYYY';
  const shortDateTime = {
    'en-US': shortEnUs,
  };

  return shortDateTime[language] || shortEnUs;
}

export function useDate() {
  const {
    i18n: { language },
  } = useTranslation();
  const fullDateTimeFormat = useFullDateTimeFormat();
  const currentLanguage = language;

  const locale = locales[currentLanguage];

  if (!locale) {
    console.log(
      `
      Locale missing: ${currentLanguage}.
      Date Locales Available: ${Object.keys(locales)}.
      `
    );
  }

  return {
    isToday,
    format(date: number | string | Date, formatString = undefined, opts = {}) {
      const format = formatString || fullDateTimeFormat;
      return formatToTimeZone(date, format, opts);
    },
    distanceInWords(toDate, fromDate, opts = {}) {
      return distanceInWords(toDate, fromDate, {
        locale,
        ...opts,
      });
    },
    distanceInWordsToNow(fromDate, opts = {}) {
      return distanceInWordsToNow(fromDate, {
        locale,
        ...opts,
      });
    },
  };
}
