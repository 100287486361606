import React, { useCallback } from 'react';
import { Modal } from '@material-ui/core';
import { ConfirmModal, Form, defaultTheme } from 'da-frontend-shared-ui-components';
import { OperationalStatus, AccountPermission } from 'da-pcc-frontend-shared-domain';
import Button from '@material-ui/core/Button';
import T from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Fields } from './fields';
import { modalStyles } from './styles';
import { useModal } from '~/utils/hooks';
import { useCurrentGenerator } from '~/data/generator';
import {
  useExerciseSchedule,
  useExerciseScheduleSchema,
  ExerciseSchedule,
} from '~/data/generator/exercise-schedule';
import { useRequiredPermission } from '~/data/generator/permissions';
import { recordGeneratorEvent } from '~/utils/gtm';

export default function EditModal() {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();
  const classes = modalStyles({});
  const [isConfirm, setConfirm] = React.useState(false);
  
  const hasPermissionToSendCommands = useRequiredPermission(
    AccountPermission.CanSendDeviceCommands
  );
  
  const [
    { exerciseSchedule },
    {
      updateExerciseSchedule: [, updateExerciseSchedule],
    },
  ] = useExerciseSchedule();
  
  const schema = useExerciseScheduleSchema(t);
  
  const {
    status: { isRemoteEnabled, status },
  } = generator;
  
  const { isOpen, open, close } = useModal();

  const handleConfirmClose = () => { setConfirm(false); }
  const handleConfirmOpen = () => { setConfirm(true); }

  const handleSubmit = useCallback(
    async (values: ExerciseSchedule) => {
      await updateExerciseSchedule(values);
      handleConfirmOpen();
      recordGeneratorEvent("Edit Exercise Schedule");
      close();
    },
    [close, updateExerciseSchedule]
  );

  const isOffline = status === OperationalStatus.Offline;
  const isDisabled = !hasPermissionToSendCommands || !isRemoteEnabled || isOffline;

  return (
    <>
      {isOpen && (
        <Form
          initialValues={exerciseSchedule}
          isInitialValid={true}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isValid, errors }) => {
            return (
              <ConfirmModal
                title={t('exercise.title')}
                confirmButtonText={t('common.save')}
                cancelButtonText={t('common.cancel')}
                enableConfirm={isValid}
                onConfirmClick={handleSubmit}
                onCancelClick={close}
              >
                <Fields />
              </ConfirmModal>
            );
          }}
        </Form>
      )}

      <Modal open={isConfirm} onClose={handleConfirmClose} className={classes.modal}>
        <div className={classes.backdrop}>
          <div style={defaultTheme.typography.body1}>
            <br />
            <p>{t('exercise.scheduleConfirm')}</p>
            <br />
          </div>
          <div className={classes.modal}>
            <p><Button variant='contained' onClick={handleConfirmClose} className={classes.modalButton}>{t('common.ok')}</Button></p>
          </div>
        </div>
      </Modal>
      
      <Button disabled={isDisabled} variant='contained' size='large' onClick={open}>
        <T variant='button'>{t('exercise.edit')}</T>
      </Button>
    </>
  );
}
