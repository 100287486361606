import React, { useMemo } from 'react';
import T from '@material-ui/core/Typography';
import { Panel } from 'da-frontend-shared-ui-components';

import { useCurrentGenerator } from '~/data/generator';

import { GeneratorStatusIcon } from './generator-status-icon';
import { primaryStatusLabelStyle, primaryStatusValueStyle } from './styles';

import { useTranslation } from 'react-i18next';

import { powerSourceToI18nKey } from '~/utils/generator/power-source-to-i18n-key';
import { OperationalStatus } from 'da-pcc-frontend-shared-domain';

export default function ConnectionTo(props) {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();

  const {
    status: { powerSource, status },
  } = generator;

  const connectedTo = useMemo(() => {
    if (status === OperationalStatus.Offline || status === OperationalStatus.Unknown) {
      // passing null to function causes switch statement default to be returned, which
      //   is 'generator.powersource.unavailable'
      return powerSourceToI18nKey(null);
    } else {
      return powerSourceToI18nKey(powerSource);
    }
  }, [powerSource, status]);

  return (
    <Panel {...props} container direction='row' alignItems='center' justify='space-around'>
      <GeneratorStatusIcon generator={generator} />

      <T style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
        <span style={primaryStatusLabelStyle}>{t('generator.connectedTo')}:</span>
        <span style={primaryStatusValueStyle}>{t(connectedTo)}</span>
      </T>
    </Panel>
  );
}
