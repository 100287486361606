import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchConsumerAccount } from './actions';
import { ConsumerAccountState } from './types';

export const initialState: ConsumerAccountState = {
  loading: true,
  consumerAccount: undefined,
};

const consumerAccountReducer = reducerWithInitialState(initialState)
  .case(fetchConsumerAccount.started, (state) => ({
    ...state,
    account: undefined,
    loading: true,
  }))
  .case(fetchConsumerAccount.failed, (state) => ({
    ...state,
    account: undefined,
    loading: false,
  }))
  .case(fetchConsumerAccount.done, (state, { result: consumerAccount }) => ({
    ...state,
    consumerAccount,
    loading: false,
  }));

export default consumerAccountReducer;
