import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { Route } from '~/utils/routing';
import { paths } from '~paths';
import Index from './root';
import Privacy from './privacy';
import TermsConditions from './terms-conditions';

export default function LandingIndexRoute() {
  const [t] = useTranslation();
  const basePath = paths.root;
  
  return (
    <Switch>
      <Route exact path={paths.privacy.path} component={Privacy} />
      <Route
        exact
        path={paths.termsConditions.path}
        render={(props) => <TermsConditions {...props} title={t('nav.termsAndConditions')} />}
      />
      <Route exact path={basePath.path} component={Index} />
    </Switch>
  );
}
