import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'da-frontend-shared-ui-components';

export default function RevokeAccess({ close, onConfirm }) {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      title={t('users.invite.cancel.title')}
      message={t('users.invite.cancel.message')}
      confirmButtonText={t('users.invite.cancel.confirm')}
      cancelButtonText={t('common.cancel')}
      onConfirmClick={onConfirm}
      onCancelClick={close}
    />
  );
}
