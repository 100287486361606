import { makeStyles } from '@material-ui/styles';
import { grayLight1, white } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  tableSpacing: {
    marginTop: '8px',
  },
  tableContent: {
    borderTop: `1px solid ${grayLight1}`,
    '& > tr > td': {
      borderBottom: `1px solid ${grayLight1}`,
    },
    '& > tr:nth-child(odd)': {
      backgroundColor: white,
    },
  },
});
