import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cached, Done } from '@material-ui/icons';
import { Typography as T } from '@material-ui/core';

import { useStyles } from './styles';

export default function StatusMessage({ isSuccess }) {
  const { t } = useTranslation();
  const classes = useStyles({});

  return (
    <div
      className={`${classes.container} ${
        isSuccess ? classes.successContainer : classes.processingContainer
      }`}
    >
      {isSuccess ? (
        <>
          <Done className={`${classes.statusIcon} ${classes.statusIconSuccess}`} />
          <T>{t('generator.add.success')}</T>
        </>
      ) : (
        <>
          <Cached className={classes.statusIcon} />
          <T>{t('generator.add.processing')}</T>
        </>
      )}
    </div>
  );
}
