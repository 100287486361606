import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography as T } from '@material-ui/core';
import { LinkButton } from 'da-frontend-shared-ui-components';

import { paths } from '~paths';

import { ccglobalStyles } from '~/ui/styles/defaults';

export default function Header() {
  const { t } = useTranslation();
  const cc = ccglobalStyles({});

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <T variant='h1' className={cc.titleSpacing}>
          {t('generator.manage.title')}
        </T>
      </Grid>
      <Grid item xs={12}>
        <LinkButton variant='contained' color='primary' to={paths.preferences.generators.new.path}>
          {t('generator.manage.addNew')}
        </LinkButton>
      </Grid>
    </Grid>
  );
}
