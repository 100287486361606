import { useMemo } from 'react';
import * as Yup from 'yup';
import { ArrowExerciseSchedule, DayOfWeek } from 'da-pcc-frontend-shared-domain';

export function useExerciseScheduleSchema(t: TFunction) {
  return useMemo(() => generateSchema(t), [t]);
}

function generateSchema(t: TFunction) {
  return Yup.object({
    day: Yup.string()
      .oneOf(Object.values(DayOfWeek))
      .required(),
    atHour: Yup.number()
      .integer()
      .min(1)
      .max(24),
    atMinute: Yup.number()
      .integer()
      .min(0)
      .max(60),
    repeat: Yup.number()
      .oneOf(Object.values(ArrowExerciseSchedule))
      .required(),
  });
}
