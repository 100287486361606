declare const AppVersion: string; // injected from webpack

const config = {
  paths: {
    root: '/',
    login: '/cclogin',
    registration: '/ccUserRegistration',
    logout: '/logout',
    userTraining: 'https://cummins.hubs.vidyard.com/watch/YyHPD7Gvd8nrd6EtfDjqVw',
    noAccessError: '/no-access',
    pccUrl: window.location.hostname.search('cc-dev') > -1
      ? 'https://portal-dev.aws.powercommandcloud.com/'
      : window.location.hostname.search('arrow-qa') > -1
        ? 'https://portal-qa.aws.powercommandcloud.com/'
        : 'https://portal.powercommandcloud.com/',
    requestAccess: window.location.hostname.search('cc-dev') > -1
      ? 'https://pcc-auth-development.auth.us-east-1.amazoncognito.com/logout?client_id=1j6ajc1041tevdga09t5bke2ev&logout_uri=https://cumminscss--iamosmdev.sandbox.my.site.com/cw/IAM_Authorize?appid=a1aDd0000008yM0IAI'
      : window.location.hostname.search('arrow-qa') > -1
        ? 'https://da-pcc-auth-qa.auth.us-east-1.amazoncognito.com/logout?client_id=44bpu5iblr581rk0b7dmnkho41&logout_uri=https://cumminscss--uat.sandbox.my.site.com/cw/IAM_Authorize?appid=a1aDz000008glWF'
        : 'https://da-pcc-auth-production.auth.us-east-1.amazoncognito.com/logout?client_id=6ru2ep2nnfbhhesvefsqdp9e9k&logout_uri=https://mylogin.cummins.com/web/IAM_Authorize?appid=a1aKY000000KXpn'
  },
  reCaptchaKey: '6LcpyCUUAAAAAINYO9CpyvWyJui7U__hK80H8Xpd',
  doorbellEnabled: true,
  gtmId: 'GTM-5NCMCC8',
  appVersion: AppVersion,
};

export default config;
