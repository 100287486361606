import React from 'react';
import { ConfirmModal } from 'da-frontend-shared-ui-components';
import { useCurrentGenerator } from '~/data/generator';
import Button from '@material-ui/core/Button';
import T from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { AccountPermission, OperationalStatus } from 'da-pcc-frontend-shared-domain';
import { questionStyle } from './styles';
import { useModal } from '~/utils/hooks';
import { useCommands } from '~/data/generator/commands';
import { useRequiredPermission } from '~/data/generator/permissions';
import { recordGeneratorEvent } from '~/utils/gtm';

export default function StartNow() {
  const [t] = useTranslation();
  const { isOpen, open, close } = useModal();
  const [{ generator }] = useCurrentGenerator();
  const hasPermissionToSendCommands = useRequiredPermission(
    AccountPermission.CanSendDeviceCommands
  );
  const [
    ,
    {
      startExercise: [startState, start],
      stopExercise: [stopState, stop],
    },
  ] = useCommands();
  const {
    status: { isExercising, isStandbyEnabled, isRemoteEnabled, status },
  } = generator;

  const isOffline = status === OperationalStatus.Offline;
  let isDisabled =
    !hasPermissionToSendCommands || !(isStandbyEnabled && isRemoteEnabled) || isOffline;

  let button: any;
  const buttonProps: any = {
    variant: 'contained',
    color: 'primary',
    size: 'large',
  };

  const handleExerciseStop = () => {
    stop();
    recordGeneratorEvent("Stop Exercise");
  }

  if (isExercising) {
    isDisabled = isDisabled || stopState.loading;

    button = (
      <Button {...buttonProps} disabled={isDisabled} onClick={handleExerciseStop}>
        <T variant='button'>{stopState.loading ? t('exercise.stopping') : t('exercise.stop')}</T>
      </Button>
    );
  } else {
    isDisabled = isDisabled || startState.loading;

    button = (
      <Button {...buttonProps} disabled={isDisabled} onClick={open}>
        <T variant='button'>{startState.loading ? t('exercise.starting') : t('exercise.now')}</T>
      </Button>
    );
  }

  const handleConfirmExerciseStart = () => {
    start();
    recordGeneratorEvent("Start Exercise");
    close();
  }

  return (
    <>
      {button}
      {isOpen && (
        <ConfirmModal
          title={t('exercise.confirmNow.title')}
          confirmButtonText={t('exercise.confirmNow.confirm')}
          cancelButtonText={t('common.cancel')}
          onConfirmClick={handleConfirmExerciseStart}
          onCancelClick={close}
        >
          <T variant='h3' style={questionStyle}>
            {t('exercise.confirmNow.messageQuestion')}
          </T>
          <T>{t('exercise.confirmNow.messageBody')}</T>
        </ConfirmModal>
      )}
    </>
  );
}
