import { useState, useEffect } from 'react';
import { useIdle } from 'react-use';

export const SESSION_MINUTES = 29;
export const WARNING_MINUTES = 0.5;
export const FORCE_SESSION_MINUTES = 60;

export function useSessionTimeout() {
  const [expired, setExpired] = useState(false);
  const isWarning = useIdle(SESSION_MINUTES * 60 * 1000);

  // Whenever the session status is warning start a timer for WARNING_MINUTES
  // This is where we potentially mark the session as expired
  useEffect(() => {
    let timer: NodeJS.Timer = undefined;
    if (isWarning) {
      timer = setTimeout(() => {
        console.warn('Session expired will logout');
        setExpired(true);
      }, WARNING_MINUTES * 60 * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
    };
  }, [isWarning]);

  return { isWarning, expired };
}
export function useForceSessionTimeout() {
  const [forceLogout, setForceLogout] = useState(false);
  let timeout = setTimeout(() => setForceLogout(true), FORCE_SESSION_MINUTES * 60 * 1000);

  // Whenever the session status is warning start a timer for WARNING_MINUTES
  // This is where we potentially mark the session to logout forcefully
  if (timeout) {
    clearTimeout(timeout);
    timeout = undefined;
  }
  return { forceLogout };
}