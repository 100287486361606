import { makeStyles } from '@material-ui/styles';
import { teal, white } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  registrationContainer: {
    margin: '0 auto',
    maxWidth: '51rem',
    padding: '4rem 0',
  },
  tandcActions: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tandcButtons: {
    paddingTop: '1.25rem',
    '& > *:first-of-type': {
      marginRight: '1rem',
      textDecoration: 'none',
    },
    '& a': {
      color: white,
      textDecoration: 'none',
    },
  },
  tandcCheckbox: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: '5rem',
    '& > input': {
      marginRight: '.5rem',
    },
  },
  tandcPrint: {
    alignItems: 'center',
    color: teal,
    display: 'flex',
    '& svg': {
      marginRight: '.5rem',
    },
  },
});
