import React, { useMemo, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { defaultTheme, ConfirmModal } from 'da-frontend-shared-ui-components';
import { useTranslation } from 'react-i18next';
import { Typography as T } from '@material-ui/core';
import { Trash } from 'da-frontend-shared-ui-components/lib/icons';
import { Event } from 'da-pcc-frontend-shared-domain';
import { useCurrentGeneratorEvents } from '~/data/generator/events';
import { useDate } from '~/utils/date';
import { defaultSeparation } from '~/ui/styles/defaults';
import { Table } from './-components/table';
import { NoAlerts } from '~/data/generator/events/no-events';
import { useModal } from '~/utils/hooks';
import { recordGeneratorEvent } from '~/utils/gtm';

export default function Alerts() {
  const [
    { alerts },
    {
      acknowledgeAll: [acknowledgeAllState, acknowledgeAll],
    },
  ] = useCurrentGeneratorEvents();
  const { t } = useTranslation();
  const { isOpen, open, close } = useModal();
  const { todaysAlerts, olderAlerts } = useTimeSplitAlerts(alerts);

  const onConfirm = useCallback(async () => {
    await acknowledgeAll(alerts);
    recordGeneratorEvent("Acknowledge All");
    close();
  }, [acknowledgeAll, alerts, close]);

  if (alerts.length === 0) {
    return <NoAlerts style={{ marginTop: defaultSeparation }} />;
  }

  return (
    <>
      <Table alerts={todaysAlerts} title={t('common.today')} />
      <Table alerts={olderAlerts} title={t('common.older')} />

      <div style={{ textAlign: 'right' }}>
        <Button color='primary' onClick={open}>
          {t('notifications.deleteAll')}
          <Trash style={{ marginLeft: defaultTheme.spacing.unit }} />
        </Button>
      </div>

      {isOpen && (
        <ConfirmModal
          title={t('notifications.confirmDeleteAll.title')}
          confirmButtonText={
            acknowledgeAllState.loading
              ? t('notifications.confirmDeleteAll.inProgress')
              : t('notifications.confirmDeleteAll.confirm')
          }
          cancelButtonText={t('common.cancel')}
          enableConfirm={!acknowledgeAllState.loading}
          onConfirmClick={onConfirm}
          onCancelClick={close}
        >
          <T>{t('notifications.confirmDeleteAll.message')}</T>
        </ConfirmModal>
      )}
    </>
  );
}

function useTimeSplitAlerts(alerts: Event[]) {
  const { isToday } = useDate();

  const todaysAlerts = useMemo(
    () =>
      alerts.filter((alertEvent) => {
        return isToday(alertEvent.timestamp);
      }),
    [alerts, isToday]
  );

  const olderAlerts = useMemo(
    () =>
      alerts.filter((alertEvent) => {
        return !isToday(alertEvent.timestamp);
      }),
    [alerts, isToday]
  );

  return { todaysAlerts, olderAlerts };
}
