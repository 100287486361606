import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { pending, received, success, failed, timeout, reset } from './actions';
import { CommandsState, CommandInvocation } from './types';
import { CommandResponse } from 'da-pcc-frontend-shared-domain';

export const initialState: CommandsState = {};

const findCommandInvocation = (
  state: CommandsState,
  commandId: string
): CommandInvocation | undefined => {
  const invocation = state[commandId];
  if (!invocation) {
    console.warn(
      `received ack for command id ${commandId}, but we had no matching invocation record`
    );
  }
  return invocation;
};

const commandsReducer = reducerWithInitialState(initialState)
  .case(pending, (state, { commandId, payload }) => ({
    ...state,
    [commandId]: {
      id: commandId,
      status: CommandResponse.Pending,
      payload,
      loading: true,
    },
  }))
  .case(received, (state, { commandId }) => {
    const invocation = findCommandInvocation(state, commandId);
    if (!invocation) {
      return state;
    }
    return {
      ...state,
      [commandId]: {
        ...invocation,
        status: CommandResponse.Received,
      },
    };
  })
  .case(success, (state, { commandId }) => {
    const invocation = findCommandInvocation(state, commandId);
    if (!invocation) {
      return state;
    }
    return {
      ...state,
      [commandId]: {
        ...invocation,
        status: CommandResponse.Succeeded,
      },
    };
  })
  .case(failed, (state, { commandId, error }) => {
    const invocation = findCommandInvocation(state, commandId);
    if (!invocation) {
      return state;
    }
    return {
      ...state,
      [commandId]: {
        ...invocation,
        status: CommandResponse.Failed,
        error,
      },
    };
  })
  .case(timeout, (state, { commandId, error }) => {
    const invocation = findCommandInvocation(state, commandId);
    if (!invocation) {
      return state;
    }
    return {
      ...state,
      [commandId]: {
        ...invocation,
        status: CommandResponse.Timeout,
        error,
      },
    };
  })
  .case(reset, (state, { commandId }) => {
    const newState = { ...state };
    delete newState[commandId];
    return newState;
  });

export default commandsReducer;
