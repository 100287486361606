import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchExerciseSchedule } from './actions';
import { ExerciseScheduleState } from './types';
import { ArrowExerciseSchedule, DayOfWeek } from 'da-pcc-frontend-shared-domain';

export const initialState: ExerciseScheduleState = {
  loading: true,
  exerciseSchedule: {
    day: DayOfWeek.monday,
    atHour: 12,
    atMinute: 0,
    repeat: ArrowExerciseSchedule.BIMONTHLY,
  },
  error: undefined,
};

const exerciseScheduleReducer = reducerWithInitialState(initialState)
  .case(fetchExerciseSchedule.done, (state, { result: exerciseSchedule }) => ({
    ...state,
    exerciseSchedule,
    loading: false,
  }))
  .case(fetchExerciseSchedule.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default exerciseScheduleReducer;
