import React from 'react';
import { ConfirmModal } from 'da-frontend-shared-ui-components';
import { AccountPermission, OperationalStatus } from 'da-pcc-frontend-shared-domain';
import Button from '@material-ui/core/Button';
import T from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { messageStyle } from './styles';
import { useCurrentGenerator } from '~/data/generator';
import { useRequiredPermission } from '~/data/generator/permissions';
import { useModal } from '~/utils/hooks';
import { useCommands } from '~/data/generator/commands';
import { recordGeneratorEvent } from '~/utils/gtm';

export default function StartNow() {
  const [t] = useTranslation();
  const { isOpen, open, close } = useModal();
  const [{ generator }] = useCurrentGenerator();
  const hasPermissionToSendCommands = useRequiredPermission(
    AccountPermission.CanSendDeviceCommands
  );
  const [
    ,
    {
      start: [startState, start],
      stop: [stopState, stop],
    },
  ] = useCommands();

  const {
    status: { isRemoteEnabled, isRunning, status },
  } = generator;
  const isOffline = status === OperationalStatus.Offline;
  let isDisabled = !hasPermissionToSendCommands || !isRemoteEnabled || isOffline;
  let message, title, button;

  if (isRunning) {
    message = 'control.confirm.stopMessage';
    title = 'control.confirm.stopTitle';
    button = stopState.loading ? 'control.confirm.stoppingButton' : 'control.confirm.stopButton';
    isDisabled = isDisabled || stopState.loading;
  } else {
    message = 'control.confirm.startMessage';
    title = 'control.confirm.startTitle';
    button = startState.loading ? 'control.confirm.startingButton' : 'control.confirm.startButton';
    isDisabled = isDisabled || startState.loading;
  }

  const handleConfirmRemoteStart = () => {
    if (isRunning) {
      stop();
      recordGeneratorEvent("Remote Stop");
    } else {
      start();
      recordGeneratorEvent("Remote Start");
    }
    close();
  }

  return (
    <>
      <Button
        disabled={isDisabled}
        variant='contained'
        color='primary'
        size='large'
        onClick={() => open()}
      >
        <T variant='button'>{t(button)}</T>
      </Button>

      {isOpen && (
        <ConfirmModal
          title={t(title)}
          message={<T style={messageStyle}>{t(message)}</T>}
          confirmButtonText={t('control.confirm.confirm')}
          cancelButtonText={t('common.cancel')}
          onConfirmClick={handleConfirmRemoteStart}
          onCancelClick={close}
        />
      )}
    </>
  );
}
