import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { DeviceTypeCapability, AccountPermission } from 'da-pcc-frontend-shared-domain';
import ConnectedTo from './-components/connected-to';
import GeneratorConnection from './-components/generator-connection';
import CurrentStatus from './-components/current-status';
import Notifications from './notifications';
import Exercise from './exercise';
import Control from './control';
import Maintenance from './maintenance';
import GeneratorData from './generator-data';
import { useCommands } from '~/data/generator/commands';
import { useCurrentGenerator } from '~/data/generator';
import useInterval from '~/utils/useInterval';
import { useRequiredPermission } from '~/data/generator/permissions';
import TagManager from 'react-gtm-module';

const RESUBCRIBE_INTERVAL = 4 * 60 * 1000;

export default function DashboardGeneratorsShowIndexRoute() {
  const [{ generator }] = useCurrentGenerator();
  const hasPermissionToSendCommands = useRequiredPermission(
    AccountPermission.CanSendDeviceCommands
  );
  const [
    ,
    {
      subscribeToDeviceStatusAndEvents: [, subscribeToDeviceStatusAndEvents],
      increaseUpdateFrequency: [, increaseUpdateFrequency],
    },
  ] = useCommands();

  const capabilities = useMemo(() => {
    const parentCapabilities = generator.parent ? generator.parent.capabilities : [];
    return [...parentCapabilities, ...generator.capabilities];
  }, [generator.capabilities, generator.parent]);

  useEffect(() => {
    if (
      hasPermissionToSendCommands &&
      capabilities.includes(DeviceTypeCapability.CustomTelemetryInterval)
    ) {
      increaseUpdateFrequency();
    }
  }, [increaseUpdateFrequency, capabilities, hasPermissionToSendCommands]);

  useInterval(
    () => {
      subscribeToDeviceStatusAndEvents();
    },
    RESUBCRIBE_INTERVAL,
    [subscribeToDeviceStatusAndEvents]
  );

  // load new generator-related variables into GA whenever generator changes
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        accountId: generator.accountId,
        siteId: generator.siteId,
        assetId: generator.id,
      }
    });
  }, [generator]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={16} alignItems='stretch'>
        <Grid item xs={12}>
          <ConnectedTo />
        </Grid>
        <Grid item xs={6}>
          <GeneratorConnection />
        </Grid>
        <Grid item xs={6}>
          <CurrentStatus />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Notifications />
              <Exercise />
              <Control />
              <Maintenance />
              <GeneratorData />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
