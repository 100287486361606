import * as React from 'react';
import { Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

export interface BrowserErrorProps extends React.PropsWithChildren<{}> {
  open: boolean;
}

export const modalStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    background: '#FFFFFF',
    fontFamily: 'Roboto,Arial,sans-serif',
    padding: 5,
    textAlign: 'center',
  },
  modalText: {
    width: '75%',
    wordWrap: 'break-word',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconWatermark: {
    opacity: 0.35,
    backgroundColor: '#FFFFFF',
  },
  iconItem: {
    verticalAlign: 'top',
    display: 'inline-block',
    textAlign: 'center',
    padding: 7,
  },
  iconCaption: {
    display: 'block',
  },
  hrStyle: {
    opacity: 0.5,
  },
});

const browserIconChrome = require('../images/browser-icon_chrome.svg');
const browserIconEdge = require('../images/browser-icon_edge.svg');
const browserIconSafari = require('../images/browser-icon_safari.svg');
const browserIconFirefox = require('../images/browser-icon_firefox.svg');
const browserIconExplorer = require('../images/browser-icon_internet-explorer_greyscale.svg');

const BrowserError = ({ open }: BrowserErrorProps) => {
  const [t] = useTranslation();
  const classes = modalStyles({});
  const [doOpen] = React.useState(open);
  const handleClose = () => {
    return true;
  };

  return (
    <>
      <Modal open={doOpen} onClose={handleClose} className={classes.modal}>
        <div className={classes.backdrop}>
          <h2>{t(`sessionError.browserErrorTitle`)}</h2>
          <p>&nbsp;</p>
          <div>
            <p>{t(`sessionError.browserErrorApplication`)}</p>
            <p>&nbsp;</p>
          </div>
          <div>
            <div className={classes.iconItem}>
              <a href='https://www.google.com/chrome' target='_blank'>
                <img src={browserIconChrome} />
              </a>
              <span className={classes.iconCaption}>Chrome</span>
              <span>All Versions</span>
            </div>
            <div className={classes.iconItem}>
              <a href='https://www.microsoft.com/en-us/edge' target='_blank'>
                <img src={browserIconEdge} />
              </a>
              <span className={classes.iconCaption}>Edge</span>
              <span>Version 44+</span>
            </div>
            <div className={classes.iconItem}>
              <a href='https://support.apple.com/downloads/safari' target='_blank'>
                <img src={browserIconSafari} />
              </a>
              <span className={classes.iconCaption}>Safari</span>
              <span>All Versions</span>
            </div>
            <div className={classes.iconItem}>
              <a href='https://www.mozilla.org/firefox/download/thanks/' target='_blank'>
                <img src={browserIconFirefox} />
              </a>
              <span className={classes.iconCaption}>Firefox</span>
              <span>Version 54+</span>
            </div>
            <div className={classes.iconItem}>
              <img src={browserIconExplorer}></img>
              <span className={classes.iconCaption}>Internet Explorer</span>
              <span>Unsupported</span>
            </div>
          </div>
          <p>&nbsp;</p>
          <hr className={classes.hrStyle} />
          <p>&nbsp;</p>
          <div className={classes.modal}>
            <p className={classes.modalText}>{t(`sessionError.browserError`)}</p>
          </div>
          <p>&nbsp;</p>
          <div className={classes.modal}>
            <p>
              <Button variant='contained' target='_blank' href='http://www.google.com/chrome'>
                {t(`sessionError.browserErrorDownload`)} Google Chrome
              </Button>
            </p>
          </div>
          <p>&nbsp;</p>
        </div>
      </Modal>
    </>
  );
};

export default BrowserError;
