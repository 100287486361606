import { Popover, Typography } from '@material-ui/core';
import { AppHeader as SharedAppHeader, icons } from 'da-frontend-shared-ui-components';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import config from '~/config';
import { useDealer } from '~/data/dealer';
import { useGeneratorsList } from '~/data/generators';
import { useSession } from '~/data/session';
import HelpPopover from '~/ui/components/help-popover';
import { help } from '~/utils/glyphs';
import { useRouter } from '~/utils/hooks';
import { buildIsActive, paths } from '~paths';

import { mainNavStyle, topNavIconStyle, helpHeaderStyle } from './styles';
import i18next from 'i18next';
import { DealerModel, AccountPermission } from 'da-pcc-frontend-shared-domain';
import { useConsumerAccount } from '~/data/consumerAccount';

const { Settings, Data, Glyph } = icons;

const buildDropdown = (
  t: i18next.TFunction,
  permissions: AccountPermission[],
  dealer?: DealerModel
) => {
  const dropdown = [
    {
      label: t('nav.myGenerators'),
      path: paths.preferences.generators.path,
    },
  ];

  if (permissions.includes(AccountPermission.CanManageDealerAccounts)) {
    dropdown.push({
      label: t(dealer ? 'nav.myDealer' : 'nav.dealers'),
      path: paths.preferences.dealers.path,
    });
  }

  if (permissions.includes(AccountPermission.CanManageUsers)) {
    dropdown.push({
      label: t('nav.users'),
      path: paths.preferences.users.path,
    });
  }

  dropdown.push({
    label: t('nav.notifications'),
    path: paths.preferences.notifications.path,
  });

  return dropdown;
};

export default function AppHeader() {
  const [t] = useTranslation();
  const [{ managedDealer: dealer }] = useDealer();
  const [{ generators }] = useGeneratorsList();

  const [consumerAccount] = useConsumerAccount();

  const permissions = useMemo(() => {
    return (consumerAccount && consumerAccount.permissions) || [];
  }, [consumerAccount]);

  const [
    {
      profile: { firstName },
    },
  ] = useSession();

  const {
    location: { pathname },
  } = useRouter();

  const isActive = useMemo(() => buildIsActive(pathname), [pathname]);

  const primaryNavLinks = useMemo(
    () =>
      generators.length === 0
        ? []
        : [
            {
              icon: (props) => <Data style={mainNavStyle} {...props} />,
              label: t('nav.dashboard'),
              path: paths.dashboard.generators.path,
              isActive: isActive(paths.dashboard.path),
            },
            {
              icon: (props) => <Settings style={mainNavStyle} {...props} />,
              label: t('nav.preferences'),
              path: paths.preferences.path,
              isActive: isActive(paths.preferences.path),
              dropdown: buildDropdown(t, permissions, dealer),
            },
          ],
    [t, isActive, permissions, dealer, generators]
  );

  const secondaryNavLinks = useMemo(
    () => [
      {
        label: (
          <PopupState variant='popover' popupId='demo-popup-popover'>
            {(popupState) => (
              <div>
                <div variant='contained' {...bindTrigger(popupState)}>
                  <Typography variant='body1' style={helpHeaderStyle}>
                    {t('header.help')}
                  </Typography>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <HelpPopover />
                </Popover>
              </div>
            )}
          </PopupState>
        ),
        icon: (props) => <Glyph code={help} style={topNavIconStyle} {...props} />,
        href: null,
      },
      {
        label: t('header.logout'),
        href: `${config.paths.logout}`,
      },
    ],
    [t]
  );

  return (
    <SharedAppHeader
      title={<div style={{ width: '50px' }}>{t('appname')}</div>}
      greeting={t('header.greetings', { firstName })}
      primaryNavigationLinks={primaryNavLinks}
      secondaryNavigationLinks={secondaryNavLinks}
    />
  );
}
