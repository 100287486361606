import * as React from 'react';
import Helmet from 'react-helmet';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import { defaultTheme } from 'da-frontend-shared-ui-components';
import useInterval from '~/utils/useInterval';

export interface BacksplashProps extends React.PropsWithChildren<{}> {
  backsplashImages: string[];
}

export const useStyles = makeStyles({
  body: {
    height: 'calc(100vh - 6.25rem)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    [defaultTheme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
});

const Backsplash = ({ children, backsplashImages }: BacksplashProps) => {
  const styles = useStyles({});

  const imagePrefetchCollection = _.clone(backsplashImages);

  const [backsplashImageRingBuffer, setBacksplashImageRingBuffer] = React.useState(
    backsplashImages
  );
  const [currentBacksplash, setCurrentBacksplash] = React.useState(backsplashImageRingBuffer[0]);

  useInterval(
    () => {
      backsplashImageRingBuffer.push(backsplashImageRingBuffer.shift() as string);
      setCurrentBacksplash(backsplashImageRingBuffer[0]);
      setBacksplashImageRingBuffer(backsplashImageRingBuffer);
    },
    1e4,
    [backsplashImageRingBuffer, setBacksplashImageRingBuffer, setCurrentBacksplash]
  );

  return (
    <>
      <Helmet>
        {imagePrefetchCollection.map((image, index) => (
          <link key={index} rel='prefetch' href={image} as='image' />
        ))}
      </Helmet>
      <Grid
        id={'backsplash'}
        container
        justify='space-between'
        direction='column'
        className={styles.body}
        style={{
          backgroundImage: `url(${currentBacksplash})`,
        }}
      >
        {children}
      </Grid>
    </>
  );
};

export default Backsplash;
