import React, { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, ConfirmModal, Input } from 'da-frontend-shared-ui-components';
import {
  PersistedAsset,
  PersistedSite,
  UpdateConnectCloudEquipmentRequest,
} from 'da-pcc-frontend-shared-domain';
import { Button, Grid, Typography as T } from '@material-ui/core';
import { useStyles } from './styles';
import { useEditSchema, useGeneratorsList } from '~/data/generators';
import ResponsiveContainer from '~/ui/components/responsive-container';
import ModalState from '~/ui/components/modal-state';
import Loader from 'da-frontend-shared-ui-components/lib/Loader';
import { dangerButtonStyle, containerStyles } from './styles';
import SelectField from '~/ui/components/custom-fields/SelectField';
import { useAPI } from '~/data';
import { useCountries, useStateOrProvidence } from '~/data/regions';
import { recordPreferenceEvent } from '~/utils/gtm';

interface IProps {
  close: () => void;
  confirm: () => void;
  generator: PersistedAsset;
}

export default function EditModal({ close: closeEditModal, confirm, generator }: IProps) {
  const { t } = useTranslation();
  const schema = useEditSchema(t);
  const classes = useStyles({});
  const [countryId, setCountryId] = React.useState('US');
  const [isInitialFormValid, setIsInitialFormValid] = React.useState<boolean | undefined>();
  const listOfCountries = useCountries();
  const listOfProvinces = useStateOrProvidence(countryId);
  // TODO: Move to Context Dispatch
  const { sites } = useAPI();
  const [site, setSite] = React.useState<PersistedSite | undefined>(undefined);
  const { name, id, siteId } = generator;

  const [
    ,
    {
      update: [updateState, update],
      destroy: [destroyState, destroy],
    },
  ] = useGeneratorsList();

  const sizing: any = {
    labelSize: 4,
    fieldSize: 8,
  };

  useEffect(() => {
    sites.getSite({ siteId }).then((json) => setSite(json));
  }, [sites, siteId]);

  const initialValues = useMemo(() => {
    if (site) {
      setCountryId(site.location.country);
      return {
        name,
        phone: site.contactInformation.phone,
        address: site.location.line1,
        address2: site.location.line2,
        city: site.location.city,
        country: site.location.country,
        state: site.location.region,
        zip: site.location.postalCode,
      };
    } else {
      return {};
    }
  }, [name, site]);

  React.useEffect(() => {
    const performValidation = async () => {
      setIsInitialFormValid(await schema.isValid(initialValues));
    };
    performValidation();
  }, [initialValues, schema]);

  const handleSubmit = useCallback(
    async (...args) => {
      const [values] = args;
      const payload: UpdateConnectCloudEquipmentRequest = {
        connectCloudSetupModel: {
          deviceId: id,
          name: values.name,
          phone: values.phone,
          location: {
            line1: values.address,
            line2: values.address2,
            city: values.city,
            region: values.state,
            postalCode: values.zip,
            country: values.country,
          },
        },
      };

      await update(payload);

      recordPreferenceEvent("Edit Generator", generator.id);

      closeEditModal();
    },
    [closeEditModal, id, update]
  );

  const handleRemove = useCallback(async () => {
    await destroy(generator);

    recordPreferenceEvent("Remove Generator", generator.id);

    confirm();
    closeEditModal();
  }, [closeEditModal, confirm, destroy, generator]);

  if (!site) {
    return <Loader />;
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={schema}
      isInitialValid={isInitialFormValid}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        if (updateState.loading) {
          return <Loader />;
        }

        return (
          <ConfirmModal
            title={name}
            confirmButtonText={t('common.save')}
            cancelButtonText={t('common.cancel')}
            onConfirmClick={handleSubmit}
            onCancelClick={closeEditModal}
          >
            <ResponsiveContainer style={containerStyles}>
              <Input {...sizing} required name='name' label={t('generator.attributes.fullName')} />
              <Input {...sizing} required name='phone' label={t('generator.attributes.phone')} />
              <Input
                {...sizing}
                required
                name='address'
                label={t('generator.attributes.address')}
              />
              <Input {...sizing} name='address2' label={t('generator.attributes.address2')} />
              <Input {...sizing} required name='city' label={t('generator.attributes.city')} />
              <SelectField
                xsinput={sizing.fieldSize}
                sminput={sizing.fieldSize}
                mdinput={sizing.fieldSize}
                lginput={sizing.fieldSize}
                xlinput={sizing.fieldSize}
                xslabel={sizing.labelSize}
                smlabel={sizing.labelSize}
                mdlabel={sizing.labelSize}
                lglabel={sizing.labelSize}
                xllabel={sizing.labelSize}
                canSelectNone={false}
                name='country'
                label={t('generator.attributes.country')}
                options={listOfCountries}
                value={values.country}
                onChange={({ target: { value } }) => {
                  setFieldValue('country', value);
                  setCountryId(value);
                  setFieldValue('state', '');
                }}
                isRequired
              />
              {listOfProvinces.length > 0 ? (
                <SelectField
                  xsinput={sizing.fieldSize}
                  sminput={sizing.fieldSize}
                  mdinput={sizing.fieldSize}
                  lginput={sizing.fieldSize}
                  xlinput={sizing.fieldSize}
                  xslabel={sizing.labelSize}
                  smlabel={sizing.labelSize}
                  mdlabel={sizing.labelSize}
                  lglabel={sizing.labelSize}
                  xllabel={sizing.labelSize}
                  canSelectNone={false}
                  name='state'
                  label={t('generator.attributes.state')}
                  options={listOfProvinces}
                  value={values.state}
                  onChange={({ target: { value } }) => setFieldValue('state', value)}
                  isRequired
                />
              ) : (
                <Input {...sizing} required name='state' label={t('generator.attributes.state')} />
              )}
              <Input {...sizing} required name='zip' label={t('generator.attributes.zip')} />

              <ModalState>
                {({ isOpen, open, close }) => {
                  return (
                    <>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} className={classes.formLabelGridItem} />
                        <Grid item xs={12} sm={8} md={8} className={classes.formInputGridItem}>
                          <Button variant='contained' style={dangerButtonStyle} onClick={open}>
                            {destroyState.loading
                              ? t('generator.manage.removing')
                              : t('generator.manage.remove')}
                          </Button>
                        </Grid>
                      </Grid>

                      {isOpen && (
                        <ConfirmModal
                          title={t('generator.remove.title')}
                          confirmButtonText={t('common.ok')}
                          cancelButtonText={t('common.cancel')}
                          onConfirmClick={async () => {
                            close();
                            await handleRemove();
                          }}
                          onCancelClick={close}
                        >
                          <T>{t('generator.remove.message')}</T>
                        </ConfirmModal>
                      )}
                    </>
                  );
                }}
              </ModalState>
            </ResponsiveContainer>
          </ConfirmModal>
        );
      }}
    </Form>
  );
}
