import React from 'react';
import T from '@material-ui/core/Typography';
import { colors } from 'da-frontend-shared-ui-components';
import { CSSProperties } from '@material-ui/styles/withStyles';

const style: CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: colors.grayMed,
};

export default function SummaryText({ children }) {
  return <T style={style}>{children}</T>;
}
