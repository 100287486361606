import React, { useContext, useCallback, createContext, useEffect, useReducer } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';

import { useAPI } from '~/data';

import { useAsyncFn } from 'react-use';

import { fetchDealers } from './actions';
import reducer, { initialState } from './reducer';
import { DealersState, DealersDispatch } from './types';

const DealersStateContext = createContext<DealersState | undefined>(undefined);
const DealersDispatchContext = createContext<DealersDispatch | undefined>(undefined);

export function DealersProvider({ children }) {
  const { dealers } = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDealers = useCallback(() => {
    const action = async () => {
      try {
        dispatch(fetchDealers.started());
        const dealer = await dealers.getConnectCloudDealers();
        dispatch(fetchDealers.done({ result: dealer }));
      } catch (error) {
        dispatch(fetchDealers.failed({ error }));
      }
    };
    action();
  }, [dealers, dispatch]);

  const requestDealer = useAsyncFn(
    async (accountId: string) => {
      return await dealers.submitDealerRequest({ dealerRequestModel: { accountId } });
    },
    [dealers]
  );

  useEffect(() => {
    getDealers();
  }, [getDealers]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <DealersStateContext.Provider value={state}>
      <DealersDispatchContext.Provider value={{ requestDealer }}>
        {children}
      </DealersDispatchContext.Provider>
    </DealersStateContext.Provider>
  );
}
function useDealersState() {
  const context = useContext(DealersStateContext);
  if (context === undefined) {
    throw new Error('useDealersState must be used within a DealersProvider');
  }
  return context;
}

function useDealersDispatch() {
  const context = useContext(DealersDispatchContext);
  if (context === undefined) {
    throw new Error('useDealersDispatch must be used within a DealersProvider');
  }
  return context;
}

export function useDealers(): [DealersState, DealersDispatch] {
  return [useDealersState(), useDealersDispatch()];
}
