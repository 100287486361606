import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const containerStyles: CSSProperties = {
  height: '100%',
};

export const iconStyles: CSSProperties = {
  height: '2.6rem',
  width: '2.6rem',
};

export const textWrapperStyles: CSSProperties = {
  textAlign: 'center',
  flexBasis: '50',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
};

export const titleStyles: CSSProperties = {
  fontWeight: 'normal',
  textTransform: 'capitalize',
};

export const statusContent: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const statusText: CSSProperties = {
  display: 'flex',
  flexGrow: 1,
};
