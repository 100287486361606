import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography as T } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ConfirmModal } from 'da-frontend-shared-ui-components';
import { DealerModel } from 'da-pcc-frontend-shared-domain';
import { useDealer } from '~/data/dealer';
import { useModal } from '~/utils/hooks';
import { useStyles } from './styles';
import { recordPreferenceEvent } from '~/utils/gtm';
import { ErrorDisplay } from '~/ui/components/errors/error-context';

export default function DeauthorizeModal({
  dealer,
  onDeauthorize,
}: {
  dealer: DealerModel;
  onDeauthorize: () => void;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, open, close } = useModal();
  const classes = useStyles({});
  const [
    ,
    {
      deauthorize: [state, deauthorize],
    },
  ] = useDealer();

  const isCorrupt = dealer.isCorrupt;

  const onConfirm = useCallback(async () => {

    await deauthorize();

    enqueueSnackbar(
      t('dealers.deauthorize.success', {
        firstName: dealer.name,
      })
    );
  
    onDeauthorize();
  
    recordPreferenceEvent("Deauthorize Dealer", `Deauthorize Dealer ID: ${dealer.id}`);
  
    close();
    
  }, [close, dealer.name, deauthorize, enqueueSnackbar, onDeauthorize, t]);

  return (
    <>
      <button className={`${classes.btn} ${classes.dealerBtn}`} onClick={open}>
        <T variant='body1' className={classes.dealerInfoBtnText}>
          {t('dealers.details.cancel')}
        </T>
      </button>

      {isOpen && (
        <ConfirmModal
          title={t('dealers.deauthorize.title')}
          message={
          <>
            {/* show either error or normal message, but not both */}
            <ErrorDisplay text={!isCorrupt ? `` : `deauthorize`}/>
            {!isCorrupt ? t( 'dealers.deauthorize.message' ) : ``}
          </>
          }
          confirmButtonText={t('dealers.deauthorize.accept')}
          cancelButtonText={t('common.cancel')}
          enableConfirm={!state.loading && !isCorrupt}
          onConfirmClick={onConfirm}
          onCancelClick={close}
        />
      )}
    </>
  );
}
