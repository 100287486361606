import React from 'react';

import { Route } from '~/utils/routing';

import { Switch } from 'react-router-dom';

import { paths } from '~paths';

import Pending from './pending';
import Index from './root';

export default function DealersShow() {
  const basePath = paths.preferences.dealers.show;

  return (
    <Switch>
      <Route exact path={basePath.path} component={Index} />
      <Route path={basePath.pending.path} component={Pending} />
    </Switch>
  );
}
