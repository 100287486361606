import * as React from 'react';
import { MenuItem, Typography, Select as MuiSelect } from '@material-ui/core';
import { Field } from 'formik';
import { isFunction } from 'lodash';
import { grayMed } from 'da-frontend-shared-ui-components/lib/styles/colors';

import { FieldLayoutProps, SelectOption } from './types';
import { ErrorHelperText } from './ErrorHelperText';
import FormLabel from './FormLabel';
import { alignLabels, labelAsterisk } from './styles';
import InputContainer from './InputContainer';

export interface SelectFieldProps
  extends FieldLayoutProps,
    React.SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[];
  label: string;
  multiple?: boolean;
  canSelectNone?: boolean;
  classes?: any;
  hasError?: boolean;
  isRequired?: boolean;
  renderValue?: (value: any) => React.ReactElement;
}

export default function SelectField({
  classes,
  id,
  hasError,
  label,
  options,
  isRequired,
  name,
  multiple = false,
  canSelectNone = true,
  ...field
}: SelectFieldProps) {
  return (
    <InputContainer
      {...field}
      label={
        <>
          <FormLabel htmlFor={name} hasError={!!hasError} style={alignLabels}>
            <Typography align='right'>
              {label}
              {isRequired ? <span style={labelAsterisk}>*</span> : null}
            </Typography>
          </FormLabel>
        </>
      }
      value={
        <>
          <Field
            type='select'
            {...field}
            id={id}
            name={name}
            component={MuiSelect}
            placeholder={label}
            multiple={multiple}
            error={hasError}
            value={field.value}
            classes={classes}
            style={{ borderColor: grayMed, marginTop: 10, marginBottom: 10 }}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (field.onChange && isFunction(field.onChange)) {
                field.onChange(e);
              }
            }}
          >
            {canSelectNone && !multiple && (
              <MenuItem value='none'>
                <em> - </em>
              </MenuItem>
            )}

            {options.map((opt: SelectOption) => (
              <MenuItem value={opt.value} key={`opt-${opt.label}-${opt.value}`}>
                <Typography variant='body2'>{opt.label}</Typography>
              </MenuItem>
            ))}
          </Field>

          {!!name && <ErrorHelperText name={name} />}
        </>
      }
    />
  );
}
