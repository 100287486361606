import TagManager from "react-gtm-module";
import config from '~/config';

const GTM_ID = config.gtmId;
const APP_ROOT = "/cc";

/**
 * Initializes Google TagManager. Google Analytics account number is set in 
 * TagManager lookup table variable based on environment
 */
export const useGtm = () => {
  // gtmId is the Google TagManager account number
  const tagManagerArgs = {
    gtmId: GTM_ID,
  }

  /*
    Initialize tagManager if GTM account is set and not in the test environment; 
    build pipeline will fail if GTM is initialized when running units tests
  */ 
  if (tagManagerArgs && process.env.NODE_ENV !== "test") {
    TagManager.initialize(tagManagerArgs);

    TagManager.dataLayer({
      dataLayer: {
        appName: "CC", 
      }
    });
  }
};

/**
 * Returns a path string with a root string identifying the application prepended 
 * 
 * @param {string} path path used for pageview
 * 
 * @returns {string} path with APP_ROOT constant string prepended
 */
export const createPathString = (path: string) => {
  return APP_ROOT + path;
};

/**
 * Forces a "Record Page View" event to be recorded in GA.  Useful for logging initial
 * pageviews that are not recorded by the GTM History change trigger.
 */
export const recordPageView = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "Record Page View - CC",
    }
  });
}

/**
 * Causes a "Record CC Event - Generators" event to be recorded in GA with its action set
 * to action and label set to description.
 * 
 * @param {string} action GA event action text
 * @param {string} description  GA event label text
 * @param {string} [newPath] optional path overrides existing path
 */
export const recordGeneratorEvent = (action: string, description: string = "", 
  newPath?: string) => {

  // override path if newPath provided
  if (newPath)  {
    TagManager.dataLayer({
      dataLayer: {
        usePath: newPath, 
      }
    });
  }

  TagManager.dataLayer({
    dataLayer: {
      capturedText: action,
      description: description,
      event: "Record CC Event - Generators",
    }
  });
}

/**
 * Causes a "Record CC Event - Preferences" event to be recorded in GA with its action set
 * to action, and label set to affectedId and description.
 * 
 * @param {string} action GA event action text
 * @param {string} affectedId GA event label text describing key of entity modified
 * @param {string} description  GA event label text providing additional info
 * @param {string} [newPath] optional path overrides existing path
 */
export const recordPreferenceEvent = (action: string, affectedId: string, description: string = "", 
  newPath?: string) => {

  // override path if newPath provided
  if (newPath)  {
    TagManager.dataLayer({
      dataLayer: {
        usePath: newPath, 
      }
    });
  }

  TagManager.dataLayer({
    dataLayer: {
      capturedText: action,
      description: description,
      affectedId: affectedId,
      event: "Record CC Event - Preferences",
    }
  });
}
