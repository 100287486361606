import React from 'react';
import { useTranslation } from 'react-i18next';
import T from '@material-ui/core/Typography';

import RoutableAccordion from '~/ui/components/routable-accordion';

import { useCurrentGenerator } from '~/data/generator';

import { Grid } from '@material-ui/core';

import { TitleText } from '../-components/generator-management';

import { useClasses } from './styles';
import EditModal from './-components/edit-modal';
import StartNow from './-components/start-now';

import { useDate, useShortDateTimeFormat } from '~/utils/date';

import { paths } from '~paths';

export default function Exercise() {
  const { t } = useTranslation();
  const shortDateTimeFormat = useShortDateTimeFormat();
  const { format } = useDate();
  const classes = useClasses({});
  const [{ generator }] = useCurrentGenerator();
  const {
    id,
    status: { lastExercise },
  } = generator;
  const exercisePath = paths.dashboard.generators.show.exercise.with({ id });

  return (
    <RoutableAccordion
      {...{
        path: exercisePath,
        exitPath: paths.dashboard.generators.show.with({ id }),
        headerContent: <TitleText>{t('generator.exercise')}</TitleText>,
      }}
    >
      <Grid container spacing={24} justify={'space-between'}>
        <Grid item>
          <T variant='body1'>{t('exercise.lastCompleted')}</T>
        </Grid>
        <Grid item>
          <T variant='body1'>
            {lastExercise
              ? format(lastExercise, shortDateTimeFormat)
              : t('exercise.lastCompletedNever')}
          </T>
        </Grid>
        <Grid container spacing={8} justify={'flex-end'}>
          <Grid item xs={12} sm={4} className={classes.buttonContainer}>
            <EditModal />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.buttonContainer}>
            <StartNow />
          </Grid>
        </Grid>
      </Grid>
    </RoutableAccordion>
  );
}
