import React from 'react';
import { icons } from 'da-frontend-shared-ui-components';
import { PowerSourceState, PersistedAsset, OperationalStatus } from 'da-pcc-frontend-shared-domain';

const {
  GridStatusConnectedGenerator,
  GridStatusConnectedUtility,
  GridStatusDisconnectedUtility,
  GridStatusDisconnectedGenerator,
} = icons;

export function GeneratorStatusIcon({ generator }: { generator: PersistedAsset }) {
  let {
    status: { status, powerSource },
  } = generator;

  if (status === OperationalStatus.Offline || status === OperationalStatus.Unknown) {
      
    // get the appropriate disconnected powerSource icon value depending on whether the powerSource
    // is a generator or utility; 
    // powerSource is an enum where 0=none, 1=generator, 2=utility, default/null=unknown
    let powerSourceDisconnectedIcon = (powerSource === PowerSourceState.Generator) ? PowerSourceState.None : null;
    
    // set the new powerSourceDisconnectIcon value as the powerSource value;
    // this will provide the UI with the disconnected version of the powerSource icon 
    // when using the switch statement below
    powerSource = powerSourceDisconnectedIcon;
  }

  switch (powerSource) {
    case PowerSourceState.Generator:
      return <GridStatusConnectedGenerator />;
    case PowerSourceState.Utility:
      return <GridStatusConnectedUtility />;
    case PowerSourceState.None:
      return <GridStatusDisconnectedGenerator />;
    default:
      return <GridStatusDisconnectedUtility />;
  }
}
