import * as React from 'react';
import { CSSProperties } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageError } from 'da-frontend-shared-ui-components/lib/Errors';
import { errorStyles } from './styles';
import { useConfig } from '~/data/config';
import config from '~/config';

interface IProps {
    style?: CSSProperties;
}
  
export default function MissingInviteError(style) {
    const [t] = useTranslation();
    const [{ connectCloudSupportAddress }] = useConfig();

    const title = React.useCallback(() => {
        return (
            <span style={errorStyles.duplicateUserTitle}>
                <span style={errorStyles.duplicateUserTitleHeading}>{t('missingInviteError.titleBold')}</span>
            </span>
        )
    }, []);

    const description = React.useCallback(() => {
        const ownerRegisterLink = t('missingInviteError.ownerRegisterLink');
        const dealerRegisterLink = t('missingInviteError.dealerRegisterLink');
        const cumminsCare = t('missingInviteError.cumminsCare');

        return (
            <span style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <div>
                    {t('missingInviteError.description1')} 
                    <br /><br />
                    <Trans 
                        i18nKey={`missingInviteError.description2`}
                        values={{ownerRegisterLink: ownerRegisterLink}}>
                        <strong>If you are an Equipment Owner,</strong> register for Connect Cloud with your existing username <a href={config.paths.requestAccess} style={errorStyles.actionLink}>{{ownerRegisterLink}}</a>.
                    </Trans>
                    <br /><br />
                    <Trans 
                        i18nKey={`missingInviteError.description3`}
                        values={{dealerRegisterLink: dealerRegisterLink}}>
                        <strong>If you are a Dealer,</strong> sign in or register with <a href={config.paths.pccUrl}>{{dealerRegisterLink}}</a>.
                    </Trans>
                    <br /><br />
                    <Trans 
                        i18nKey={'missingInviteError.description4'}
                        values={{cumminsCare: cumminsCare}}>
                        <strong>Need More Help?</strong><br />
                        Contact <a href={`mailto:${connectCloudSupportAddress}`}>{{cumminsCare}}</a>
                    </Trans>
                </div>
            </span>
        )
    }, []);

    return (
        <PageError
          title={title}
          description={description}
          style={errorStyles.pageError}
        />
    )
}