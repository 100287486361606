import { DynamoEventSeverity } from 'da-pcc-frontend-shared-domain';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchEvents, fetchAlerts, prependNewEvent, acknowledgeAlert } from './actions';
import { CurrentGeneratorEventsState } from './types';

export const initialState: CurrentGeneratorEventsState = {
  loading: true,
  events: [],
  totalEvents: 0,
  alerts: [],
  totalAlerts: 0,
  error: undefined,
};

const eventsReducer = reducerWithInitialState(initialState)
  .cases([fetchEvents.started, fetchAlerts.started], (state) => ({
    ...state,
    loading: true,
  }))
  .case(
    fetchEvents.done,
    (
      state,
      {
        result: {
          items: events,
          meta: { totalItemCount: totalEvents },
        },
      }
    ) => ({
      ...state,
      events,
      totalEvents,
      loading: false,
    })
  )
  .case(fetchEvents.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
  .case(
    fetchAlerts.done,
    (
      state,
      {
        result: {
          items: alerts,
          meta: { totalItemCount: totalAlerts },
        },
      }
    ) => ({
      ...state,
      alerts,
      totalAlerts,
      loading: false,
    })
  )
  .case(fetchAlerts.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
  .case(
    acknowledgeAlert.done,
    (
      state,
      {
        result: {
          items: alerts,
          meta: { totalItemCount: totalAlerts },
        },
      }
    ) => ({
      ...state,
      alerts,
      totalAlerts,
      loading: false,
    })
  )
  .case(prependNewEvent, (state, event) => {
    const maxResults = 20;
    const isAlert = !event.isAcknowledged && event.severity !== DynamoEventSeverity.Information;
    return {
      ...state,
      events: [event, ...state.events].slice(0, maxResults),
      alerts: isAlert ? [event, ...state.alerts].slice(0, maxResults) : state.alerts,
    };
  });

export default eventsReducer;
