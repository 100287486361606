import { CSSProperties } from 'react';
import { colors } from 'da-frontend-shared-ui-components';

export const errorStyles: { [name: string]: CSSProperties } = {
    pageError: {
        flexGrow: 1,
      },

    duplicateUserTitle: {
        color: colors.grayDark
    },
    
    duplicateUserTitleHeading: {
    display: "block",
    fontSize: "1.3em",
    fontWeight: "bold",
    marginBlockEnd: '1em',
    },
}