import React from 'react';
import { Errors } from '~/ui/components/errors/errors';

export default function NoAccessRoute({match}) {

  const { errorStatus } = match.params;

  switch(errorStatus) {
    case '409': // If the error status is 409
      throw new Error(Errors.MissingInvite);
    case '412': // If the error status is 412
      throw new Error(Errors.NoCCUser);
    case '418': // If the error status is 418
      throw new Error(Errors.NoAppAccess);
  }

  return (
    <div className='ui text container'>
      <h1 className='ui header p-t-lg'></h1>
    </div>
  );
}
