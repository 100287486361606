import { makeStyles } from '@material-ui/styles';

export const modalStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    background: '#FFFFFF',
    fontFamily: 'Roboto,Arial,sans-serif',
    padding: 10,
    textAlign: 'center',
  },
  modalText: {
    width: '75%',
    wordWrap: 'break-word',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  modalButton: {
    color: '#FFFFFF',
    backgroundColor: '#007C7C',
  },
});
