import React from 'react';
import Panel from 'da-frontend-shared-ui-components/lib/Panel';
import { Grid, Typography } from '@material-ui/core';

import {
  textWrapperStyles,
  iconStyles,
  titleStyles,
  containerStyles,
  statusContent,
  statusText,
} from './styles';

export default function SmallStatusPanel({ Icon, label, value, ...props }) {
  return (
    <Panel {...props} container direction='row' style={containerStyles}>
      <Grid item style={statusContent}>
        <Icon style={iconStyles} />
      </Grid>
      <Grid item style={statusText}>
        <Typography variant='h2' component='h4' style={textWrapperStyles}>
          <label style={titleStyles}>{label}</label>
          <span>{value}</span>
        </Typography>
      </Grid>
    </Panel>
  );
}
