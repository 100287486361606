import { PowerSourceState } from 'da-pcc-frontend-shared-domain';

export function powerSourceToI18nKey(powerSource: PowerSourceState) {
  switch (powerSource) {
    case PowerSourceState.Generator:
      return 'generator.powersource.generator';
    case PowerSourceState.Utility:
      return 'generator.powersource.utility';
    case PowerSourceState.None:
      return 'generator.powersource.none';
    default:
      return 'generator.powersource.unavailable';
  }
}
