import { makeStyles } from '@material-ui/styles';
import { grayLight1 } from 'da-frontend-shared-ui-components/lib/styles/colors';
import { defaultTheme } from 'da-frontend-shared-ui-components';

export const useStyles = makeStyles({
  dealerContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${grayLight1}`,
    lineHeight: '1.5rem!important',
    '& h5': {
      lineHeight: '1.5rem!important',
    },
  },

  dealerSubHeading: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },

  dealerAction: {
    display: 'flex',
    alignItems: 'center',
    [defaultTheme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
});
