import { makeStyles } from '@material-ui/styles';
import { redPrimary, white } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  btn: {
    width: '8.25rem',
    'min-height': '1.875rem',
    borderRadius: '5px',
    border: 'none',
  },
  dealerBtn: {
    backgroundColor: redPrimary,
  },
  dealerInfoBtnText: {
    color: white,
  },
});
