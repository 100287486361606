import React, { useContext, createContext, useReducer, useCallback, useEffect } from 'react';
import { useAPI } from '..';
import { IConfigState, ConfigDispatch } from './types';
import configReducer, { initialState } from './reducer';
import { fetchConfig } from './actions';

const ConfigStateContext = createContext<IConfigState | undefined>(undefined);
const ConfigDispatchContext = createContext<ConfigDispatch | undefined>(undefined);

export function ConfigProvider({ children }) {
  const { config } = useAPI();
  const [state, dispatch] = useReducer(configReducer, initialState);

  const getConfig = useCallback(() => {
    dispatch(fetchConfig.started());
    config
      .getConfig()
      .then((config) => {
        dispatch(fetchConfig.done({ result: config }));
      })
      .catch((error) => {
        dispatch(fetchConfig.failed({ error }));
      });
  }, [config, dispatch]);

  useEffect(getConfig, [getConfig]);

  return (
    <ConfigStateContext.Provider value={state}>
      <ConfigDispatchContext.Provider value={{}}>{children}</ConfigDispatchContext.Provider>
    </ConfigStateContext.Provider>
  );
}

function useConfigState() {
  const context = useContext(ConfigStateContext);
  if (context === undefined) {
    throw new Error('useConfigState must be used within a ConfigProvider');
  }
  return context;
}

function useConfigDispatch() {
  const context = useContext(ConfigDispatchContext);
  if (context === undefined) {
    throw new Error('useConfigDispatch must be used within a ConfigProvider');
  }
  return context;
}

export function useConfig(): [IConfigState, ConfigDispatch] {
  return [useConfigState(), useConfigDispatch()];
}
