import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  innerHTML: {
    fontFamily: 'Roboto, Arial',
    marginTop: '40px',
    marginBottom: '60px',
    '& p': {
      'margin-top': '20px',
    },
    '& h1': {
      'font-size': '30px',
      'font-weight': 'bold',
    },
    '& h2': {
      'font-size': '22px',
      'font-weight': 'bold',
      'margin-top': '30px',
    },
    '& h3': {
      'font-size': '18px',
      'font-weight': 'bold',
      'margin-top': '20px',
    },
  },
});
