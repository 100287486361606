import { Configuration, BaseAPI, ResponseContext, Middleware } from 'da-pcc-frontend-shared-domain';

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

interface IApiOptions {
  onUnauthorized: () => void;
  onForbidden: () => void;
  onHttp409: () => void;
  onHttp412: () => void;
  onHttp418: () => void;
}

function buildUnauthenticatedMiddleware(options: IApiOptions): Middleware {
  const { onUnauthorized } = options;

  return {
    async post(context: ResponseContext): Promise<Response | void> {
      if (context.response.status === 401) {
        onUnauthorized();
      }
    },
  };
}

function buildForbiddenMiddleware(options: IApiOptions): Middleware {
  const { onForbidden } = options;

  return {
    async post(context: ResponseContext): Promise<Response | void> {
      if (context.response.status === 403) {
        onForbidden();
      }
    },
  };
}

/**
 * HTTP Code 409 is currently used to indicate a user without an invite
 */
function buildHttp409Middleware(options: IApiOptions): Middleware {
  const { onHttp409 } = options;

  return {
    async post(context: ResponseContext): Promise<Response | void> {
      if (context.response.status === 409) {
        onHttp409();
      }
    },
  };
}

/**
 * HTTP Code 412 is currently used to indicate need to redirect user to
 * No Access Error Page
 */
function buildHttp412Middleware(options: IApiOptions): Middleware {
  const { onHttp412 } = options;

  return {
    async post(context: ResponseContext): Promise<Response | void> {
      if (context.response.status === 412) {
        onHttp412();
      }
    },
  };
}

/**
 * HTTP Code 418 is currently used to indicate need to redirect user to
 * No Access Error Page
 */
function buildHttp418Middleware(options: IApiOptions): Middleware {
  const { onHttp418 } = options;

  return {
    async post(context: ResponseContext): Promise<Response | void> {
      if (context.response.status === 418) {
        onHttp418();
      }
    },
  };
}

export function configure<T extends BaseAPI>(api: ApiConstructor<T>, options: IApiOptions): T {
  const unauthenticatedMiddleware = buildUnauthenticatedMiddleware(options);
  const forbiddenMiddleware = buildForbiddenMiddleware(options);
  const http409Middleware = buildHttp409Middleware(options);
  const http412Middleware = buildHttp412Middleware(options);
  const http418Middleware = buildHttp418Middleware(options);

  const config = new Configuration({
    basePath: '/api/dashboard/v1', // default configuration, just relative to the root URI
    middleware: [unauthenticatedMiddleware, forbiddenMiddleware, http409Middleware, http412Middleware, http418Middleware],
  });
  const instance = new api(config);

  fixContextForApiClass(instance, api);

  return instance;
}

function fixContextForApiClass(api, klass) {
  Object.getOwnPropertyNames(api)
    .concat(Object.getOwnPropertyNames(klass.prototype))
    .forEach((name) => {
      const method = api[name];
      const canBind = !!method.bind;

      if (canBind) {
        api[name] = method.bind(api);
      }
    });

  return api;
}
