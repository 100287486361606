import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  confirmButton: {
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
    },
  },
  confirmWelcome: {
    marginBottom: '1rem',
  },
  registrationContainer: {
    margin: '0 auto',
    maxWidth: '51rem',
    padding: '4rem 0',
  },
});
