import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AccountPermission } from 'da-pcc-frontend-shared-domain';

import { fetchPermissions } from './actions';
import { PermissionState } from './types';

export const initialState: PermissionState = {
  loading: true,
  permissions: new Set<AccountPermission>(),
};

const permissionsReducer = reducerWithInitialState(initialState)
  .case(fetchPermissions.done, (state, { result: permissions }) => ({
    ...state,
    permissions: new Set<AccountPermission>(permissions),
    loading: false,
  }))
  .case(fetchPermissions.failed, (state, { error }) => ({
    ...state,
    loading: false,
  }));

export default permissionsReducer;
