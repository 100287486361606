import { AppHeader as SharedAppHeader, AlertBanner } from 'da-frontend-shared-ui-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigProvider } from '~/data/config';
import { DealerProvider } from '~/data/dealer';
import { useSession, useSessionTimeout, useForceSessionTimeout } from '~/data/session';
import AppFooter from '~/ui/routes/-components/app-footer';
import AppHeader from '~/ui/routes/-components/app-header';
import { ConsumerAccountProvider } from '~/data/consumerAccount';
import { GeneratorsProvider } from '~/data/generators';
import config from '~/config';

export default function AppWrapper({ children }) {
  const [t] = useTranslation();
  const [{ isAuthenticated }] = useSession();
  const { expired, isWarning } = useSessionTimeout();
  const { forceLogout } = useForceSessionTimeout();
  useEffect(() => {
    if (isAuthenticated && expired) {
      window.location.assign(
        `${config.paths.logout}?redirectUri=${encodeURI(
          window.location.pathname + window.location.search
        )}`
      );
    }
  }, [expired, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && forceLogout) {
      window.location.assign(
        `${config.paths.logout}?redirectUri=${encodeURI(
          window.location.pathname + window.location.search
        )}`
      );
    }
  }, [forceLogout, isAuthenticated]);

  return isAuthenticated ? (
    <DealerProvider>
      <ConfigProvider>
        <ConsumerAccountProvider>
          <GeneratorsProvider>
            {isWarning && <AlertBanner>{t('sessionError.message')}</AlertBanner>}
            <AppHeader />
            {children}
            <AppFooter />
          </GeneratorsProvider>
        </ConsumerAccountProvider>
      </ConfigProvider>
    </DealerProvider>
  ) : (
    <>
      <SharedAppHeader
        title={<div style={{ width: '50px' }}>{t('appname')}</div>}
        primaryNavigationLinks={undefined}
        secondaryNavigationLinks={undefined}
      />
      {children}
      <AppFooter />
    </>
  );
}
