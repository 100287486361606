import React, { CSSProperties } from 'react';
import { Typography as T } from '@material-ui/core';
import { Footer } from 'da-frontend-shared-ui-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paths } from '~paths';
import config from '~/config';

const linkStyle: CSSProperties = {
  textDecoration: 'none',
};

export default function AppFooter() {
  const { t } = useTranslation();

  return (
    <Footer
      links={{
        userTraining: (props) => (
          <a href={config.paths.userTraining} rel='noopener noreferrer' target='_blank'>
            <T {...props}>{t('nav.userTraining')}</T>
          </a>
        ),
        privacy: (props) => (
          <Link style={linkStyle} to={paths.privacy.path}>
            <T {...props}>{t('nav.privacyPolicy')}</T>
          </Link>
        ),
        terms: (props) => (
          <Link style={linkStyle} to={paths.termsConditions.path}>
            <T {...props}>{t('nav.termsAndConditions')}</T>
          </Link>
        ),
      }}
    />
  );
}
