import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { paths } from '~paths';
import NotFoundRoute from './not-found';
import NoAccessRoute from './no-access';

export default class ErrorsRootRoute extends React.Component {
  public render() {
    return (
      <Switch>
        <Route exact path={paths.errors.notFound.path} component={NotFoundRoute} />
        <Route exact path={`${paths.errors.noAccess.path}/:errorStatus?`} component={NoAccessRoute} />
        <Route component={NotFoundRoute} />
      </Switch>
    );
  }
}
