import { useMemo } from 'react';
import * as Yup from 'yup';

export function useInviteSchema(t: TFunction) {
  return useMemo(() => generateInviteSchema(t), [t]);
}

function generateInviteSchema(t: TFunction) {
  return Yup.object({
    name: Yup.string().required(requiredFieldMessage(t, 'name')),
    email: Yup.string()
      .email()
      .required(requiredFieldMessage(t, 'email')),
  });
}

function requiredFieldMessage(t: TFunction, attribute: string) {
  return t('errors.fields.required', { field: t(`userInvite.attributes.${attribute}`) });
}
