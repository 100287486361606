import { icons } from 'da-frontend-shared-ui-components';
import { OperationalStatus } from 'da-pcc-frontend-shared-domain';

const { Online, Help, Fault, Offline, Warning } = icons;

export function iconForStatus(status: string | OperationalStatus) {
  switch (status) {
    case OperationalStatus.Normal:
      return Online;
    case OperationalStatus.Warning:
      return Warning;
    case OperationalStatus.Fault:
      return Fault;
    case OperationalStatus.Offline:
      return Offline;
    case OperationalStatus.Unknown:
      return Help;
    default:
      return Help;
  }
}
