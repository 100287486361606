import { actionCreatorFactory } from 'typescript-fsa';
import { Event, PagedResultOfEvents } from 'da-pcc-frontend-shared-domain';

const createAction = actionCreatorFactory('ASSET/EVENTS');
const fetchEvents = createAction.async<void, PagedResultOfEvents, Error>('FETCH_EVENTS');
const fetchAlerts = createAction.async<void, PagedResultOfEvents, Error>('FETCH_ALERTS');
const acknowledgeAlert = createAction.async<Event[], PagedResultOfEvents, Error>(
  'ACKNOWLEDGE_ALERT'
);

const prependNewEvent = createAction<Event>('PREPEND_ACTION');

export { fetchEvents, fetchAlerts, acknowledgeAlert, prependNewEvent };
