import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchNotifications, needToVerify } from './actions';
import { NotificationsState } from './types';

export const initialState: NotificationsState = {
  loading: true,
  settings: undefined,
  error: undefined,
  isVerifyingEmail: false,
};

const notificationsReducer = reducerWithInitialState(initialState)
  .case(needToVerify, (state) => ({
    ...state,
    isVerifyingEmail: true,
  }))
  .case(fetchNotifications.done, (state, { result: settings }) => ({
    ...state,
    settings,
    loading: false,
  }))
  .case(fetchNotifications.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default notificationsReducer;
