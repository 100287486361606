import React, { useContext, createContext, useReducer, useEffect } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';

import { useAPI } from '~/data';

import { useAsyncFn } from 'react-use';

import { fetchUsers } from './actions';
import reducer, { initialState } from './reducer';
import { UsersState, UsersDispatch } from './types';

const UsersStateContext = createContext<UsersState | undefined>(undefined);
const UsersDispatchContext = createContext<UsersDispatch | undefined>(undefined);

export function UsersProvider({ children }) {
  const { users } = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [, getUsers] = useAsyncFn(async () => {
    dispatch(fetchUsers.started());
    users
      .getConnectCloudUsers()
      .then((users) => dispatch(fetchUsers.done({ result: users })))
      .catch((error) => dispatch(fetchUsers.failed({ error })));
  }, [users, fetchUsers, dispatch]);

  const invite = useAsyncFn(
    async (name: string, email: string) => {
      await users.getConnectCloudInvites({
        userInviteInput: {
          name,
          email,
        },
      });
      await getUsers();
    },
    [users, getUsers]
  );

  const revokeInvite = useAsyncFn(
    async (id: string) => {
      await users.removeConnectCloudInvite({
        inviteId: id,
      });
      await getUsers();
    },
    [users, getUsers]
  );

  const removeUser = useAsyncFn(
    async (id: string) => {
      await users.removeConnectCloudUser({
        subscriptionId: id,
      });
      await getUsers();
    },
    [users, getUsers]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={{ invite, revokeInvite, removeUser }}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
}

function useUsersState() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error('useUsersState must be used within a UsersProvider');
  }
  return context;
}

function useUsersDispatch() {
  const context = useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error('useUsersDispatch must be used within a UsersProvider');
  }
  return context;
}

export function useUsers(): [UsersState, UsersDispatch] {
  return [useUsersState(), useUsersDispatch()];
}
