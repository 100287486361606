import React, { useEffect } from 'react';
import { Switch } from 'react-router';
import { paths } from '~paths';
import { Route } from '~/utils/routing';
import Landing from './root';
import TermsConditions from './terms-conditions';
import { useRegistration } from '~/data/registration';
import { useRouter } from '~/utils/hooks';
import { Loader } from 'da-frontend-shared-ui-components';

export default function InviteIndexRoute() {
  const {
    match: {
      params: { inviteCode },
    },
  } = useRouter();
  const [{ error, loading }, { getInvite }] = useRegistration();

  useEffect(() => {
    getInvite(inviteCode);
  }, [inviteCode, getInvite]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={paths.invite.show.termsConditions.path} component={TermsConditions} />
      <Route exact path={paths.invite.show.path} component={Landing} />
    </Switch>
  );
}
