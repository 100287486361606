import React from 'react';
import { Switch } from 'react-router-dom';

import { Route } from '~/utils/routing';
import { paths } from '~paths';

import List from './list';
import New from './new';

export default function PreferencesGeneratorsRoute() {
  return (
    <Switch>
      <Route exact path={paths.preferences.generators.path} component={List} />
      <Route path={paths.preferences.generators.new.path} component={New} />
    </Switch>
  );
}
