import { route } from 'static-route-paths';

export const paths = route({
  root: route(),
  privacy: route('privacy'),
  termsConditions: route('terms-and-conditions'),

  invite: route('ViewInvite', {
    show: route(':inviteCode', {
      termsConditions: route('terms-and-conditions'),
    }),
  }),

  registration: route('registration', {
    termsConditions: route('terms-and-conditions'),
    confirmation: route('confirmation'),
  }),

  dashboard: route('dashboard', {
    generators: route('generators', {
      show: route(':id', {
        alerts: route('alerts'),
        events: route('events'),
        exercise: route('exercise'),
        control: route('control'),
        maintenance: route('maintenance'),
        generatorData: route('generatorData'),
      }),
    }),
  }),

  preferences: route('preferences', {
    generators: route('generators', {
      show: route(':id'),
      new: route('new'),
    }),
    users: route('users'),
    notifications: route('notifications'),
    dealers: route('dealers', {
      show: route(':id', {
        pending: route('pending'),
      }),
    }),
  }),

  errors: route({
    notFound: route('not-found'),
    noAccess: route('no-access'),
  }),
});

export function buildIsActive(currentPath: string) {
  return (testAgainstPath: string) => {
    const regex = new RegExp(`^${testAgainstPath}`, 'ig');

    return regex.test(currentPath);
  };
}
