import React, { useCallback } from 'react';
import { useInviteSchema, useUsers } from '~/data/users';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { has } from 'lodash';
import { Input, Grid, InputLabel, FormHelperText } from '@material-ui/core';
import { ccglobalStyles } from '~/ui/styles/defaults';
import { ConfirmModal, Form } from 'da-frontend-shared-ui-components';
import { recordPreferenceEvent } from '~/utils/gtm';

const initialValues = {
  name: '',
  email: '',
};

export default function InviteModal({ close }) {
  const cc = ccglobalStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useInviteSchema(t);
  const [
    ,
    {
      invite: [, invite],
    },
  ] = useUsers();

  const onSubmit = useCallback(
    async (values) => {
      const { name, email } = values;

      await invite(name, email);

      enqueueSnackbar(t('users.invite.success'));
      recordPreferenceEvent("Invite User", `Invite User`);
      close();
    },
    [close, enqueueSnackbar, invite, t]
  );

  const handleClose = useCallback(() => {
    enqueueSnackbar(t('users.invite.abort'));
    close();
  }, [close, enqueueSnackbar, t]);

  return (
    <div>
      <Form initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ handleSubmit, handleBlur, handleChange, values, errors, isValid }) => {
          return (
            <ConfirmModal
              title={t('users.invite.title')}
              confirmButtonText={t('users.invite.confirm')}
              cancelButtonText={t('common.cancel')}
              enableConfirm={isValid}
              onConfirmClick={handleSubmit}
              onCancelClick={handleClose}
            >
              <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                <Grid item xs={12} sm={4} className={cc.formLabelGridItem}>
                  <InputLabel className='responsive' required>
                    {t('users.name')}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={cc.formInputGridItem}>
                  <Input
                    id='name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {has(errors, 'name') && (
                    <FormHelperText id='helper-text' className={cc.formHelper}>
                      {errors.name}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                <Grid item xs={12} sm={4} className={cc.formLabelGridItem}>
                  <InputLabel className='responsive' required>
                    {t('users.email')}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={cc.formInputGridItem}>
                  <Input
                    id='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {has(errors, 'email') && (
                    <FormHelperText id='helper-text' className={cc.formHelper}>
                      {errors.email}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </ConfirmModal>
          );
        }}
      </Form>
    </div>
  );
}
