import React, { useCallback } from 'react';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography as T } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ConfirmModal } from 'da-frontend-shared-ui-components';
import { paths } from '~paths';
import { useDealer } from '~/data/dealer';
import { useModal, useRouter } from '~/utils/hooks';
import DealerLinks from '~/ui/components/resources/dealers/links';
import DealerInfo from '~/ui/components/resources/dealers/contact';
import { useStyles } from '../../styles';
import { ccglobalStyles } from '~/ui/styles/defaults';
import ResponsiveContainer from '~/ui/components/responsive-container';
import { formatAddress } from '~/utils/address';
import { recordPreferenceEvent } from '~/utils/gtm';

export default function PreferencesDealersShowPendingRoute() {
  const [t] = useTranslation();
  const classes = useStyles({});
  const cc = ccglobalStyles({});
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, open, close } = useModal();
  const { history } = useRouter();
  const [
    { requestedDealer: dealer },
    {
      cancelRequest: [, cancelRequest],
    },
  ] = useDealer();

  const handleCancel = useCallback(async () => {
    await cancelRequest();

    recordPreferenceEvent("Cancel Pending Dealer", `Cancel Dealer ID: ${dealer.id}`);

    enqueueSnackbar(t('dealers.cancel.success'));
    history.push(paths.preferences.dealers.path);
  }, [cancelRequest, enqueueSnackbar, history, t]);

  if (!dealer) {
    return <Redirect to={paths.preferences.dealers.path} />;
  }

  return (
    <ResponsiveContainer xs={12} lg={8}>
      <div className={cc.titleSpacing}>
        <T variant='h1'>{t('dealers.details.titlePending')}</T>
      </div>

      <Grid container spacing={16} className={classes.dealerContainer}>
        <Grid item xs={12} md={6}>
          <DealerInfo name={dealer.name} address={formatAddress(dealer.address)} />
        </Grid>

        <Grid item xs={12} md={4}>
          <DealerLinks
            email={dealer.contactInformation.email}
            phone={dealer.contactInformation.phone}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.dealerAction}>
          <Button variant='contained' color='secondary' onClick={open}>
            {t('dealers.details.cancelPending')}
          </Button>
          {isOpen && (
            <ConfirmModal
              title={t('dealers.cancel.title')}
              message={t('dealers.cancel.message')}
              confirmButtonText={t('dealers.cancel.accept')}
              cancelButtonText={t('common.cancel')}
              onConfirmClick={handleCancel}
              onCancelClick={close}
              enableConfirm
            />
          )}
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
}
