import React, { CSSProperties } from 'react';
import { Typography as T, Grid } from '@material-ui/core';
import { Settings } from 'da-frontend-shared-ui-components/lib/icons';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useGeneratorsList } from '~/data/generators';

import { useButtonStyles, useContainerStyles } from './styles';
import EditModal from './edit-modal';

import { useModal } from '~/utils/hooks';

interface IProps {
  style?: CSSProperties;
}

export default function GeneratorsTable({ style = {} }: IProps) {
  const [{ generators }] = useGeneratorsList();
  const classes = useContainerStyles({});

  return (
    <div className={classes.root} style={style}>
      {generators.map((generator) => {
        return <Row generator={generator} key={generator.id} />;
      })}
    </div>
  );
}

function Row({ generator }) {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, open, close } = useModal();
  const classes = useButtonStyles({});

  const handleConfirmDestroy = () => {
    enqueueSnackbar(t('generator.remove.success', { name: generator.name }));
  };

  return (
    <>
      <div className={classes.root} role='button' onClick={open}>
        <Grid container spacing={16} justify='space-between'>
          <Grid item>
            <T>{generator.name}</T>
          </Grid>
          <Grid item>
            <Settings className={classes.settings} />
          </Grid>
        </Grid>
      </div>

      {isOpen && <EditModal generator={generator} close={close} confirm={handleConfirmDestroy} />}
    </>
  );
}
