import { defaultTheme } from 'da-frontend-shared-ui-components';
import { makeStyles } from '@material-ui/styles';

import { redError } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const spacing = defaultTheme.spacing.unit;
export const defaultSeparation = spacing * 2;

export const ccglobalStyles = makeStyles({
  titleSpacing: {
    marginTop: defaultSeparation,
    marginBottom: defaultSeparation * 2,
  },

  formGridContainer: {
    paddingBottom: '20px',
  },
  formSection: {
    paddingBottom: '2rem',
  },
  formLabelGridItem: {
    position: 'relative',
    padding: '5px',
    alignSelf: 'center',
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  formInputGridItem: {
    padding: '5px',
  },
  formPostInputGridItem: {
    padding: '5px',
    alignSelf: 'center',
  },
  formHelper: {
    [defaultTheme.breakpoints.down('xs')]: {
      position: 'relative',
    },
    paddingBottom: '5px',
    color: redError,
  },
  formHelperRelative: {
    position: 'relative',
  },
  formHelper2: {
    position: 'relative',
    paddingBottom: '15px',
  },
  resetMargin: {
    marginLeft: '0',
  },
  isRequired: {
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'right',
      position: 'absolute',
      right: '0',
    },
    display: 'inline-block',
    textAlign: 'left',
    width: '12px',
    marginLeft: '0',
    color: redError,
  },
  bottomSpaced: {
    marginBottom: '1rem',
  },
  formButtonleftSpaced: {
    marginLeft: '1rem',
  },
});
