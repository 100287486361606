export interface IParsedError {
  title: string;
  body?: string | string[];
}

export function parseError(error: any): IParsedError {
  if (typeof error === 'string') {
    return {
      title: error,
      body: undefined,
    };
  }

  if (error instanceof Response) {
    return {
      title: error.statusText,
      body: undefined,
    };
  }

  // Normal JS Errors
  const title = error.message || error;
  const body = undefined;

  return { title, body };
}
