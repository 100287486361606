import React from 'react';
import { OperationalStatus } from 'da-pcc-frontend-shared-domain';

import { iconForStatus } from '~/utils/generator/icon-for-status';

interface IProps {
  status: string | OperationalStatus;
  className?: string;
}

export default function StatusIcon({ status, ...props }: IProps) {
  const Icon = iconForStatus(status);

  return <Icon {...props} />;
}
