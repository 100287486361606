import React, { useCallback } from 'react';
import T from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Event } from 'da-pcc-frontend-shared-domain';
import { Trash } from 'da-frontend-shared-ui-components/lib/icons';
import ConfirmModal from 'da-frontend-shared-ui-components/lib/ConfirmModal';
import { useDate } from '~/utils/date';
import { useTranslation } from 'react-i18next';
import { useCurrentGeneratorEvents } from '~/data/generator/events';
import { useModal } from '~/utils/hooks';
import { dayStyle, timeStyle, eventStyle } from './styles';
import { recordGeneratorEvent } from '~/utils/gtm';

export function Table({ alerts, title }: { alerts: Event[]; title: string }) {
  if (alerts.length === 0) {
    return null;
  }

  return (
    <div style={dayStyle}>
      <T variant='h3' style={timeStyle}>
        {title}
      </T>

      {alerts.map((alertEvent) => {
        return <Row key={alertEvent.timestamp.getTime()} {...{ alertEvent }} />;
      })}
    </div>
  );
}

export default function Row({ alertEvent }: { alertEvent: Event }) {
  const [t] = useTranslation();
  const [
    ,
    {
      acknowledge: [acknowledgeState, acknowledge],
    },
  ] = useCurrentGeneratorEvents();

  const { isOpen, open, close } = useModal();
  const { format } = useDate();

  const onConfirm = useCallback(async () => {
    await acknowledge(alertEvent);
    recordGeneratorEvent("Acknowledge", `${alertEvent.code} ${alertEvent.message}`);
    close();
  }, [alertEvent, acknowledge, close]);

  return (
    <Grid
      key={alertEvent.timestamp.getDate()}
      container
      direction='row'
      justify='space-between'
      style={eventStyle}
    >
      <T variant='body1'>{alertEvent.message || alertEvent.severity}</T>

      <div>
        <Grid container direction='row' alignItems='center'>
          <T variant='body1'>{format(alertEvent.timestamp)}</T>

          <IconButton onClick={open} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Trash />
          </IconButton>
        </Grid>

        {isOpen && (
          <ConfirmModal
            title={t('notifications.confirmDelete.title')}
            confirmButtonText={
              acknowledgeState.loading
                ? t('notifications.confirmDelete.inProgress')
                : t('notifications.confirmDelete.confirm')
            }
            cancelButtonText={t('common.cancel')}
            enableConfirm={!acknowledgeState.loading}
            onConfirmClick={onConfirm}
            onCancelClick={close}
          >
            <T>{t('notifications.confirmDelete.message')}</T>
          </ConfirmModal>
        )}
      </div>
    </Grid>
  );
}
