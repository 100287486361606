import React from 'react';
import T from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { useCurrentGenerator } from '~/data/generator';

import { useDate } from '~/utils/date';

import { ccglobalStyles } from '~/ui/styles/defaults';

export default function Header(props) {
  const { t } = useTranslation();
  const { distanceInWordsToNow } = useDate();
  const [{ generator }] = useCurrentGenerator();
  const {
    name,
    status: { lastUpdate },
  } = generator;

  const cc = ccglobalStyles({});

  return (
    <div {...props} className={cc.titleSpacing}>
      <T variant='h1'>{name}</T>
      <T variant='subtitle1'>
        {t('timeago', { action: t('common.updated'), time: distanceInWordsToNow(lastUpdate) })}
      </T>
    </div>
  );
}
