import React from 'react';
import { TableRow, TableCell, Typography as T } from '@material-ui/core';
import { Trash } from 'da-frontend-shared-ui-components/lib/icons';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InviteConfirmCancel from '~/ui/routes/preferences/users/list/-components/users-table/row/revoke-access';
import ModalState from '~/ui/components/modal-state';
import { UserInfoModel } from 'da-pcc-frontend-shared-domain';
import { useStyles } from './styles';
import { useUsers } from '~/data/users';
import { recordPreferenceEvent } from '~/utils/gtm';

export default function UsersTableRow({ user }: { user: UserInfoModel }) {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { enqueueSnackbar } = useSnackbar();
  const [
    ,
    {
      removeUser: [, removeUser],
      revokeInvite: [, revokeInvite],
    },
  ] = useUsers();

  const handleRevokation = async () => {
    if (user.isActive) {
      await removeUser(user.id);
    } else {
      await revokeInvite(user.id);
    }

    enqueueSnackbar(t('users.invite.cancel.success'));
  };

  return (
    <TableRow>
      <TableCell>
        <T className={`${classes.name} ${user.isActive ? 'active' : 'invited'}`}>{user.name}</T>
        <a className={classes.email} href={`mailto:${user.email}`}>
          {user.email}
        </a>
        {!user.isActive && <T>{t('users.invited')}</T>}
      </TableCell>
      <TableCell align='right'>
        <ModalState>
          {({ isOpen, open, close }) => {
            return (
              <div className={classes.rowTrash}>
                {user.canDelete && <Trash className={classes.trash} onClick={open} />}
                {isOpen && (
                  <InviteConfirmCancel
                    {...{
                      close,
                      onConfirm() {
                        handleRevokation();
                        recordPreferenceEvent("Revoke User", `Revoke User ID: ${user.id}`);
                        close();
                      },
                    }}
                  />
                )}
              </div>
            );
          }}
        </ModalState>
      </TableCell>
    </TableRow>
  );
}
