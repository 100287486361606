import React from 'react';
import { Switch } from 'react-router-dom';
import { paths } from '~paths';
import { Route } from '~/utils/routing';
import { List } from './list';
import { UsersProvider } from '~/data/users';

export default function PreferencesUsersRoute() {
  return (
    <UsersProvider>
      <Switch>
        <Route exact path={paths.preferences.users.path} component={List} />
      </Switch>
    </UsersProvider>
  );
}
