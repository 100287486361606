import { makeStyles } from '@material-ui/styles';
import { colors } from 'da-frontend-shared-ui-components';

export const useStyles = makeStyles((theme: any) => ({
  exerciseStatusBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: colors.redAccent,
  },
  exerciseToggle: {
    color: colors.white,
  },
  exerciseInfo: {
    backgroundColor: colors.redAccent,
    color: colors.white,
    width: '300px',
    padding: '12px',
  },
}));
