import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';

import { useAPI } from '../..';
import { useCurrentGenerator } from '..';
import { fetchExerciseSchedule } from './actions';
import reducer, { initialState } from './reducer';
import { ExerciseScheduleDispatch, ExerciseScheduleState, ExerciseSchedule } from './types';
import { asDayOfWeek, asHour, asQuarterHour, asArrowExerciseSchedule, asDate } from './utils';
import { useCommands } from '../commands';

const ExerciseScheduleStateContext = createContext<ExerciseScheduleState | undefined>(undefined);
const ExerciseScheduleDispatchContext = createContext<ExerciseScheduleDispatch | undefined>(
  undefined
);

export function ExerciseScheduleProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { assets } = useAPI();
  const [{ generator }] = useCurrentGenerator();
  const [
    ,
    {
      setExercise: [setExerciseCommandState, setExerciseCommand],
    },
  ] = useCommands();
  const { id: assetId } = generator;

  const getExerciseSchedule = useCallback(() => {
    dispatch(fetchExerciseSchedule.started());
    assets
      .getExerciseSchedule({ assetId })
      .then(({ startDate: time, interval }) =>
        dispatch(
          fetchExerciseSchedule.done({
            result: {
              day: asDayOfWeek(time),
              atHour: asHour(time),
              atMinute: asQuarterHour(time),
              repeat: asArrowExerciseSchedule(interval),
            },
          })
        )
      )
      .catch((error) => dispatch(fetchExerciseSchedule.failed({ error })));
  }, [assets, assetId, dispatch]);

  const updateExerciseSchedule = useCallback(
    (schedule: ExerciseSchedule) => {
      const date = asDate(schedule.day, schedule.atHour, schedule.atMinute);
      return setExerciseCommand({
        date,
        tzOffset: date.getTimezoneOffset(),
        repeat: schedule.repeat,
      });
    },
    [setExerciseCommand]
  );

  useEffect(() => {
    getExerciseSchedule();
  }, [getExerciseSchedule]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <ExerciseScheduleStateContext.Provider value={state}>
      <ExerciseScheduleDispatchContext.Provider
        value={{ updateExerciseSchedule: [setExerciseCommandState, updateExerciseSchedule] }}
      >
        {children}
      </ExerciseScheduleDispatchContext.Provider>
    </ExerciseScheduleStateContext.Provider>
  );
}

function useExerciseScheduleState() {
  const context = useContext(ExerciseScheduleStateContext);
  if (context === undefined) {
    throw new Error('useExerciseScheduleState must be used within a ExerciseScheduleProvider');
  }
  return context;
}

function useExerciseScheduleDispatch() {
  const context = useContext(ExerciseScheduleDispatchContext);
  if (context === undefined) {
    throw new Error('useExerciseScheduleDispatch must be used within a ExerciseScheduleProvider');
  }
  return context;
}

export function useExerciseSchedule(): [ExerciseScheduleState, ExerciseScheduleDispatch] {
  return [useExerciseScheduleState(), useExerciseScheduleDispatch()];
}
