import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  pageContainer: {
    padding: '1.25rem 1.875rem 3.75rem',
  },
  maxWidthContainer: {
    width: '100%',
    maxWidth: '1000px',
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  h1: {
    marginBottom: '2rem',
  },
  h5: {
    fontWeight: 'normal',
  },
});
