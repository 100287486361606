import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Typography as T,
  FormHelperText,
} from '@material-ui/core';
import { Form } from 'da-frontend-shared-ui-components';
import {
  NotificationMethod,
  NotificationSeverity,
  NotificationInput,
} from 'da-pcc-frontend-shared-domain/lib';
import { useNotifications, useNotificationSchema } from '~/data/notifications';
import { useTimezones, useTimezone } from '~/data/timezones';
import { useStyles } from './styles';
import { ccglobalStyles } from '~/ui/styles/defaults';
import { FormikErrors } from 'formik';
import { recordPreferenceEvent } from '~/utils/gtm';

export default function Settings() {
  const classes = useStyles({});
  const cc = ccglobalStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [didUserAttemptToSubmit, setDidUserAttemptToSubmit] = useState(false);
  const [
    { settings, isVerifyingEmail },
    {
      updateNotificationSettings: [updateNotificationStatus, updateNotificationSettings],
      sendVerificationCode: [sendVerificationCodeStatus, sendVerificationCode],
    },
  ] = useNotifications();
  const schema = useNotificationSchema(t, isVerifyingEmail);

  const onSubmit = useCallback(
    (values: NotificationInput) => {
      updateNotificationSettings(values);
      recordPreferenceEvent("Edit Notifications", `Notifications Enabled: ${values.enabled}`);
    },
    [updateNotificationSettings]
  );

  useEffect(() => {
    if ('value' in updateNotificationStatus || 'error' in updateNotificationStatus) {
      enqueueSnackbar(
        t(
          'notificationsForm.' +
            ('value' in updateNotificationStatus ? 'saveMessage' : 'errorMessage')
        )
      );
    }
  }, [enqueueSnackbar, t, updateNotificationStatus, updateNotificationStatus.loading]);

  useEffect(() => {
    if ('value' in sendVerificationCodeStatus || 'error' in sendVerificationCodeStatus) {
      enqueueSnackbar(
        t(
          'notificationsForm.' +
            ('value' in sendVerificationCodeStatus ? 'verifyMessage' : 'verifyErrorMessage')
        )
      );
    }
  }, [enqueueSnackbar, sendVerificationCodeStatus, sendVerificationCodeStatus.loading, t]);

  const timezones = useTimezones();
  const getTimezoneById = useTimezone();
  const initialValues = useMemo(
    () => ({
      enabled: settings.eventNotificationMethod.indexOf('email' as NotificationMethod) >= 0,
      contactEmail: settings.email,
      notifyPrimaryEmail: settings.notifyPrimaryEmail || !settings.notifyContactEmail,
      alternativeEmail: settings.contactEmail || '',
      notifyAlternativeEmail: settings.notifyContactEmail,
      timezone: getTimezoneById(settings.timezone).id,
      verificationCode: '',
      notificationForAlerts:
        settings.eventNotificationSeverity.indexOf('fault' as NotificationSeverity) >= 0,
      notificationForEvents:
        settings.eventNotificationSeverity.indexOf('informational' as NotificationSeverity) >= 0 &&
        settings.eventNotificationSeverity.indexOf('warning' as NotificationSeverity) >= 0,
    }),
    [settings, getTimezoneById]
  );
  // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
  const formErrors = React.useRef({} as FormikErrors<typeof initialValues>);

  return (
    <Form
      initialValues={initialValues}
      validationSchema={schema}
      isInitialValid={schema.isValidSync(initialValues)}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ handleSubmit, setFieldValue, values, errors, resetForm }) => {
        formErrors.current = errors;
        const isEmailValidToVerify =
          !formErrors.current.alternativeEmail &&
          values.alternativeEmail !== initialValues.alternativeEmail &&
          values.alternativeEmail !== ''
            ? true
            : false;
        if (!didUserAttemptToSubmit) {
          errors = {};
        }
        return (
          <div className={classes.pageContainer}>
            <div className={classes.contentContainer}>
              <div className={classes.maxWidthContainer}>
                <Grid container className={cc.formGridContainer}>
                  <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                    <T variant='h1'>{t('notificationsForm.title')}</T>
                  </Grid>
                </Grid>

                <form>
                  <Grid container className={cc.formGridContainer}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                      <Switch
                        checked={values.enabled}
                        onChange={() => setFieldValue('enabled', !values.enabled)}
                        color='primary'
                      />
                    </Grid>
                    <Grid item xs={8} sm={10} md={5} className={cc.formPostInputGridItem}>
                      <T variant='h3'>{t('notificationsForm.switch')}</T>
                    </Grid>
                  </Grid>

                  <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                      <InputLabel
                        error={Boolean(errors.contactEmail)}
                        className='responsive'
                        required
                      >
                        {t('notificationsForm.email')}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                      <Input
                        disabled
                        id='contactEmail'
                        onChange={(e) => setFieldValue('contactEmail', e.target.value)}
                        value={values.contactEmail}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className={cc.formPostInputGridItem}>
                      <Checkbox
                        disabled={!values.enabled || !values.notifyAlternativeEmail}
                        checked={values.notifyPrimaryEmail}
                        onChange={() =>
                          setFieldValue('notifyPrimaryEmail', !values.notifyPrimaryEmail)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                      <InputLabel error={Boolean(errors.alternativeEmail)} className='responsive'>
                        {t('notificationsForm.alternativeEmail')}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                      <Input
                        id='alternativeEmail'
                        disabled={!values.enabled}
                        onChange={(e) => {
                          setFieldValue('alternativeEmail', e.target.value);
                        }}
                        value={values.alternativeEmail}
                      />
                      {formErrors.current.alternativeEmail && (
                        <FormHelperText id='helper-text' className={cc.formHelper}>
                          {formErrors.current.alternativeEmail}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className={cc.formPostInputGridItem}>
                      <Checkbox
                        name='notifyAlternativeEmail'
                        checked={values.notifyAlternativeEmail}
                        disabled={!values.enabled || !values.notifyPrimaryEmail}
                        onChange={() =>
                          setFieldValue('notifyAlternativeEmail', !values.notifyAlternativeEmail)
                        }
                      />
                      {isEmailValidToVerify && (
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          onClick={() => {
                            if (formErrors.current.alternativeEmail) return;
                            sendVerificationCode({
                              email: values.alternativeEmail,
                            });
                          }}
                          disabled={!values.enabled}
                        >
                          {t('notificationsForm.verify')}
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {isVerifyingEmail && (
                    <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                      <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                        <InputLabel error={Boolean(errors.verificationCode)} className='responsive'>
                          {t('notificationsForm.verifyCode')}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                        <Input
                          id='verificationCode'
                          disabled={!values.enabled}
                          onChange={(e) => setFieldValue('verificationCode', e.target.value)}
                        />
                        {errors.verificationCode && (
                          <FormHelperText id='helper-text' className={cc.formHelper}>
                            {errors.verificationCode}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem}>
                      <InputLabel error={Boolean(errors.timezone)} className='responsive' required>
                        {t('notificationsForm.timezone')}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                      <Select
                        value={values.timezone}
                        id='timezone'
                        disabled={!values.enabled}
                        onChange={(e) => setFieldValue('timezone', e.target.value)}
                      >
                        {timezones.map((tz) => (
                          <MenuItem value={tz.value} key={tz.value}>
                            {tz.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.timezone && (
                        <FormHelperText id='helper-text' className={cc.formHelper}>
                          {errors.timezone}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem} />
                    <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                      <T variant='h5' className={`${classes.h5}`}>
                        {t('notificationsForm.sendLabel')}
                      </T>
                      {errors.notificationForAlerts && (
                        <FormHelperText
                          id='helper-text'
                          className={`${cc.formHelper} ${cc.formHelperRelative}`}
                        >
                          {errors.notificationForAlerts}
                        </FormHelperText>
                      )}
                      <FormControlLabel
                        control={<Checkbox value='Alerts' checked={values.notificationForAlerts} />}
                        label={t('notificationsForm.alerts')}
                        disabled={!values.enabled}
                        name='notificationForAlerts'
                        onChange={() =>
                          setFieldValue('notificationForAlerts', !values.notificationForAlerts)
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox value='Events' checked={values.notificationForEvents} />}
                        label={t('notificationsForm.events')}
                        disabled={!values.enabled}
                        name='notificationForEvents'
                        onChange={() =>
                          setFieldValue('notificationForEvents', !values.notificationForEvents)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container className={`${cc.formGridContainer} ${cc.formSection}`}>
                    <Grid item xs={12} sm={12} md={3} className={cc.formLabelGridItem} />
                    <Grid item xs={12} sm={8} md={5} className={cc.formInputGridItem}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          handleSubmit();
                          setDidUserAttemptToSubmit(true);
                        }}
                      >
                        {t('common.save')}
                      </Button>
                      <Button
                        className={cc.formButtonleftSpaced}
                        variant='contained'
                        color='default'
                        onClick={() => resetForm()}
                      >
                        {t('common.cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Form>
  );
}
