import * as React from 'react';

import Header from './-components/header';
import GeneratorsTable from './-components/generators-table';
import { ValidateData } from '~/data/generators';

import ResponsiveContainer from '~/ui/components/responsive-container';
import { tableStyle } from './styles';

export default function GeneratorsListRoute() {
  return (
    <ResponsiveContainer xs={12} lg={8}>
      <Header />

      <ValidateData>
        <GeneratorsTable style={tableStyle} />
      </ValidateData>
    </ResponsiveContainer>
  );
}
