import { makeStyles } from '@material-ui/styles';
import {
  white,
  grayLight,
  grayLight1,
  grayMed2,
  teal,
} from 'da-frontend-shared-ui-components/lib/styles/colors';

import { spacing } from '~/ui/styles/defaults';

export const useContainerStyles = makeStyles({
  root: {
    display: 'block',
  },
});

export const useButtonStyles = makeStyles({
  root: {
    background: white,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: grayLight1,
    fontSize: '1rem',
    padding: spacing * 2.3,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 0,

    '&:last-child': {
      borderBottomLeftRadius: spacing,
      borderBottomRightRadius: spacing,
      borderBottom: '2px solid',
      borderBottomColor: grayLight1,
    },
    '&:first-child': {
      borderTopLeftRadius: spacing,
      borderTopRightRadius: spacing,
    },
    '&:hover': {
      backgroundColor: grayLight,
      cursor: 'pointer',

      '& svg': {
        color: teal,
      },
    },
  },
  settings: {
    color: grayMed2,
  },
});
