import {
  AccountsApi,
  AlertsApi,
  AssetsApi,
  ConfigApi,
  ConnectCloudDealersApi as DealerApi,
  ConnectCloudEquipmentApi as EquipmentApi,
  EventsApi,
  LoggerApi,
  ConnectCloudNotificationsApi as NotificationsApi,
  ProfileApi,
  SitesApi,
  ConnectCloudUsersApi as UsersApi,
} from 'da-pcc-frontend-shared-domain/lib';
import React, { useContext, useMemo } from 'react';

import config from '~/config';
import { configure } from './api-setup';
import { Errors } from '~/ui/components/errors/errors';

interface IAPIContext {
  accounts: AccountsApi;
  assets: AssetsApi;
  config: ConfigApi;
  equipments: EquipmentApi;
  events: EventsApi;
  sites?: SitesApi;
  dealers: DealerApi;
  profile: ProfileApi;
  users: UsersApi;
  notifications: NotificationsApi;
  session: ProfileApi;
  logger: LoggerApi;
  alerts: AlertsApi;
}

const APIContext = React.createContext<IAPIContext | undefined>(undefined);

export function APIProvider({ children }) {
  const options = {
    onForbidden() {
      console.warn('Received 403 from API, redirecting to root');
      document.location.href = config.paths.root;
    },
    onUnauthorized() {
      console.warn('Received 401 from API, redirecting to login');
      window.location.assign(
        `${config.paths.logout}?redirectUri=${encodeURI(
          window.location.pathname + window.location.search
        )}`
      );
    },
    onHttp409() {
      console.warn('Received 409 from API, display missing access error page');
      document.location.href = config.paths.noAccessError + '/409';
    },
    onHttp412() {
      console.warn('Received 412 from API, display missing access error page');
      document.location.href = config.paths.noAccessError + '/412';
    },
    onHttp418: () => {
      console.warn('Session API received 418 from API, redirecting to Salesforce request access endpoint');
      document.location.href = config.paths.noAccessError + '/418';
    },
  };

  const apis = useMemo(() => {
    return {
      accounts: configure(AccountsApi, options),
      assets: configure(AssetsApi, options),
      config: configure(ConfigApi, options),
      equipments: configure(EquipmentApi, options),
      events: configure(EventsApi, options),
      sites: configure(SitesApi, options),
      dealers: configure(DealerApi, options),
      profile: configure(ProfileApi, options),
      users: configure(UsersApi, options),
      notifications: configure(NotificationsApi, options),
      session: configure(ProfileApi, {
        onForbidden: () => {
          /*noop*/
          // Disable 401 middleware since these api will just be used to confirm if we have a token
          console.log('forbidden');
        },
        onUnauthorized: () => {
          /*noop*/
          // Disable 401 middleware since these api will just be used to confirm if we have a token
          console.log('unauthorized');
        },
        onHttp409() {
          console.warn('Received 409 from API, display missing access error page');
          document.location.href = config.paths.noAccessError + '/409';
        },
        onHttp412() {
          console.warn('Received 412 from API, display missing access error page');
          document.location.href = config.paths.noAccessError + '/412';
        },
        onHttp418: () => {
          console.warn('Session API received 418 from API, redirecting to Salesforce request access endpoint');
          document.location.href = config.paths.noAccessError + '/418';
        },
      }),
      logger: configure(LoggerApi, options),
      alerts: configure(AlertsApi, {
        onForbidden: () => {
          /*noop*/
          // Disabled 401 middleware as alerts are publicly available
        },
        onUnauthorized: () => {
          /*noop*/
          // Disable 401 middleware as alerts are publicly available
        },
        onHttp409() {
          /*noop*/
          // Disable 409 middleware as alerts are publicly available
        },
        onHttp412() {
          /*noop*/
          // Disable 412 middleware as alerts are publicly available
        },
        onHttp418: () => {
          /*noop*/
          // Disable 401 middleware since these api will just be used to confirm if we have a token
        }
      }),
    };
  }, [options]);

  return <APIContext.Provider value={apis}>{children}</APIContext.Provider>;
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error('useAPI must be used within a APIProvider');
  }
  return context;
}
