import React, { createContext, useContext, useEffect, useReducer, useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { useAPI } from '../..';
import { fetchPermissions } from './actions';
import reducer, { initialState } from './reducer';
import { PermissionState, PermissionDispatch } from './types';
import { useCurrentGenerator } from '../context';
import { AccountPermission } from 'da-pcc-frontend-shared-domain';
import { useConsumerAccount } from '~/data/consumerAccount';

const PermissionStateContext = createContext<PermissionState | undefined>(undefined);
const PermissionDispatchContext = createContext<PermissionDispatch | undefined>(undefined);

export function PermissionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { accounts } = useAPI();
  const [{ generator }] = useCurrentGenerator();

  const [consumerAccount] = useConsumerAccount();
  const consumerAccountPermissions = useMemo(
    () => (consumerAccount ? consumerAccount.permissions : []),
    [consumerAccount]
  );

  const [, getPermissions] = useAsyncFn(async () => {
    const { accountId } = generator;
    dispatch(fetchPermissions.started());
    await accounts
      .accountControllerGetAccount({ accountId })
      .then(({ permissions }) => dispatch(fetchPermissions.done({ result: permissions })))
      .catch((error) => dispatch(fetchPermissions.failed({ error })));
  }, [accounts, generator]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const permissionSet = useMemo(() => {
    return new Set([...consumerAccountPermissions, ...Array.from(state.permissions)]);
  }, [consumerAccountPermissions, state.permissions]);

  return (
    <PermissionStateContext.Provider value={{ loading: state.loading, permissions: permissionSet }}>
      <PermissionDispatchContext.Provider value={{}}>{children}</PermissionDispatchContext.Provider>
    </PermissionStateContext.Provider>
  );
}

export function usePermissionState() {
  const context = useContext(PermissionStateContext);
  if (context === undefined) {
    throw new Error('usePermission must be used within a PermissionProvider');
  }
  return context;
}

export function usePermissionDispatch() {
  const context = useContext(PermissionDispatchContext);
  if (context === undefined) {
    throw new Error('usePermissionDispatch must be used within a PermissionProvider');
  }
  return context;
}

export function usePermission(): [PermissionState, PermissionDispatch] {
  return [usePermissionState(), usePermissionDispatch()];
}

export function useRequiredPermission(requiredPermission: AccountPermission): boolean {
  const { permissions } = usePermissionState();
  return useMemo(() => permissions.has(requiredPermission), [permissions, requiredPermission]);
}
