import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchInvitationCode } from './actions';
import { RegistrationState } from './types';

export const initialState: RegistrationState = {
  invitation: undefined,
  error: undefined,
  loading: true,
};

const userInvitationCodeReducer = reducerWithInitialState(initialState)
  .case(fetchInvitationCode.done, (state, { result: invitation }) => ({
    ...state,
    invitation,
    loading: false,
  }))
  .case(fetchInvitationCode.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default userInvitationCodeReducer;
