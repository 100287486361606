import { makeStyles } from '@material-ui/styles';
import { grayLight } from 'da-frontend-shared-ui-components/lib/styles/colors';
import { defaultTheme } from 'da-frontend-shared-ui-components';

const spacing = defaultTheme.spacing.unit;

export const useClasses = makeStyles({
  header: {
    marginTop: spacing * 3,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  key: {
    textAlign: 'left',
  },
  value: {
    textAlign: 'right',
  },
  cell: {
    padding: '15px 20px',
  },
  row: {
    borderBottom: `1px solid ${grayLight}`,
  },
});
