import {
  LanguageType,
  NotificationSeverity,
  ProfileDisplayUnit,
} from 'da-pcc-frontend-shared-domain/lib';

export enum ActivationStatus {
  enable = 'enable',
  disable = 'disable',
}

export interface UpdateProfileForm {
  firstName: string;
  lastName: string;
  contactPhone: string;
  preferredAccountId: string;
  language: LanguageType;
  displayUnit: ProfileDisplayUnit;
  emailNotifications: ActivationStatus;
  notifyPrimaryEmail: boolean;
  contactEmail: string;
  notifyContactEmail: boolean;
  timezone: string;
  accountSubscriptions: string[];
  eventNotificationSeverity: NotificationSeverity[];
  notifyDeviceReminders: ActivationStatus;
  wwid: string;
  email: string;
  notificationEmail?: string;
}

export interface AppAccount {
  name: string;
  id: string;
}

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface CheckboxOption {
  label: string;
  name: string;
  id?: string;
  checked?: boolean;
  value?: any;
}

export interface RadioOption<T = any> extends CheckboxOption {
  value: T;
}

export type GridNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface SizeProps {
  xs?: GridNumbers;
  sm?: GridNumbers;
  md?: GridNumbers;
  lg?: GridNumbers;
  xl?: GridNumbers;
}

export interface FieldLayoutProps {
  xsinput?: GridNumbers;
  sminput?: GridNumbers;
  mdinput?: GridNumbers;
  lginput?: GridNumbers;
  xlinput?: GridNumbers;
  xslabel?: GridNumbers;
  smlabel?: GridNumbers;
  mdlabel?: GridNumbers;
  lglabel?: GridNumbers;
  xllabel?: GridNumbers;
}

export const defaultLabelSizeProps: SizeProps = {
  xs: 12,
  sm: 3,
  md: 2,
};

export const defaultInputSizeProps: SizeProps = {
  xs: 12,
  sm: 5,
  md: 4,
  lg: 3,
  xl: 2,
};
