import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchGenerators, updateDeviceStatus } from './actions';
import { GeneratorsState } from './types';

export const initialState: GeneratorsState = {
  loading: true,
  generators: [],
  error: undefined,
};

const generatorsReducer = reducerWithInitialState(initialState)
  .case(fetchGenerators.done, (state, { result: generators }) => ({
    ...state,
    generators,
    loading: false,
  }))
  .case(fetchGenerators.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
  .case(updateDeviceStatus, (state, status) => {
    const { generators } = state;
    return {
      ...state,
      generators: generators.map((generator) => {
        if (generator.id !== status.equipmentId) {
          return generator;
        }

        return {
          ...generator,
          status,
        };
      }),
    };
  });

export default generatorsReducer;
