import { makeStyles } from '@material-ui/styles';
import { defaultTheme } from 'da-frontend-shared-ui-components';

const grey = defaultTheme.palette.grey[500];

export default makeStyles({
  headlineContainer: {
    backgroundColor: 'rgba(255,255,255,0.275)',
    padding: '.625rem 0',
  },
  whiteText: {
    whiteSpace: 'pre-line',
    lineHeight: 'initial',
    fontWeight: 'bold',
    margin: '.3125rem 0',
    color: defaultTheme.palette.common.white,
  },
  darkendBackground: {
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
  test: {
    backgroundColor: defaultTheme.palette.common.black,
  },
  note: {
    textShadow: '-2px -2px 4px #000000',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    lineHeight: 'initial',
    fontWeight: 'bold',
    margin: '2rem 0',
    color: defaultTheme.palette.common.white,
    ['& a']: {
      color: defaultTheme.palette.common.white,
      textDecoration: 'underline',
    },
    ['& a:visited']: {
      color: defaultTheme.palette.common.white,
    },
  },
  btnRed: {
    backgroundColor: defaultTheme.palette.error.dark,
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: grey,
    },
  },
  btnGrey: {
    backgroundColor: defaultTheme.palette.grey[700],
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: grey,
    },
  },
  noUnderline: {
    textDecoration: 'none',
  },
  marginRight: {
    marginRight: '.75rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
});
