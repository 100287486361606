import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography as T } from '@material-ui/core';

import { paths } from '~paths';

import DealerLinks from '~/ui/components/resources/dealers/links';
import DealerInfo from '~/ui/components/resources/dealers/contact';

import { useStyles } from '../styles';
import { ccglobalStyles } from '~/ui/styles/defaults';
import ResponsiveContainer from '~/ui/components/responsive-container';

import { useRouter } from '~/utils/hooks';

import { DealersProvider, useDealers } from '~/data/dealers';
import { useDealer } from '~/data/dealer';

import { formatAddress } from '~/utils/address';

export default function List() {
  const { t } = useTranslation();
  const cc = ccglobalStyles({});

  return (
    <ResponsiveContainer xs={12} lg={8}>
      <div className={cc.titleSpacing}>
        <T variant='h1'>{t('dealers.list.title')}</T>
      </div>
      <DealersProvider>
        <Dealers />
      </DealersProvider>
    </ResponsiveContainer>
  );
}

function Dealers() {
  const { t } = useTranslation();
  const [
    { dealers },
    {
      requestDealer: [, requestDealer],
    },
  ] = useDealers();
  const [
    ,
    {
      getDealer: [, getDealer],
    },
  ] = useDealer();
  const classes = useStyles({});
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const selectDealer = useCallback(
    async (id: string, firstName: string) => {
      await requestDealer(id);
      await getDealer();
      enqueueSnackbar(
        t('dealers.list.selected', {
          firstName,
        })
      );

      history.push(paths.dashboard.generators.path);
    },
    [enqueueSnackbar, getDealer, history, requestDealer, t]
  );

  const validateContactInfo = (dealer, field) => {
    const errorMessage = t('dealers.list.noField', { field });

    if (dealer.contactInformation) {
      return dealer.contactInformation[field] || errorMessage;
    }

    return errorMessage;
  };

  return (
    <>
      {dealers.map((dealer) => {
        return (
          <Grid key={dealer.id} container spacing={16} className={classes.dealerContainer}>
            <Grid item xs={12} md={6}>
              <DealerInfo name={dealer.name} address={formatAddress(dealer.address)} />
            </Grid>

            <Grid item xs={12} md={4}>
              <DealerLinks
                email={validateContactInfo(dealer, 'email')}
                phone={validateContactInfo(dealer, 'phone')}
              />
            </Grid>

            <Grid item xs={12} md={2} className={classes.dealerAction}>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => selectDealer(dealer.id, dealer.name)}
              >
                {t('dealers.list.selectBtn')}
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
