import { makeStyles } from '@material-ui/styles';

import { defaultSeparation } from '~/ui/styles/defaults';

import { defaultTheme } from 'da-frontend-shared-ui-components';
import { grayLight } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'start',
    padding: '2rem 7rem 0 7rem',

    [defaultTheme.breakpoints.down('xs')]: {
      padding: '2rem 2rem 0 2rem',
    },
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [defaultTheme.breakpoints.down('sm')]: {
      flexFlow: 'wrap',
    },
  },
  formFields: {
    display: 'flex',
  },
  serialContainer: {
    padding: defaultSeparation,
    backgroundColor: grayLight,
  },
  formButton: {
    marginRight: '20px',
  },
  hint: {
    marginTop: defaultSeparation * 2,
  },
  retryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  retryButtonIcon: {
    marginRight: '5px',
    width: 15,
    height: 15,
  },
  retryButtonText: {
    lineHeight: 1,
  },
});
