import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';

interface IProps {
  children?: any;
  style?: CSSProperties;
}

export default function ResponsiveContainer({
  children,
  style = {},
  ...props
}: IProps & GridProps) {
  return (
    <Grid container justify='center'>
      <Grid item xs={12} style={{ padding: '1.25rem 1.875rem 3.75rem', ...style }} {...props}>
        {children}
      </Grid>
    </Grid>
  );
}
