import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Sockette from 'sockette';

import { useAPI } from '~/data';

import { useCurrentGenerator } from '../context';

import {
  PingMessage,
  ConnectionControlMessage,
  isPingMessage,
  isConnectionInfoMessage,
  RealtimeConnection,
} from './types';

const useWebsocket = ({ onMessage }: { onMessage: (message: any) => void }) => {
  const [{ generator }] = useCurrentGenerator();

  const websocket = useRef<Sockette>();
  const heartbeat = useRef<NodeJS.Timer>();

  const [connection, setConnection] = useState<RealtimeConnection | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const isConnected = useMemo(() => Boolean(connection), [connection]);
  const { config } = useAPI();

  const createWebsocket = useCallback(async () => {
    const { websocketURL } = await config.getConfig();
    const socket = new Sockette(websocketURL, {
      timeout: 5e3,
      onopen: (e: Event) => {
        socket.json({ action: '/getConnectionInfo' });
      },
      onmessage: (event: Event) => {
        const message = JSON.parse((event as any).data) as PingMessage | ConnectionControlMessage;
        if (isPingMessage(message)) {
          // do nothing
        } else if (isConnectionInfoMessage(message)) {
          setConnection(message.payload);
        } else {
          onMessage(message);
        }
      },
      onreconnect: (e) => console.warn('Reconnecting websocket...'),
      onmaximum: (e) =>
        setError(new Error('Failed to establish realtime connection with generator.')),
      onclose: (e) => console.log('onclose', e),
      onerror: (e) => console.log('onerror', e),
    });
    return socket;
  }, [config, onMessage]);

  useEffect(() => {
    createWebsocket().then((socket) => (websocket.current = socket));

    return () => {
      if (websocket.current) {
        websocket.current.close();
        websocket.current = undefined;
        setConnection(undefined);
      }
    };
  }, [createWebsocket, setConnection, generator.id]);

  useEffect(() => {
    heartbeat.current = setInterval(
      () => {
        if (websocket.current) {
          websocket.current.json({ action: '/ping' });
        }
      },
      5e3 // config.api.websocketPingIntervalMillis,
    );
    return () => {
      if (heartbeat.current) {
        clearInterval(heartbeat.current);
        heartbeat.current = undefined;
      }
    };
  }, []);

  return { connection, error, isConnected };
};

export { useWebsocket };
