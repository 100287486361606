import { CSSProperties } from 'react';
import { defaultTheme, colors } from 'da-frontend-shared-ui-components';

export const dayStyle: CSSProperties = {
  paddingTop: defaultTheme.spacing.unit * 2,
  paddingBottom: defaultTheme.spacing.unit,
  marginBottom: defaultTheme.spacing.unit,
};

export const timeStyle: CSSProperties = {
  paddingBottom: defaultTheme.spacing.unit,
};

export const eventStyle: CSSProperties = {
  marginLeft: defaultTheme.spacing.unit * 2,
  padding: defaultTheme.spacing.unit * 1.5,
  borderBottom: `1px solid ${colors.grayLight}`,
};

export const fontStyle: CSSProperties = {
  fontSize: '16px',
};
