import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import T from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { AccountPermission, OperationalStatus } from 'da-pcc-frontend-shared-domain';
import { SummaryText, TitleText } from '../-components/generator-management';
import RoutableAccordion from '~/ui/components/routable-accordion';
import { useCurrentGenerator } from '~/data/generator';
import { switchStyle, onStyle, useStyles } from './styles';
import StartNow from './-components/start-now';
import { paths } from '~paths';
import { useCommands } from '~/data/generator/commands';
import { useRequiredPermission } from '~/data/generator/permissions';
import { recordGeneratorEvent } from '~/utils/gtm';

export default function Control() {
  const [t] = useTranslation();
  const classes = useStyles();
  const [{ generator }] = useCurrentGenerator();
  const hasPermissionToSendCommands = useRequiredPermission(
    AccountPermission.CanSendDeviceCommands
  );
  const [
    ,
    {
      changeStandby: [changeStandbyState, changeStandby],
    },
  ] = useCommands();
  const {
    id,
    status: { isStandbyEnabled, isRemoteEnabled, isRunning, status: generatorStatus },
  } = generator;
  const controlPath = paths.dashboard.generators.show.control.with({ id });

  const isOffline = generatorStatus === OperationalStatus.Offline;
  const standByMode = isStandbyEnabled ? t('control.states.on') : t('control.states.off');
  const standByIntent = isStandbyEnabled ? t('control.intents.will') : t('control.intents.willNot');
  const remoteStart = isRemoteEnabled ? t('control.states.on') : t('control.states.off');
  const remoteStartIntent = isRemoteEnabled
    ? t('control.intents.can')
    : t('control.intents.canNot');
  const isRunningManual = !isStandbyEnabled && isRunning;
  const status = isStandbyEnabled ? t('control.standbyOn') : t('control.standbyOff');
  const isDisabled =
    !hasPermissionToSendCommands ||
    !(!isRunningManual && isRemoteEnabled) ||
    isOffline ||
    changeStandbyState.loading;
  const isStandByChecked = changeStandbyState.loading ? !isStandbyEnabled : isStandbyEnabled;

  const toggleStandbyStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changeStandby({
        on: event.currentTarget.checked,
      });

      event.currentTarget.checked ? recordGeneratorEvent("Standby On") 
        : recordGeneratorEvent("Standby Off");
    },
    [changeStandby]
  );

  return (
    <RoutableAccordion
      {...{
        path: controlPath,
        exitPath: paths.dashboard.generators.show.with({ id }),
        headerContent: <TitleText>{t('generator.control')}</TitleText>,
        headerActions: [<SummaryText key='x'>{status}</SummaryText>],
      }}
    >
      <div className={classes.container}>
        <div className={classes.control}>
          <div>
            <T variant='h3'>{t('control.standbyStatus')}</T>
            <T variant='body1'>
              <Trans
                i18nKey='control.standbyDescription'
                values={{ onOff: standByMode, intent: standByIntent }}
              >
                Standby mode is currently <strong>{standByMode}</strong>
                <br />
                Generator <strong>{standByIntent}</strong> start automatically in the event of a
                power outage.
              </Trans>
            </T>
          </div>
          <div>
            <Switch
              style={switchStyle}
              checked={isStandByChecked}
              disabled={isDisabled}
              onChange={toggleStandbyStatus}
            />
          </div>
        </div>

        <div className={classes.control}>
          <div>
            <T variant='h3'>{t('control.remoteStart')}</T>
            <T variant='body1'>
              <Trans
                i18nKey='control.remoteStartDescription'
                values={{ onOff: remoteStart, intent: remoteStartIntent }}
              >
                Remote Start mode is currently <strong>{remoteStart}</strong>
                <br />
                Generator <strong>{remoteStartIntent}</strong> receive a remote start command.
                <br />
                To disable remote start, use the display located on your generator.
              </Trans>
            </T>
          </div>
          <div>
            <T style={onStyle}>{remoteStart}</T>
          </div>
        </div>

        <div className={classes.primaryAction}>
          <StartNow />
        </div>
      </div>
    </RoutableAccordion>
  );
}
