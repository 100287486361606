import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography as T, Button } from '@material-ui/core';
import Cached from '@material-ui/icons/Cached';
import { Form, Input } from 'da-frontend-shared-ui-components';
import { WarningError } from 'da-frontend-shared-ui-components/lib/icons';
import { ErrorMessage } from 'da-frontend-shared-ui-components/lib/Errors';
import SelectField from '~/ui/components/custom-fields/SelectField';
import { useAddSchema, useGeneratorsList } from '~/data/generators';
import { paths } from '~paths';
import { useSnackbar } from 'notistack';
import StatusMessage from './-components/status-message';
import { useStyles } from './styles';
import { useAPI } from '~/data';
import { AsyncWaiter } from '~/ui/components/async-waiter';
import { useCountries, useStateOrProvidence } from '~/data/regions';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '~/config';
import { recordPreferenceEvent } from '~/utils/gtm';

export default function NewRoute({ history }) {
  const { t } = useTranslation();
  const schema = useAddSchema(t);
  const { enqueueSnackbar } = useSnackbar();
  // TODO: Move to Context Dispatch
  const { accounts } = useAPI();
  const [countryId, setCountryId] = useState(null);
  const listOfCountries = useCountries();
  const listOfProvinces = useStateOrProvidence(countryId);
  const [
    ,
    {
      create: [createState, create],
      findBySerialNumberAndAccessCode: [
        findBySerialNumberAndAccessCodeState,
        findBySerialNumberAndAccessCode,
      ],
    },
  ] = useGeneratorsList();
  const generatorId = useMemo<string | undefined>(() => {
    return findBySerialNumberAndAccessCodeState.value;
  }, [findBySerialNumberAndAccessCodeState]);
  const isAttemptingFindBySerialNumberAndAccessCode = useMemo(
    () => findBySerialNumberAndAccessCodeState.loading,
    [findBySerialNumberAndAccessCodeState]
  );
  const hasAttemptedFindBySerialNumberAndAccessCode = useMemo(() => {
    return Boolean(
      findBySerialNumberAndAccessCodeState.value || findBySerialNumberAndAccessCodeState.error
    );
  }, [findBySerialNumberAndAccessCodeState]);
  const shouldAttemptFindBySerialNumberAndAccessCode = useCallback(
    (values, errors) => {
      const fieldsHaveValues = values.serialNumber && values.accessCode;
      const fieldHaveError = errors.serialNumber || errors.accessCode;
      const fieldsAreValid = fieldsHaveValues && !fieldHaveError;
      return (
        fieldsAreValid &&
        !(
          isAttemptingFindBySerialNumberAndAccessCode || hasAttemptedFindBySerialNumberAndAccessCode
        )
      );
    },
    [isAttemptingFindBySerialNumberAndAccessCode, hasAttemptedFindBySerialNumberAndAccessCode]
  );
  const classes = useStyles({});

  const fn = useCallback(async () => {
    const { items } = await accounts.accountControllerGetAccounts();
    const account = items[0];
    if (account && account.sites.length) {
      const location = account.sites[0].location;
      setCountryId(location.country);
      return {
        name: '',
        phone: '',
        address: location.line1,
        address2: location.line2,
        city: location.city,
        country: location.country,
        state: location.region,
        zip: location.postalCode,
        serialNumber: '',
        accessCode: '',
      };
    }
  }, [accounts]);

  const onLocateGenerator = useCallback(
    (values) => {
      findBySerialNumberAndAccessCode({
        serialNumber: (values.serialNumber) ? (values.serialNumber).toUpperCase() : "",
        customerAccessCode: (values.accessCode) ? (values.accessCode).toUpperCase() : ""
      });
    },
    [findBySerialNumberAndAccessCode]
  );

  const onSubmit = useCallback(
    async (values) => {
      if (generatorId) {
        await create({
          connectCloudSetupModel: {
            name: values.name,
            phone: values.phone,
            deviceId: generatorId,
            location: {
              line1: values.address,
              line2: values.address2,
              city: values.city,
              region: values.state,
              postalCode: values.zip,
              country: values.country,
            },
          },
        });

        enqueueSnackbar(t('generator.add.successAdd', { name: values.name }));

        recordPreferenceEvent("Add New Generator", `Add New Generator ID: ${generatorId}`);

        history.push(paths.preferences.dealers.path);
      } else {
        await onLocateGenerator(values);
      }
    },
    [create, enqueueSnackbar, onLocateGenerator, generatorId, history, t]
  );

  // eslint-disable-next-line no-restricted-globals
  const [continueDisabled, setContinueDisabled] = React.useState(location.hostname !== 'localhost');

  const reCaptchaOnChange = React.useCallback((value: any) => {
    if (value) {
      setContinueDisabled(false);
    }
  }, []);

  const sizing: any = {
    labelSize: 4,
    fieldSize: 8,
  };

  const generatorErrorMessage = (message) => {
    if (message == "Serial No mismatch") {
      return t('generator.add.error3');
    } else if (message == "Device already exists") {
      return t('generator.add.error4');
    } else if (message == "Access Code mismatch") {
      return t('generator.add.error5');
    } else {
      return t('generator.add.error1');
    }
  }
  return (
    <AsyncWaiter fn={fn}>
      {({ value }) => (
        <div className={classes.container}>
          <T variant='h1' style={{ marginBottom: '32px' }}>
            {t('generator.add.title')}
          </T>

          <Form initialValues={value} validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, values, errors, isValid }) => {
              if (shouldAttemptFindBySerialNumberAndAccessCode(values, errors)) {
                onLocateGenerator(values);
              }
              return (
                <>
                  <Grid container spacing={16} style={{ alignSelf: 'center' }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Input
                        {...sizing}
                        required
                        name='name'
                        label={t('generator.attributes.fullName')}
                      />
                      <Input
                        {...sizing}
                        required
                        name='phone'
                        label={t('generator.attributes.phone')}
                      />
                      <Input
                        {...sizing}
                        required
                        name='address'
                        label={t('generator.attributes.address')}
                      />
                      <Input
                        {...sizing}
                        name='address2'
                        label={t('generator.attributes.address2')}
                      />
                      <Input
                        {...sizing}
                        required
                        name='city'
                        label={t('generator.attributes.city')}
                      />
                      <SelectField
                        xsinput={sizing.fieldSize}
                        sminput={sizing.fieldSize}
                        mdinput={sizing.fieldSize}
                        lginput={sizing.fieldSize}
                        xlinput={sizing.fieldSize}
                        xslabel={sizing.labelSize}
                        smlabel={sizing.labelSize}
                        mdlabel={sizing.labelSize}
                        lglabel={sizing.labelSize}
                        xllabel={sizing.labelSize}
                        canSelectNone={false}
                        name='country'
                        label={t('generator.attributes.country')}
                        options={listOfCountries}
                        value={values.country || ''}
                        onChange={({ target: { value } }) => {
                          setFieldValue('country', value);
                          setCountryId(value);
                          setFieldValue('state', '');
                        }}
                        isRequired
                      />
                      {listOfProvinces.length > 0 ? (
                        <SelectField
                          xsinput={sizing.fieldSize}
                          sminput={sizing.fieldSize}
                          mdinput={sizing.fieldSize}
                          lginput={sizing.fieldSize}
                          xlinput={sizing.fieldSize}
                          xslabel={sizing.labelSize}
                          smlabel={sizing.labelSize}
                          mdlabel={sizing.labelSize}
                          lglabel={sizing.labelSize}
                          xllabel={sizing.labelSize}
                          canSelectNone={false}
                          name='state'
                          label={t('generator.attributes.state')}
                          options={listOfProvinces}
                          value={values.state || ''}
                          onChange={({ target: { value } }) => setFieldValue('state', value)}
                          isRequired
                        />
                      ) : (
                        <Input
                          {...sizing}
                          required
                          name='state'
                          label={t('generator.attributes.state')}
                        />
                      )}
                      <Input
                        {...sizing}
                        required
                        name='zip'
                        label={t('generator.attributes.zip')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.serialContainer}>
                        <Input
                          {...sizing}
                          required
                          name='serialNumber'
                          label={t('generator.attributes.serialNumber')}
                        />
                        <Input
                          {...sizing}
                          required
                          name='accessCode'
                          label={t('generator.attributes.accessCode')}
                        />

                        <div>
                          {findBySerialNumberAndAccessCodeState.value && (
                            findBySerialNumberAndAccessCodeState.value.message ?
                              <>
                                <ErrorMessage
                                  icon={WarningError}
                                  error={() => (
                                    <>
                                      {
                                        generatorErrorMessage(findBySerialNumberAndAccessCodeState.value.message)
                                      }

                                      <Button
                                        color='primary'
                                        className={classes.retryButton}
                                        onClick={() => onLocateGenerator(values)}
                                      >
                                        <Cached className={classes.retryButtonIcon} />
                                        <span className={classes.retryButtonText}>
                                          {t('generator.add.retry')}
                                        </span>
                                      </Button>
                                    </>
                                  )}
                                />
                              </> :
                              (
                                <StatusMessage isSuccess={true} />
                              )
                          )}

                          {findBySerialNumberAndAccessCodeState.loading && (
                            <StatusMessage isSuccess={false} />
                          )}
                        </div>

                        <T className={classes.hint}>{t('generator.add.hint')}</T>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '6rem' }}>
                      <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
                          <ReCAPTCHA sitekey={config.reCaptchaKey} onChange={reCaptchaOnChange} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          &nbsp;
                          {/*
                          Left Empty to space align with Formik
                          Remove When replacing Formik
                        */}
                        </Grid>
                        <Grid item xs={8}>
                          <Button
                            size='small'
                            color='primary'
                            variant='contained'
                            disabled={continueDisabled || !isValid || createState.loading}
                            className={classes.formButton}
                            onClick={(e) => handleSubmit()}
                          >
                            {createState.loading
                              ? t('generator.add.saving')
                              : t('generator.add.continue')}
                          </Button>
                          <Button
                            size='small'
                            color='default'
                            variant='contained'
                            className={classes.formButton}
                            disabled={createState.loading}
                            onClick={() => history.push(paths.preferences.generators.path)}
                          >
                            {t('common.cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Form>
        </div>
      )}
    </AsyncWaiter>
  );
}
