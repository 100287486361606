import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Typography as T, Button } from '@material-ui/core';
import { paths } from '~paths';
import { useRouter } from '~/utils/hooks';
import { useRegistration } from '~/data/registration';
import { useStyles } from './styles';
import config from '~/config';

export default function Landing() {
  const [t] = useTranslation();
  const classes = useStyles({});
  const {
    match: {
      params: { inviteCode },
    },
  } = useRouter();
  const [{ invitation }] = useRegistration();

  return (
    <>
      <Grid className={classes.registrationContainer}>
        <T align='center' component='h2' variant='h1'>
          {t('invitations.registration.welcome', { name: invitation.name })}
        </T>

        <div className={classes.pSpacing}>
          <T>
            {t('invitations.registration.invited', {
              name: invitation.account.name,
            })}
          </T>
        </div>

        <div className={classes.pSpacing}>
          <T>{t('invitations.registration.description')}</T>
        </div>

        <div className={classes.pSpacing}>
          <T>
            <Trans i18nKey='invitations.registration.begin'>
              <strong>Please click the appropriate option below to register</strong>. As of August 2024, Connect Cloud was integrated with Cummins' universal online login system. This allows users to use the same username and password for multiple Cummins applications. There are two options to register:
            </Trans>
          </T>
        </div>
        <div className={classes.optionsList}>
          <T>
            <ul>
              <li>
                <Trans i18nKey='invitations.registration.option1'>
                  <strong>Register NEW User Account</strong> - you do not yet have a Cummins Online Account
                </Trans>
              </li>
              <li>
                <Trans i18nKey='invitations.registration.option2'>
                  <strong>Use your EXISTING Cummins Online Account</strong> - you already have a Cummins Online Account username and password and would like to use that to register for Connect Cloud
                </Trans>
              </li>
            </ul>
          </T>
        </div>

        <Grid item xs={12} className={classes.buttonSpread}>
          <Link to={paths.invite.show.termsConditions.with({ inviteCode })}>
            <Button variant='contained' color='primary'>
              {t('invitations.registration.registerUser')}
            </Button>
          </Link>
          <Button
            variant='contained'
            color='secondary'
            href={`${config.paths.login
              }?redirectUri=${`${paths.registration.confirmation.path}?code=${inviteCode}`}`}
          >
            {t('invitations.registration.currentUser')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
