import * as Yup from 'yup';
import { useMemo } from 'react';

export function useEditSchema(t: TFunction) {
  return useMemo(() => generateSchema(t), [t]);
}

export function useAddSchema(t: TFunction) {
  return useMemo(() => generateSchema(t).concat(generateSchemaAdd(t)), [t]);
}

export function useLookupSchema(t: TFunction) {
  return useMemo(() => generateSchemaAdd(t), [t]);
}

function requiredFieldMessage(t: TFunction, attribute: string) {
  return t('errors.fields.required', { field: t(`generator.attributes.${attribute}`) });
}

function invalidFieldMessage(t: TFunction, attribute: string) {
  return t('errors.fields.invalid', { field: t(`generator.attributes.${attribute}`) });
}

function invalidSpecialFieldMessage(t: TFunction, attribute: string) {
  return t('errors.specialFields.invalid', { field: t(`generator.attributes.${attribute}`) });
}

function generateSchemaAdd(t: TFunction) {
  return Yup.object({
    serialNumber: Yup.string()
      .matches(/^[a-np-zA-NP-Z0-9]{10}$/, invalidSpecialFieldMessage(t, 'serialNumber'))
      .required(requiredFieldMessage(t, 'serialNumber')),
    accessCode: Yup.string()
      .matches(/^[a-np-zA-NP-Z0-9]{5}$/, invalidSpecialFieldMessage(t, 'accessCode'))
      .required(requiredFieldMessage(t, 'accessCode')),
  });
}

function generateSchema(t: TFunction) {
  return Yup.object({
    name: Yup.string().required(requiredFieldMessage(t, 'fullName')),
    phone: Yup.string().required(requiredFieldMessage(t, 'phone')),
    address: Yup.string().required(requiredFieldMessage(t, 'address')),
    address2: Yup.string(),
    city: Yup.string().required(requiredFieldMessage(t, 'city')),
    country: Yup.string().required(requiredFieldMessage(t, 'country')),
    state: Yup.string().required(requiredFieldMessage(t, 'state')),
    zip:Yup.string().when('country',{
      is: (val) => val  ==='US' || val ==='CA' || val ==='MX' || val ==='TT',
      then:Yup.string().matches(/^([0-9]{5}|[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/, invalidFieldMessage(t, 'zip')).required(requiredFieldMessage(t, 'zip')),
      otherwise:Yup.string().
      matches(/^[\w-_.]{4,7}$/, invalidFieldMessage(t, 'zip')).required(requiredFieldMessage(t, 'zip')),
  }),
  });
}
