import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { install } from '@material-ui/styles';

import './ui/styles/app.scss';

import Application from './ui/application';

install();

ReactDOM.render(<Application />, document.getElementById('root'));
