import { useAsyncFn } from 'react-use';
import { useAPI } from '~/data';
import { useCurrentGenerator } from '../context';
import { useRealtime } from '../realtime';
import useCommandId from './useCommandId';

export default function useIncreaseUpdateFrequency() {
  const [{ generator }] = useCurrentGenerator();
  const { assets } = useAPI();
  const [{ connection }] = useRealtime();
  const { id: assetId } = generator;
  const getCommandId = useCommandId();

  return useAsyncFn(async () => {
    if (connection) {
      const { subscriptionId, endpoint } = connection;
      const commandId = getCommandId();
      await assets.increaseUpdateFrequency({
        assetId,
        deviceCommandInput: {
          id: commandId,
          subscriptionId,
          endpoint,
        },
      });
    } else {
      console.warn('websocket connection is unavailable, ignoring increaseUpdateFrequency');
    }
  }, [assetId, connection, getCommandId, assets]);
}
