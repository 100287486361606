import React, { useContext, createContext, useMemo, useCallback } from 'react';
import data from './timezones.json';
import { TimezoneState } from './types';

const TimezonesStateContext = createContext<TimezoneState | undefined>(undefined);

export function TimezonesProvider({ children }) {
  const state = useMemo(() => {
    return data;
  }, []);
  return <TimezonesStateContext.Provider value={state}>{children}</TimezonesStateContext.Provider>;
}

function useTimezonesState() {
  const context = useContext(TimezonesStateContext);
  if (context === undefined) {
    throw new Error('useTimezonesState must be used within a TimezonesProvider');
  }
  return context;
}

export function useTimezones() {
  const timezones = useTimezonesState();
  return useMemo(() => timezones.map((tz) => ({ value: tz.id, label: tz.displayName })), [
    timezones,
  ]);
}

export function useTimezone() {
  const timezones = useTimezonesState();
  return useCallback(
    (timezone: string) => {
      const tz = timezones.find((z) => z.id === timezone);
      if (!tz) {
        console.warn('Unable to find timezone by id: ', timezone);
        return timezones.find((z) => z.id === 'UTC');
      }
      return tz;
    },
    [timezones]
  );
}
