import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@material-ui/core';
import { RouteTab } from 'da-frontend-shared-ui-components';
import { Switch } from 'react-router-dom';

import { useRouter } from '~/utils/hooks';

import { Route } from '~/utils/routing';

import { useCurrentGenerator } from '~/data/generator';

import { useCurrentGeneratorEvents } from '~/data/generator/events';

import RoutableAccordion from '~/ui/components/routable-accordion';

import { SummaryText, TitleText } from '../-components/generator-management';

import Alerts from './alerts';
import Events from './events';

import { paths } from '~paths';

const containerStyle: CSSProperties = {
  width: '100%',
};

export default function Notifications() {
  const [t] = useTranslation();
  const { isActivePath } = useRouter();
  const [{ generator }] = useCurrentGenerator();
  const { id } = generator;
  const alertsPath = paths.dashboard.generators.show.alerts.with({ id });
  const eventsPath = paths.dashboard.generators.show.events.with({ id });

  const tabs = [alertsPath, eventsPath];

  const activeIndex = tabs.findIndex((tab) => isActivePath(tab));
  const isActive = activeIndex !== -1;

  return (
    <RoutableAccordion
      {...{
        paths: [alertsPath, eventsPath],
        exitPath: paths.dashboard.generators.show.with({ id }),
        headerContent: <TitleText>{t('generator.notifications')}</TitleText>,
        headerActions: [<NewCount key='gnocci' />],
      }}
    >
      {isActive && (
        <div style={containerStyle}>
          <NotificationTabs {...{ activeIndex, alertsPath, eventsPath }} />

          <Switch>
            <Route path={paths.dashboard.generators.show.alerts.path} component={Alerts} />
            <Route path={paths.dashboard.generators.show.events.path} component={Events} />
          </Switch>
        </div>
      )}
    </RoutableAccordion>
  );
}

function NewCount() {
  const { t } = useTranslation();
  const [{ alerts }] = useCurrentGeneratorEvents();
  const totalAlerts = useMemo(() => alerts.length, [alerts]);

  if (totalAlerts === 0) {
    return null;
  }

  return <SummaryText>{t('notifications.new', { num: totalAlerts })}</SummaryText>;
}

function NotificationTabs({ activeIndex, alertsPath, eventsPath }) {
  const { t } = useTranslation();
  const [{ events, alerts }] = useCurrentGeneratorEvents();

  return (
    <Tabs value={activeIndex} variant='fullWidth'>
      <RouteTab
        label={<strong>{t('notifications.alerts', { num: alerts.length })}</strong>}
        to={alertsPath}
      />
      <RouteTab
        label={<strong>{t('notifications.events', { num: events.length })}</strong>}
        to={eventsPath}
      />
    </Tabs>
  );
}
