import { useState } from 'react';

export default function ModalState({ children }) {
  const [isOpen, setIsOpen] = useState();

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return children({ isOpen, open, close });
}
