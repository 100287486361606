import { invert } from 'lodash';
import {
  ArrowExerciseSchedule,
  ScheduledEventInterval,
  DayOfWeek,
} from 'da-pcc-frontend-shared-domain';
import { isAfter } from 'date-fns';

export const asArrowExerciseSchedule = (
  interval: ScheduledEventInterval
): ArrowExerciseSchedule => {
  switch (interval) {
    case ScheduledEventInterval.oneTime:
      return ArrowExerciseSchedule.NEVER;
    case ScheduledEventInterval.weekly:
      return ArrowExerciseSchedule.WEEKLY;
    case ScheduledEventInterval.biweekly:
      // TODO: is this correct?
      return ArrowExerciseSchedule.BIMONTHLY;
    case ScheduledEventInterval.monthly:
      return ArrowExerciseSchedule.MONTHLY;
    case ScheduledEventInterval.now:
    case ScheduledEventInterval.daily:
    case ScheduledEventInterval.yearly:
    default:
      console.warn(
        'Unsupported Schedule Event Interval, defaulting to ArrowExerciseSchedule.NEVER'
      );
      return ArrowExerciseSchedule.NEVER;
  }
};

const dayOfWeekMap = {
  0: DayOfWeek.sunday,
  1: DayOfWeek.monday,
  2: DayOfWeek.tuesday,
  3: DayOfWeek.wednesday,
  4: DayOfWeek.thursday,
  5: DayOfWeek.friday,
  6: DayOfWeek.saturday,
};

export const asDate = (day: DayOfWeek, hour: number /*1-24*/, minutes: number /*0-60*/): Date => {
  const today = new Date();
  const dayOfWeek = +invert(dayOfWeekMap)[day];
  const todayAtTime = dateAtTime(today, hour, minutes);
  if (isSameWeekday(today, dayOfWeek) && isAfter(todayAtTime, today)) {
    return todayAtTime;
  } else {
    return nextOccuranceOnWeekday(todayAtTime, dayOfWeek);
  }
};

const isSameWeekday = (date: Date, dayOfWeek: number) => {
  return dayOfWeek === date.getDay();
};

const nextOccuranceOnWeekday = (date: Date, dayOfWeek: number) => {
  const resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay() - 1) % 7) + 1);
  return resultDate;
};

const dateAtTime = (date: Date, hour: number /*1-24*/, minutes: number /*0-60*/) => {
  const resultDate = new Date(date.getTime());
  resultDate.setHours(hour);
  resultDate.setMinutes(minutes);
  resultDate.setSeconds(0);
  resultDate.setMilliseconds(0);
  return resultDate;
};

export const asDayOfWeek = (date: Date): DayOfWeek => {
  const day = date.getDay();
  return dayOfWeekMap[day];
};

export const asHour = (date: Date): number => {
  return date.getHours();
};

export const asQuarterHour = (date: Date): number => {
  const minutes = date.getMinutes();
  return (Math.round(minutes / 15) * 15) % 60;
};
