import React from 'react';
import { Grid, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useUsers } from '~/data/users';
import UsersTableRow from './row';
import { PageError } from 'da-frontend-shared-ui-components/lib/Errors/PageError';

import { ccglobalStyles } from '~/ui/styles/defaults';
import { useStyles } from '../../../styles';

export default function UsersTable() {
  const cc = ccglobalStyles({});
  const classes = useStyles({});
  const { t } = useTranslation();
  const [{ users }] = useUsers();

  if (!users.length) {
    return (
      <div className={cc.titleSpacing}>
        <PageError
          title={t('users.noUserMessage')}
          description={t('users.noUserMessage')}
          icon='false'
        />
      </div>
    );
  }

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Table className={classes.tableSpacing}>
          <TableBody className={classes.tableContent}>
            {users.map((user) => {
              return <UsersTableRow key={user.id} user={user} />;
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
