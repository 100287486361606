import React from 'react';
import { Switch } from 'react-router';
import { paths } from '~paths';
import { Route } from '~/utils/routing';
import GeneratorsRoute from './generators';
import ExerciseStatusBar from './generators/-components/exerciseStatusBar';

export default function DashboardIndexRoute() {
  return (
    <>
      <ExerciseStatusBar></ExerciseStatusBar>
      <Switch>
        <Route path={paths.dashboard.generators.path} component={GeneratorsRoute} />
      </Switch>
    </>
  );
}
