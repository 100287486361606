import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
  ReactChild,
  Dispatch,
} from 'react';
import {
  DeviceModelCommand,
  CommandResponse,
  ArrowExerciseSchedule,
} from 'da-pcc-frontend-shared-domain';
import { NGDICommandAckMessage, RealtimeProvider } from '../realtime';

import * as actions from './actions';
import reducer, { initialState } from './reducer';
import { CommandsState, CommandsRequestDispatch, CommandsResponseDispatch } from './types';
import useCommand from './useCommand';
import useIncreaseUpdateFrequency from './useIncreaseUpdateFrequency';
import useSubscribeToDeviceStatusAndEvents from './useSubscribeToDeviceStatusAndEvents';

const StoreContext = createContext<[CommandsState, Dispatch<any>] | undefined>(undefined);

const CommandsRequestDispatchContext = createContext<CommandsRequestDispatch | undefined>(
  undefined
);
const CommandsResponseDispatchContext = createContext<CommandsResponseDispatch | undefined>(
  undefined
);

function CommandsResponseProvider({ children }: { children: ReactChild }) {
  const [, dispatch] = useCommandsStore();

  const receivedNGDICommandAckMessage = useCallback(
    (message: NGDICommandAckMessage) => {
      if (!message.samples) {
        console.warn('Received command ack message with no samples'); // tslint:disable-line no-console
      }
      for (const sample of message.samples) {
        const { commandId, commandResponse, commandError } = sample.convertedDeviceParameters;
        switch (commandResponse) {
          case CommandResponse.Pending:
            dispatch(actions.pending({ commandId }));
            break;
          case CommandResponse.Received:
            dispatch(actions.received({ commandId }));
            break;
          case CommandResponse.Succeeded:
            dispatch(actions.success({ commandId }));
            break;
          case CommandResponse.Failed:
            dispatch(actions.failed({ commandId, error: new Error(commandError) }));
            break;
          case CommandResponse.Timeout:
            dispatch(actions.timeout({ commandId, error: new Error(commandError) }));
            break;
        }
      }
    },
    [dispatch]
  );

  return (
    <CommandsResponseDispatchContext.Provider value={{ receivedNGDICommandAckMessage }}>
      {children}
    </CommandsResponseDispatchContext.Provider>
  );
}

function CommandsRequestProvider({ children }: { children: ReactChild }) {
  const increaseUpdateFrequency = useIncreaseUpdateFrequency();
  const subscribeToDeviceStatusAndEvents = useSubscribeToDeviceStatusAndEvents();
  const changeStandby = useCommand<{ on: boolean }>(
    DeviceModelCommand.SetStandby,
    ({ isStandbyEnabled }, request) => {
      return request && isStandbyEnabled === request.on;
    }
  );
  const start = useCommand(DeviceModelCommand.StartGenset, ({ isRunning }) => isRunning === true);
  const stop = useCommand(DeviceModelCommand.StopGenset, ({ isRunning }) => isRunning === false);
  const startExercise = useCommand(
    DeviceModelCommand.StartExercise,
    ({ isExercising }) => isExercising === true
  );
  const stopExercise = useCommand(
    DeviceModelCommand.StopExercise,
    ({ isExercising }) => isExercising === false
  );
  const setExercise = useCommand<{ date: Date; tzOffset: number; repeat: ArrowExerciseSchedule }>(
    DeviceModelCommand.SetExerciseSchedule
  );

  return (
    <CommandsRequestDispatchContext.Provider
      value={{
        increaseUpdateFrequency,
        subscribeToDeviceStatusAndEvents,
        start,
        stop,
        startExercise,
        stopExercise,
        changeStandby,
        setExercise,
      }}
    >
      {children}
    </CommandsRequestDispatchContext.Provider>
  );
}

export function CommandsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      <CommandsResponseProvider>
        <RealtimeProvider>
          <CommandsRequestProvider>{children}</CommandsRequestProvider>
        </RealtimeProvider>
      </CommandsResponseProvider>
    </StoreContext.Provider>
  );
}

export function useCommandsStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useCommandsStore must be used within a CommandsProvider');
  }
  return context;
}

function useCommandsState() {
  const [state] = useCommandsStore();
  return state;
}

function useCommandsRequestDispatch() {
  const context = useContext(CommandsRequestDispatchContext);
  if (context === undefined) {
    throw new Error('useCommandsRequestDispatch must be used within a CommandsProvider');
  }
  return context;
}

export function useCommandsResponseDispatch() {
  const context = useContext(CommandsResponseDispatchContext);
  if (context === undefined) {
    throw new Error('useCommandsResponseDispatch must be used within a CommandsProvider');
  }
  return context;
}

export function useCommands(): [CommandsState, CommandsRequestDispatch] {
  return [useCommandsState(), useCommandsRequestDispatch()];
}
