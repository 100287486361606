import React, { useCallback } from 'react';
import Accordion, { AccordionProps } from 'da-frontend-shared-ui-components/lib/Accordion';

import { Route } from '~/utils/routing';

import { useRouter } from '~/utils/hooks';

interface IProps {
  path?: string;
  paths?: string[];
  exitPath: string;
  children: React.ReactNode;
}

export default function RoutableAccordion(props: IProps & AccordionProps) {
  const { path, paths: multiplePaths, exitPath, children, ...rest } = props;
  const { history, isActivePath } = useRouter();
  const paths = makeArray(path, multiplePaths);
  const firstPath = paths[0];

  const activeIndex = paths.findIndex((path) => isActivePath(path));
  const isActive = activeIndex !== -1;

  const toggle = useCallback(() => {
    if (!isActive) {
      return history.push(firstPath);
    }

    return history.push(exitPath);
  }, [exitPath, firstPath, history, isActive]);

  return (
    <Route path={paths}>
      <>
        <Accordion
          {...{
            expanded: isActive,
            loadOnce: true,
            onChange: toggle,
            ...rest,
          }}
        >
          {isActive && (typeof children === 'function' ? children({ isActive }) : children)}
        </Accordion>
        <hr />
      </>
    </Route>
  );
}

function makeArray(singular, many) {
  let result = [];

  if (singular) {
    result.push(singular);
  }

  if (many && many.length > 0) {
    result = result.concat(many);
  }

  return result;
}
