import { makeStyles } from '@material-ui/styles';
import { grayLight } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  container: {
    width: '18.75rem',
  },
  body: {
    padding: '10px 15px',
  },
  title: {
    padding: '10px 0',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: grayLight,
  },
  description: {
    fontSize: 13,
    margin: '10px 0 20px',
  },
  contactButton: {
    marginBottom: 15,
  },
});
