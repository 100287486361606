import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchUsers } from './actions';
import { UsersState } from './types';

export const initialState: UsersState = {
  loading: true,
  users: undefined,
  error: undefined,
};

const usersReducer = reducerWithInitialState(initialState)
  .case(fetchUsers.done, (state, { result: users }) => ({
    ...state,
    users,
    loading: false,
  }))
  .case(fetchUsers.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default usersReducer;
