import { CommandResponse, DeviceModelCommand } from 'da-pcc-frontend-shared-domain';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentGenerator } from '../context';
import { useRealtime } from '../realtime';
import * as actions from './actions';
import { useCommandsStore } from './context';
import { CommandIdle, CommandInvocation, CommandPayload, CommandSuccessfulFn } from './types';
import useCommandId from './useCommandId';
import useCommandType from './useCommandType';

const COMMAND_TIMEOUT_SECONDS = 60;

export default function useCommand<T extends CommandPayload>(
  commandType: DeviceModelCommand,
  commandSuccessfulFn?: CommandSuccessfulFn
): [CommandInvocation, (payload?: T) => Promise<void>] {
  const [{ generator }] = useCurrentGenerator();
  const [{ connection }] = useRealtime();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // using useRef here to prevent re-rendering the callbacks below
  const commandId = useRef<string>();
  const [commands, dispatch] = useCommandsStore();
  const invokeCommand = useCommandType(commandType);
  const getCommandId = useCommandId();

  const { id: assetId, status: generatorStatus } = generator;

  // Defining a default state so we can use this to control ui states
  // without worrying about undefined cases
  const initialState = useMemo<CommandInvocation>(
    () => ({
      id: commandId.current,
      loading: false,
      status: CommandIdle,
    }),
    []
  );

  const state = useMemo<CommandInvocation>(() => commands[commandId.current] || initialState, [
    commands,
    initialState,
  ]);

  const command = useCallback(
    async (payload?: T) => {
      if (connection) {
        const { subscriptionId, endpoint } = connection;
        commandId.current = getCommandId();
        try {
          dispatch(actions.pending({ commandId: commandId.current, payload }));
          await invokeCommand(assetId, commandId.current, { subscriptionId, endpoint }, payload);
        } catch (error) {
          dispatch(actions.failed({ commandId: commandId.current, error }));
        }
      } else {
        console.warn('websocket connection is unavailable, ignoring command invocation');
      }
    },
    [assetId, dispatch, connection, invokeCommand, getCommandId]
  );

  useEffect(() => {
    const { id, status } = state;
    let timer: NodeJS.Timer = undefined;

    if (status === CommandResponse.Pending) {
      timer = setTimeout(() => {
        if (status === CommandResponse.Pending) {
          dispatch(
            actions.timeout({
              commandId: id,
              error: new Error(t(`Commands.${commandType}.Timeout`)),
            })
          );
        }
      }, COMMAND_TIMEOUT_SECONDS * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
    };
  }, [commandType, dispatch, state, t]);

  useEffect(() => {
    const { status } = state;
    const statusWhitelist = [CommandResponse.Received, CommandResponse.Failed];
    const translationKeyMap = {
      [CommandIdle]: 'Idle',
      [CommandResponse.Pending]: 'Pending',
      [CommandResponse.Received]: 'Received',
      [CommandResponse.Succeeded]: 'Succeeded',
      [CommandResponse.Failed]: 'Failed',
      [CommandResponse.Timeout]: 'Timeout',
    };
    if (statusWhitelist.includes(status)) {
      enqueueSnackbar(t(`Commands.${commandType}.${translationKeyMap[status]}`));
    }
  }, [commandType, dispatch, enqueueSnackbar, state, t]);

  useEffect(() => {
    const { id, status, payload } = state;
    // When the command status completes remove it from the state
    const failureStatuses = [CommandResponse.Failed, CommandResponse.Timeout];
    const successStatuses = [CommandResponse.Received, CommandResponse.Succeeded];

    // When the commandSuccessfulFn the command will use the DeviceStatus to determine if the command is "done"
    // When commandSuccessfulFn is undefined fallback to the CommandAck Response
    const isSuccess = commandSuccessfulFn
      ? commandSuccessfulFn(generatorStatus, payload)
      : successStatuses.includes(status);
    const isFailure = failureStatuses.includes(status);
    if (isSuccess || isFailure) {
      dispatch(actions.reset({ commandId: id }));
      commandId.current = undefined;
    }
  }, [dispatch, state, generatorStatus]);

  return [state, command];
}
