import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PersistedAsset,
  getAssetPropertyValue,
  TelemetryDatum,
  ArrowGensetStatus,
} from 'da-pcc-frontend-shared-domain';

import { useCurrentGenerator } from '~/data/generator';

import InfoTable from '~/ui/components/info-table';

import RoutableAccordion from '~/ui/components/routable-accordion';

import { TitleText } from '../-components/generator-management';

import { paths } from '~paths';

export default function GeneratorData() {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();
  const { id } = generator;

  const dataPath = paths.dashboard.generators.show.generatorData.with({ id });

  return (
    <RoutableAccordion
      {...{
        path: dataPath,
        exitPath: paths.dashboard.generators.show.with({ id }),
        headerContent: <TitleText>{t('generator.generatorData')}</TitleText>,
      }}
    >
      <GeneratorDataInfo />
    </RoutableAccordion>
  );
}

function GeneratorDataInfo() {
  return (
    <>
      <Nameplate />
      <Data />
    </>
  );
}

function Nameplate() {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();

  const nameplate = useMemo(() => getNameplateData(t, generator), [t, generator]);

  return <InfoTable title={t('generatorData.nameplate')} data={nameplate} />;
}

function Data() {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();

  const generatorData = useMemo(() => getGeneratorData(t, generator), [t, generator]);

  return <InfoTable title={t('generatorData.title')} data={generatorData} />;
}

function getNameplateData(t: TFunction, generator: PersistedAsset) {
  const { equipmentModel } = generator;

  const softwareVersion = getAssetPropertyValue(generator, 'softwareVersion');
  const serialNumber = getAssetPropertyValue(generator, 'serialNumber');
  const customerAccessCode = getAssetPropertyValue(generator, 'customerAccessCode');

  return [
    { key: t('generatorData.serialNumber'), value: maybe(serialNumber) },
    { key: t('generatorData.model'), value: equipmentModel.name },
    { key: t('generatorData.customerAccessCode'), value: maybe(customerAccessCode) },
    { key: t('generatorData.softwareVersion'), value: maybe(softwareVersion) },
  ];
}

function getGeneratorData(t: TFunction, generator: PersistedAsset) {
  const {
    status: { gensetStatus, lastTelemetry },
  } = generator;
  const { data } = lastTelemetry || { data: [] };

  const status = generatorStatus(t, gensetStatus);
  const loadStatus = generatorLoadStatus(t, +getTelemetryPropertyValue(data, 'loadStatus'));
  const batteryVoltage = getTelemetryPropertyValue(data, 'batteryVoltage');
  const averageEngineSpeed = getTelemetryPropertyValue(data, 'averageEngineSpeed');
  const frequencyOp = getTelemetryPropertyValue(data, 'frequencyOP');

  return [
    { key: t('generatorData.status'), value: maybe(status) },
    { key: t('generatorData.loadStatus'), value: maybe(loadStatus) },
    { key: t('generatorData.batteryVoltage'), value: maybe(batteryVoltage) },
    { key: t('generatorData.engineSpeed'), value: maybe(averageEngineSpeed) },
    { key: t('generatorData.generatorFrequency'), value: maybe(frequencyOp) },
  ];
}

const generatorStatus = (t: TFunction, status: ArrowGensetStatus): string => {
  switch (status) {
    case ArrowGensetStatus.Stopped:
      return t('generatorData.generatorStatus.Stopped');
    case ArrowGensetStatus.Cranking:
      return t('generatorData.generatorStatus.Cranking');
    case ArrowGensetStatus.Running:
      return t('generatorData.generatorStatus.Running');
    case ArrowGensetStatus.Fault:
      return t('generatorData.generatorStatus.Fault');
    case ArrowGensetStatus.TestMode:
      return t('generatorData.generatorStatus.TestMode');
    case ArrowGensetStatus.VoltAdjust:
      return t('generatorData.generatorStatus.VoltAdjust');
    case ArrowGensetStatus.CyclePause:
      return t('generatorData.generatorStatus.CyclePause');
    case ArrowGensetStatus.Exercising:
      return t('generatorData.generatorStatus.Exercising');
    case ArrowGensetStatus.Cooldown:
      return t('generatorData.generatorStatus.CoolDown');
    case ArrowGensetStatus.Unknown:
      return t('generatorData.generatorStatus.Unknown');
    default:
      return '';
  }
};

/* eslint-disable @typescript-eslint/camelcase */
enum ArrowGensetLoadStatus {
  Load_All_Off = 0x00,
  Load_1_On = 0x01,
  Load_1_2_On = 0x03,
  Load_1_2_3_On = 0x07,
  Load_All_On = 0x0f,
}
/* eslint-enable @typescript-eslint/camelcase */

const generatorLoadStatus = (t: TFunction, status: ArrowGensetLoadStatus): string => {
  switch (status) {
    case ArrowGensetLoadStatus.Load_All_Off:
      return t('generatorData.generatorLoadStatus.Load_All_Off');
    case ArrowGensetLoadStatus.Load_1_On:
      return t('generatorData.generatorLoadStatus.Load_1_On');
    case ArrowGensetLoadStatus.Load_1_2_On:
      return t('generatorData.generatorLoadStatus.Load_1_2_On');
    case ArrowGensetLoadStatus.Load_1_2_3_On:
      return t('generatorData.generatorLoadStatus.Load_1_2_3_On');
    case ArrowGensetLoadStatus.Load_All_On:
      return t('generatorData.generatorLoadStatus.Load_All_On');
    default:
      return '';
  }
};

function getTelemetryPropertyValue(data: TelemetryDatum[], key: string): string | undefined {
  const datum = data.find(({ name }) => name === key);
  return datum ? datum.value : undefined;
}

function maybe(value, defaultValue = '-') {
  let result;

  if (typeof value === 'function') {
    try {
      result = value();
    } catch (e) {
      // catching, to not block the UI, and because the states of
      // these values are horribly messy
      console.error('value could not be found', e);
    }
  } else {
    result = value;
  }

  return result || defaultValue;
}
