import React, { createContext, useContext, useCallback } from 'react';
import { useCurrentGenerator } from '../context';
import { useCurrentGeneratorEvents } from '../events';
import { useCommandsResponseDispatch } from '../commands/context';

import { useWebsocket } from './websocket';
import {
  isNGDICommandAck,
  isNGDITelemetry,
  isNGDIEvent,
  RealtimeState,
  RealtimeDispatch,
  isNGDIDeviceStatus,
  isNGDIGroupStatus,
} from './types';

const RealtimeStateContext = createContext<RealtimeState | undefined>(undefined);
const RealtimeDispatchContext = createContext<RealtimeDispatch | undefined>(undefined);

export function RealtimeProvider({ children }) {
  const [, { receivedNGDIDeviceStatusMessage }] = useCurrentGenerator();
  const [, { receivedNGDIEventMessage }] = useCurrentGeneratorEvents();
  const { receivedNGDICommandAckMessage } = useCommandsResponseDispatch();

  const onMessage = useCallback(
    (message) => {
      if (isNGDICommandAck(message)) {
        receivedNGDICommandAckMessage(message);
      } else if (isNGDITelemetry(message)) {
      } else if (isNGDIDeviceStatus(message)) {
        receivedNGDIDeviceStatusMessage(message);
      } else if (isNGDIGroupStatus(message)) {
        // TODO: handle Group Status message
      } else if (isNGDIEvent(message)) {
        receivedNGDIEventMessage(message);
      } else {
        throw new Error(`Received unexpected message:\n${message}`);
      }
    },
    [receivedNGDICommandAckMessage, receivedNGDIEventMessage, receivedNGDIDeviceStatusMessage]
  );
  const { connection, isConnected, error } = useWebsocket({
    onMessage,
  });

  if (error) {
    throw error;
  }

  return (
    <RealtimeStateContext.Provider value={{ connection, isConnected }}>
      <RealtimeDispatchContext.Provider value={{}}>{children}</RealtimeDispatchContext.Provider>
    </RealtimeStateContext.Provider>
  );
}

function useRealtimeState() {
  const context = useContext(RealtimeStateContext);
  if (context === undefined) {
    throw new Error('useRealtimeState must be used within a RealtimeProvider');
  }
  return context;
}
function useRealtimeDispatch() {
  const context = useContext(RealtimeDispatchContext);
  if (context === undefined) {
    throw new Error('useRealtimeDispatch must be used within a RealtimeProvider');
  }
  return context;
}

export function useRealtime(): [RealtimeState, RealtimeDispatch] {
  return [useRealtimeState(), useRealtimeDispatch()];
}
