import * as React from 'react';
import { PageError } from 'da-frontend-shared-ui-components/lib/Errors';

import { parseError } from './parse-error';

export interface IProps {
  error?: any;
}

export default function FormattedError({ error, style }) {
  const { title, body } = parseError(error);

  return (
    <div style={style}>
      <PageError title={title} description={body} />
    </div>
  );
}
