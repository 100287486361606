import React, { useMemo } from 'react';
import { Button, Popover, Typography as T } from '@material-ui/core';
import { useGeneratorsList } from '~/data/generators';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function ExerciseStatusBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({});
  const [t] = useTranslation();
  const [{ generators }] = useGeneratorsList();
  const generatorNames = useMemo(
    () =>
      generators
        .filter((generator) => generator.status.isExercising)
        .map((generator) => generator.name),
    [generators]
  );
  const exerciseInProgress = useMemo(() => generatorNames.length > 0, [generatorNames]);
  const showExerciseInfo = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!exerciseInProgress) {
    return null;
  }

  return (
    <div className={classes.exerciseStatusBar}>
      <Button className={classes.exerciseToggle} onClick={handleClick}>
        {t('exercise.statusBanner.inProgress', {
          generatorNames: generatorNames.join(', '),
        })}
      </Button>
      <Popover
        open={showExerciseInfo}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        <T className={classes.exerciseInfo}>{t('exercise.statusBanner.info')}</T>
      </Popover>
    </div>
  );
}
