import { CSSProperties } from 'react';
import { defaultTheme } from 'da-frontend-shared-ui-components';

export const sectionStyles: CSSProperties = {
  gridArea: 'section',
  flexGrow: 1,
};

export const loadingStyles: CSSProperties = {
  gridArea: 'header / section',
  flexGrow: 1,
  margin: defaultTheme.spacing.unit * 8,
};

export const headerStyles: CSSProperties = {
  gridArea: 'header',
};
