import React from 'react';
import { Button, Grid, Typography as T } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import InviteModal from '../invite-modal';

import { useModal } from '~/utils/hooks';

import { ccglobalStyles } from '~/ui/styles/defaults';

export default function Header() {
  const { t } = useTranslation();
  const cc = ccglobalStyles({});
  const { isOpen, open, close } = useModal();

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <T variant='h1' className={cc.titleSpacing}>
          {t('users.manage')}
        </T>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            open();
          }}
        >
          {t('users.invite.action')}
        </Button>

        {isOpen && <InviteModal {...{ close }} />}
      </Grid>
    </Grid>
  );
}
