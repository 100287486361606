import {
  CommandResponse,
  DeviceModelType,
  DeviceTypeCapability,
  DynamoEventSeverity as EventSeverity,
  OperationalStatus,
  ExerciseRepeatType,
  PowerSourceState,
  ArrowGensetStatus,
  GeneratorSetControlState,
  TransferSwitchSourceState,
} from 'da-pcc-frontend-shared-domain/lib';

export type EntityId = string;

export interface RealtimeConnection {
  subscriptionId: string;
  endpoint: string;
}
export interface RealtimeState {
  connection?: RealtimeConnection;
  isConnected: boolean;
}

export interface RealtimeDispatch {}

export interface ObjectifiedTelemetry {
  [key: string]: any;
}

export interface ConnectionControlMessage<T = any> {
  action: string;
  payload: T;
}

export interface PingPayload {
  pong: true;
}

export interface PingMessage extends ConnectionControlMessage<PingPayload> {
  action: '/ping';
}

export interface ConnectionInfoMessage extends ConnectionControlMessage<RealtimeConnection> {
  action: '/getConnectionInfo';
}

export const isPingMessage = (msg: object): msg is PingMessage => msg['action'] === '/ping'; // tslint:disable-line no-string-literal

export const isConnectionInfoMessage = (msg: object): msg is ConnectionInfoMessage =>
  msg['action'] === '/getConnectionInfo'; // tslint:disable-line no-string-literal

export const isNGDIMessage = (msg: object): msg is NGDIMessage => msg['namespace']; // tslint:disable-line no-string-literal

export const isNGDICommandAck = (msg: NGDIMessage): msg is NGDICommandAckMessage =>
  msg.namespace === 'commandAck';

export const isNGDITelemetry = (msg: NGDIMessage): msg is NGDITelemetryMessage =>
  msg.namespace === 'telemetry';

export const isNGDIDeviceStatus = (msg: any): msg is NGDIDeviceStatusMessage =>
  msg.namespace === 'devicestatus';

export const isNGDIGroupStatus = (msg: any): msg is NGDIGroupStatusMessage =>
  msg.namespace === 'groupstatus';

export const isNGDIEvent = (msg: NGDIMessage): msg is NGDIEventMessage => msg.namespace === 'event';

export interface NGDISample<T> {
  dateTimestamp: string;
  convertedDeviceParameters: T;
  // convertedEquipmentFaultCodes: U;
}

export interface NGDIMessage<T = object> {
  messageFormatVersion?: string;
  id: EntityId;
  namespace: string;
  telematicsPartnerName?: string;
  telematicsDeviceId: EntityId;
  equipmentId: EntityId;
  groupId: EntityId;
  dataSamplingConfigId?: string; // observed as "dummy"
  dataEncryptionSchemeId?: string; // observed as "none"
  customerReference?: EntityId;
  capabilities?: DeviceTypeCapability[];
  deviceModelType?: DeviceModelType;
  resourceName?: string;
  timestampDevice?: string;
  timestampCloud?: string;
  numberOfSamples?: number;
  samples: NGDISample<T>[];
}

export interface NGDICommandAckSampleParameters {
  commandId: EntityId;
  commandResponse: CommandResponse;
  commandError: null | string;
}

export interface NGDITelemetrySampleParameters {
  [key: string]: number | string;
}

export interface NGDIEventSampleParameters {
  message?: string;
  messageParts?: string[];
}

export interface NGDIEventSample extends NGDISample<NGDIEventSampleParameters> {
  convertedEquipmentFaultCodes: {
    deviceId: EntityId;
    activeFaultCodes: {
      spn: string;
      severity: EventSeverity;
    }[];
  }[];
}

export interface NGDICommandAckMessage extends NGDIMessage<NGDICommandAckSampleParameters> {
  namespace: 'commandAck';
}

export interface NGDITelemetryMessage extends NGDIMessage<NGDITelemetrySampleParameters> {
  namespace: 'telemetry';
}

export interface NGDIEventMessage extends NGDIMessage {
  namespace: 'event';
  samples: NGDIEventSample[];
}

export interface NGDIGroupStatusMessage {
  id: string; // siteId
  namespace: 'groupstatus';
  lastUpdate: string; // "2019-07-30T21:32:32.000Z"
  status: OperationalStatus;
}

export interface NGDIDeviceStatusMessage {
  id: string;
  namespace: 'devicestatus';

  equipmentId: string;
  groupId: string; //siteId
  lastUpdate: string; // "2019-07-30T21:32:32.000Z",
  telematicsDeviceId: string;
  status: OperationalStatus;
  isStandbyEnabled?: boolean;
  isRemoteEnabled?: boolean;
  isRunning?: boolean;
  isExercising?: boolean;
  engineRuntime?: number;
  lastMaintenance?: string; //"2019-07-30T21:23:00Z"
  lastExercise?: string; // "2019-07-30T21:23:00Z"
  nextExerciseRepeat?: ExerciseRepeatType;
  powerSource?: PowerSourceState;
  gensetStatus?: ArrowGensetStatus;
  controlState?: GeneratorSetControlState;
  source1?: TransferSwitchSourceState;
  source2?: TransferSwitchSourceState;
  configurationErrors?: string[];
  lastTelemetry: {
    [key: string]: number | string;
  };
  inAuto?: boolean;
}
