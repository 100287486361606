import { CSSProperties } from 'react';
import { defaultTheme, colors } from 'da-frontend-shared-ui-components';

const spacing = defaultTheme.spacing.unit;

export const titleStyle: CSSProperties = {
  borderBottom: '1px solid',
  borderColor: colors.grayLight,
  paddingTop: spacing * 3,
  paddingBottom: spacing * 3,
  marginBottom: spacing * 2,
  display: 'block',
  width: '100%',
};

export const labelStyle: CSSProperties = {
  fontSize: '18px',
  lineHeight: 1.11,
  marginBottom: spacing,
};

export const valueStyle: CSSProperties = {
  textAlign: 'right',
  color: colors.grayMed,
  fontWeight: 'bold',
};

export const layoutStyle: CSSProperties = {
  width: '100%',
  display: 'grid',
  gridTemplate: `"dateLabel date" "info hours"`,
  gridGap: `${spacing * 3}px ${spacing * 3}px`,
};
