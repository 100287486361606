import React, { useContext, createContext, useReducer, useMemo, useEffect } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';
import { useAsyncFn } from 'react-use';
import { useAPI } from '..';
import { fetchMyDealers } from './actions';
import reducer, { initialState } from './reducer';
import { DealerState, DealerDispatch } from './types';

const DealerStateContext = createContext<DealerState | undefined>(undefined);
const DealerDispatchContext = createContext<DealerDispatch | undefined>(undefined);

export function DealerProvider({ children }) {
  const { dealers } = useAPI();
  const [{ myDealersLoading, managedDealer, requestedDealer, error }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const loading = useMemo(() => myDealersLoading, [myDealersLoading]);
  const state = useMemo(
    () => ({
      managedDealer,
      requestedDealer,
      loading,
      error,
    }),
    [managedDealer, requestedDealer, loading, error]
  );

  const [, getMyDealers] = useAsyncFn(async () => {
    try {
      dispatch(fetchMyDealers.started());

      const result = await dealers.getMyDealers();

      dispatch(fetchMyDealers.done({ result }));
    } catch (error) {
      dispatch(fetchMyDealers.failed({ error }));
    }
  }, [dealers, dispatch]);

  const cancelRequest = useAsyncFn(async () => {
    await dealers.cancelDealerRequest();
    await getMyDealers();
  }, [dealers, getMyDealers]);

  const deauthorize = useAsyncFn(async () => {
    await dealers.revokeManagingDealer();
    await getMyDealers();
  }, [dealers, getMyDealers]);

  const [getDealerState, getDealer] = useAsyncFn(async () => {
    await getMyDealers();
  }, [dealers, getMyDealers]);

  useEffect(() => {
    getDealer();
  }, [getDealer]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <DealerStateContext.Provider value={state}>
      <DealerDispatchContext.Provider
        value={{ getDealer: [getDealerState, getDealer], cancelRequest, deauthorize }}
      >
        {children}
      </DealerDispatchContext.Provider>
    </DealerStateContext.Provider>
  );
}
function useDealerState() {
  const context = useContext(DealerStateContext);
  if (context === undefined) {
    throw new Error('useDealerState must be used within a DealerProvider');
  }
  return context;
}

function useDealerDispatch() {
  const context = useContext(DealerDispatchContext);
  if (context === undefined) {
    throw new Error('useDealerDispatch must be used within a DealerProvider');
  }
  return context;
}

export function useDealer(): [DealerState, DealerDispatch] {
  return [useDealerState(), useDealerDispatch()];
}
