import { Grid } from '@material-ui/core';
import React from 'react';
import { Redirect, Switch } from 'react-router';

import { GeneratorProvider } from '~/data/generator';
import { CommandsProvider } from '~/data/generator/commands';
import { EventsProvider } from '~/data/generator/events';
import { ExerciseScheduleProvider } from '~/data/generator/exercise-schedule';
import { PermissionProvider } from '~/data/generator/permissions';
import { useGeneratorsList, ValidateData } from '~/data/generators';
import ResponsiveContainer from '~/ui/components/responsive-container';
import { Route } from '~/utils/routing';
import { paths } from '~paths';

import OffCanvas from './-components/off-canvas';
import GeneratorsIndexRoute from './show';
import Header from './show/-components/header';
import { headerStyles, sectionStyles } from './styles';

export default function DashboardGeneratorsIndexRoute() {
  return (
    <ValidateData>
      <ResponsiveContainer xs={12}>
        <OffCanvas>
          <Switch>
            <Route
              onErrorProps={{ style: sectionStyles }}
              path={paths.dashboard.generators.show.path}
              component={ShowDisplay}
            />

            <Route component={FirstGenerator} />
          </Switch>
        </OffCanvas>
      </ResponsiveContainer>
    </ValidateData>
  );
}

function ShowDisplay() {
  return (
    <GeneratorProvider>
      <PermissionProvider>
        <EventsProvider>
          <CommandsProvider>
            <ExerciseScheduleProvider>
              <Header style={headerStyles} />
              <section style={sectionStyles}>
                <Grid container>
                  <GeneratorsIndexRoute />
                </Grid>
              </section>
            </ExerciseScheduleProvider>
          </CommandsProvider>
        </EventsProvider>
      </PermissionProvider>
    </GeneratorProvider>
  );
}

function FirstGenerator() {
  const [{ generators }] = useGeneratorsList();

  const first = generators[0];

  if (!first) {
    return null;
  }

  return <Redirect to={paths.dashboard.generators.show.with({ id: first.id })} />;
}
