import React, { CSSProperties } from 'react';
import { Route as ReactRouterRoute } from 'react-router-dom';

import ErrorBoundary from '~/ui/components/errors/error-boundary';
import { ConfigProvider } from '~/data/config';

export function Route(passedProps: any /* RouteProps */) {
  const { onErrorProps, ...props } = passedProps;
  const style: CSSProperties = (onErrorProps || {}).style;

  return (
    <ConfigProvider>
      <ErrorBoundary style={style}>
        <ReactRouterRoute {...props} />
      </ErrorBoundary>
    </ConfigProvider>
  );
}
