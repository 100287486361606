import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchDealers } from './actions';
import { DealersState } from './types';

export const initialState: DealersState = {
  loading: true,
  dealers: [],
  error: undefined,
};

const dealersReducer = reducerWithInitialState(initialState)
  .case(fetchDealers.done, (state, { result: dealers }) => ({
    ...state,
    dealers,
    loading: false,
  }))
  .case(fetchDealers.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default dealersReducer;
