import React, { useContext, createContext, useEffect, useReducer, useCallback } from 'react';
import { Loader } from 'da-frontend-shared-ui-components';
import {
  UpdateConnectCloudEquipmentRequest,
  CompleteConnectCloudSetupRequest,
  LocateConnectCloudEquipmentRequest,
  RemoveConnectCloudEquipmentRequest,
  PersistedAsset,
  DeviceStatus,
} from 'da-pcc-frontend-shared-domain';
import { useAsyncFn } from 'react-use';

import { GeneratorsState, GeneratorsDispatch } from './types';
import reducer, { initialState } from './reducer';

import { useAPI } from '..';

import { fetchGenerators, updateDeviceStatus } from './actions';
import { NGDIDeviceStatusMessage } from '../generator/realtime';
import { logMe, portal } from '../logger';

const GeneratorsStateContext = createContext<GeneratorsState | undefined>(undefined);
const GeneratorDispatchContext = createContext<GeneratorsDispatch | undefined>(undefined);

export function GeneratorsProvider({ children }) {
  const { equipments, logger } = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [fetchAllState, fetchAll] = useAsyncFn(async () => {
    dispatch(fetchGenerators.started());

    const generators: PersistedAsset[] = [];

    const pageSize = 100;
    let currentPage = 1;
    let pageCount = 1;

    try {
      do {
        // UI logger API call
        logger.loggerControllerLogMe(logMe({
          metadata: [ portal ],
          body: {
            Request: {
              path: 'connectcloud/Equipment',
              params: { currentPage, pageCount }
            }
          }
        }));

        const equipmentResult = await equipments.getConnectCloudEquipment({
          currentPage,
          pageSize,
        });

        logger.loggerControllerLogMe(logMe({
          metadata: [ portal ],
          body: {
            Response: {
              path: 'connectcloud/Equipment',
              params: equipmentResult
            }
          }
        }));

        generators.push(...equipmentResult.items);

        currentPage = equipmentResult.meta.currentPage;
        pageCount = equipmentResult.meta.pageCount;

        currentPage += 1;
      } while (currentPage <= pageCount);

      dispatch(fetchGenerators.done({ result: generators }));
      return generators;
    } catch (error) {
      dispatch(fetchGenerators.failed({ error }));
    }
  }, [equipments, fetchGenerators, dispatch]);

  const findBySerialNumberAndAccessCode = useAsyncFn(
    async (request: LocateConnectCloudEquipmentRequest) => {

      // UI logger API call
      logger.loggerControllerLogMe(logMe({
        metadata: [ portal ],
        body: {
          Request: {
            path: '/connectcloud/Equipment/locate',
            params: request
          }
        }
      }));
      let response;

    try{
      response = await equipments.locateConnectCloudEquipment(request);
    } catch(err) {
      const res = await err.json();
      response = res.error;
    }
      logger.loggerControllerLogMe(logMe({
        metadata: [ portal ],
        body: {
          Response: {
            path: '/connectcloud/Equipment/locate',
            params: response
          }
        }
      }));
      
      return response;
    },
    [equipments]
  );

  const create = useAsyncFn(
    async (generator: CompleteConnectCloudSetupRequest) => {

      // UI logger API call
      logger.loggerControllerLogMe(logMe({
        metadata: [ portal ],
        body: {
          Request: {
            path: '/connectcloud/Equipment',
            params: generator
          }
        }
      }));

      const response = await equipments.completeConnectCloudSetup(generator);

      logger.loggerControllerLogMe(logMe({
        metadata: [ portal ],
        body: {
          Response: {
            path: '/connectcloud/Equipment',
            params: response
          }
        }
      }));

      return await fetchAll();
    },
    [equipments]
  );

  const update = useAsyncFn(
    async (request: UpdateConnectCloudEquipmentRequest) => {
      await equipments.updateConnectCloudEquipment(request);
      return await fetchAll();
    },
    [equipments, fetchAll]
  );

  const destroy = useAsyncFn(
    async (request: RemoveConnectCloudEquipmentRequest) => {
      await equipments.removeConnectCloudEquipment(request);
      return await fetchAll();
    },
    [equipments]
  );

  const receivedNGDIDeviceStatusMessage = useCallback(
    (message: NGDIDeviceStatusMessage) => {
      const status: DeviceStatus = {
        equipmentId: message.equipmentId,
        siteId: message.groupId,
        lastUpdate: new Date(message.lastUpdate),
        telematicsDeviceId: message.telematicsDeviceId,
        status: message.status,
        isStandbyEnabled: message.isStandbyEnabled,
        isRemoteEnabled: message.isRemoteEnabled,
        isRunning: message.isRunning,
        isExercising: message.isExercising,
        engineRuntime: message.engineRuntime,
        lastMaintenance: message.lastMaintenance ? new Date(message.lastMaintenance) : undefined,
        lastExercise: message.lastExercise ? new Date(message.lastExercise) : undefined,
        nextExerciseRepeat: message.nextExerciseRepeat,
        powerSource: message.powerSource,
        gensetStatus: message.gensetStatus,
        controlState: message.controlState,
        source1: message.source1,
        source2: message.source2,
        configurationErrors: message.configurationErrors,
        lastTelemetry: {
          timestamp: new Date(message.lastUpdate),
          equipmentId: message.equipmentId,
          groupId: message.groupId,
          data: Object.keys(message.lastTelemetry || {}).map((name) => ({
            name,
            value: `${message.lastTelemetry[name]}`,
          })),
        },
        inAuto: message.inAuto,
      };
      dispatch(updateDeviceStatus(status));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <GeneratorsStateContext.Provider value={state}>
      <GeneratorDispatchContext.Provider
        value={{
          findBySerialNumberAndAccessCode,
          create,
          update,
          destroy,
          receivedNGDIDeviceStatusMessage,
          fetchAll: [fetchAllState, fetchAll],
        }}
      >
        {children}
      </GeneratorDispatchContext.Provider>
    </GeneratorsStateContext.Provider>
  );
}

function useGeneratorsListState() {
  const context = useContext(GeneratorsStateContext);
  if (context === undefined) {
    throw new Error('useGeneratorsListState must be used within a GeneratorsProvider');
  }
  return context;
}

function useGeneratorsListDispatch() {
  const context = useContext(GeneratorDispatchContext);
  if (context === undefined) {
    throw new Error('useGeneratorsListDispatch must be used within a GeneratorsProvider');
  }
  return context;
}

export function useGeneratorsList(): [GeneratorsState, GeneratorsDispatch] {
  return [useGeneratorsListState(), useGeneratorsListDispatch()];
}
