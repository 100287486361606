import React from 'react';
import { Switch } from 'react-router-dom';

import { paths } from '~paths';

import { Route } from '~/utils/routing';

import Settings from './settings';
import { NotificationsProvider } from '~/data/notifications';

export default function PreferencesNotificationsRoute() {
  return (
    <NotificationsProvider>
      <Switch>
        <Route exact path={paths.preferences.notifications.path} component={Settings} />
      </Switch>
    </NotificationsProvider>
  );
}
