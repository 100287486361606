import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchProfile } from './actions';
import { SessionState } from './types';

export const initialState: SessionState = {
  loading: true,
  isAuthenticated: false,
  profile: undefined,
};

const profileReducer = reducerWithInitialState(initialState)
  .case(fetchProfile.done, (state, { result: profile }) => ({
    ...state,
    profile,
    loading: false,
    isAuthenticated: true,
  }))
  .case(fetchProfile.failed, (state, { error }) => ({
    ...state,
    loading: false,
    isAuthenticated: false,
  }));

export default profileReducer;
