import React, { createContext, useContext, useReducer } from 'react';
import { useAsyncFn } from 'react-use';
import reducer, { initialState } from './reducer';
import { RegistrationDispatch, RegistrationState } from './types';

import { useAPI } from '..';

import { fetchInvitationCode, acceptInvitationCode } from './actions';
import { useSession } from '../session';
import { logMe, portal } from '../logger';

const RegistrationStateContext = createContext<RegistrationState | undefined>(undefined);
const RegistrationDispatchContext = createContext<RegistrationDispatch | undefined>(undefined);

export function RegistrationProvider({ children }) {
  const [, { getMe }] = useSession();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { users, logger } = useAPI();

  const [, getInvite] = useAsyncFn(
    async (code) => {
      dispatch(fetchInvitationCode.started());
      await users
        .getConnectCloudInviteByCode({ invitationCode: code })
        .then((user) => dispatch(fetchInvitationCode.done({ result: user })))
        .catch((error) => dispatch(fetchInvitationCode.failed({ error })));
    },
    [users]
  );

  const confirmInvite = useAsyncFn(
    async (code) => {
      dispatch(acceptInvitationCode.started());
      await users
        .completeInviteWithCode({ invitationCode: code })
        .then((user) => dispatch(acceptInvitationCode.done({ result: user })))
        .catch((error) => dispatch(acceptInvitationCode.failed({ error })));
      await getMe();
    },
    [users, getMe]
  );

  const completeSignup = useAsyncFn(async () => {

    // UI logger API call
    logger.loggerControllerLogMe(logMe({
      metadata: [ portal ],
      body: {
        Request: {
          path: '/registration/complete',
          params: {}
        }
      }
    }));

    const response = await users.postConnectCloudRegistrationComplete();

    logger.loggerControllerLogMe(logMe({
      metadata: [ portal ],
      body: {
        Response: {
          path: '/registration/complete',
          params: response
        }
      }
    }));

    await getMe();
  }, [users, getMe]);

  return (
    <RegistrationStateContext.Provider value={state}>
      <RegistrationDispatchContext.Provider value={{ getInvite, confirmInvite, completeSignup }}>
        {children}
      </RegistrationDispatchContext.Provider>
    </RegistrationStateContext.Provider>
  );
}

function useRegistrationState() {
  const context = useContext(RegistrationStateContext);
  if (context === undefined) {
    throw new Error('useRegistrationState must be used within a RegistrationProvider');
  }
  return context;
}

function useRegistrationDispatch() {
  const context = useContext(RegistrationDispatchContext);
  if (context === undefined) {
    throw new Error('useRegistrationDispatch must be used within a RegistrationProvider');
  }
  return context;
}

export function useRegistration(): [RegistrationState, RegistrationDispatch] {
  return [useRegistrationState(), useRegistrationDispatch()];
}
