import React from 'react';
import { Link } from '@material-ui/core';

export default function DealerLinks({ email, phone }: { email: string; phone: string }) {
  return (
    <div>
      <div>
        <Link variant='body1' href={`mailto:${email}`}>
          {email}
        </Link>
      </div>
      <div>
        <Link variant='body1' href={`tel:${phone}`}>
          {phone}
        </Link>
      </div>
    </div>
  );
}
