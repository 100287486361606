import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useCallback } from 'react';
import realUseRouter from 'use-react-router';

export function useToggle(defaultValue: boolean = false) {
  const [value, setValue] = useState(defaultValue);

  return [value, () => setValue(!value)];
}

export function useModal(initialState: boolean = false) {
  const [isOpen, setOpen] = useState(initialState);

  return {
    isOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
}

export function useGoodCallback(fn, args) {
  return invoke(fn, args);
}

const fnCache = new WeakMap();
const noargs = [];

// hack to get around
// https://github.com/facebook/react/issues/14099
// useCallback invalidates as if it's not even being used.
//
// NOTE: depending on how often this gets called, this will be
// a memory leak -- I don't know how to invalidate any of this
//
// NOTE: args can only be an array/object of primitives
function invoke(fn, args) {
  const fnKey = args ? JSON.stringify(args) : noargs;
  // ensure function cache exists
  let cachedFn = fnCache.get(fn);

  if (!cachedFn) {
    fnCache.set(fn, new Map());
    cachedFn = fnCache.get(fn);
  }

  // have we used this arg set before?
  let cachedBoundFn = cachedFn.get(fnKey);
  if (!cachedBoundFn) {
    cachedFn.set(fnKey, () => fn(args));
    cachedBoundFn = cachedFn.get(fnKey);
  }

  return cachedBoundFn;
}

type UseRouterReturn<Params> = RouteComponentProps<Params> & {
  isActivePath(...paths: string[]): boolean;
};

export const useRouter = <Params extends { [K in keyof Params]?: string } = {}>(): UseRouterReturn<
  Params
> => {
  const router = realUseRouter<Params>();

  const isActivePath = useCallback(
    (...paths: string[]) => {
      const currentPath = router.location.pathname;

      for (let i = 0; i < paths.length; i++) {
        let path = paths[i];

        if (path === currentPath) {
          return true;
        }

        if (currentPath.startsWith(path)) {
          return true;
        }
      }

      return false;
    },
    [router]
  );

  return {
    ...router,
    isActivePath,
  };
};
