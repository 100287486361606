import * as Yup from 'yup';
import { useMemo } from 'react';

export function useNotificationSchema(t: TFunction, isVerifyingEmail: boolean) {
  return useMemo(() => generateSchema(t, isVerifyingEmail), [t, isVerifyingEmail]);
}

function requiredFieldMessage(t: TFunction, path: string) {
  return t('notificationsForm.errors.' + path);
}

function generateSchema(t: TFunction, isVerifyingEmail: boolean) {
  return Yup.object({
    contactEmail: Yup.string()
      .email()
      .required(),
    notifyPrimaryEmail: Yup.bool(),
    alternativeEmail: Yup.string().email(requiredFieldMessage(t, 'alternativeEmail')),
    verificationCode: Yup.string().when('alternativeEmail', {
      is: (o) =>
        Yup.string()
          .email()
          .isValidSync(o) &&
        o &&
        isVerifyingEmail,
      then: Yup.string().required(requiredFieldMessage(t, 'verificationCode')),
      otherwise: Yup.string(),
    }),
    notifyAlternativeEmail: Yup.bool(),
    timezone: Yup.string().required(requiredFieldMessage(t, 'timezone')),
    notificationForEvents: Yup.bool(),
    notificationForAlerts: Yup.bool().when('notificationForEvents', {
      is: false,
      then: Yup.bool().oneOf([true], requiredFieldMessage(t, 'notificationType')),
    }),
  });
}
