import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router';
import { paths } from '~paths';
import { Route } from '~/utils/routing';
import { RegionsProvider } from '~/data/regions';
import { TimezonesProvider } from '~/data/timezones';
import Dealers from './dealers';
import Users from './users';
import Generators from './generators';
import Notifications from './notifications';
import TagManager from 'react-gtm-module';

export default function PreferencesIndexRoute() {
  // unset generator-related data-layer variables in GA;
  // preferences pages are not specific to particular generator
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        accountId: undefined,
        siteId: undefined,
        assetId: undefined,
      }
    });
  }, [window.location.pathname])

  return (
    <TimezonesProvider>
      <RegionsProvider>
        <Switch>
          <Route
            exact
            path={paths.preferences.path}
            component={() => <Redirect to={paths.preferences.dealers.path} />}
          />
          <Route path={paths.preferences.dealers.path} component={Dealers} />
          <Route path={paths.preferences.users.path} component={Users} />
          <Route path={paths.preferences.generators.path} component={Generators} />
          <Route path={paths.preferences.notifications.path} component={Notifications} />
        </Switch>
      </RegionsProvider>
    </TimezonesProvider>
  );
}
