import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { ConfirmModal, icons } from 'da-frontend-shared-ui-components';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import Print from 'react-to-print';
import config from '~/config';
import TandC from '~/ui/routes/landing/terms-conditions';
import { recordPreferenceEvent } from '~/utils/gtm';
import { useRouter } from '~/utils/hooks';
import { paths } from '~paths';
import { useStyles } from './styles';

export default function TermsConditions() {
  const [t] = useTranslation();
  const classes = useStyles({});
  const {
    match: {
      params: { inviteCode },
    },
  } = useRouter();

  const [isAccepted, setIsAccepted] = useState(false);
  const toggleAccept = () => setIsAccepted(!isAccepted);

  const componentRef = useRef();

  const registrationUri = useMemo(() => {
    if (inviteCode) {
      return `${config.paths.registration}?code=${inviteCode}`;
    } else {
      return config.paths.registration;
    }
  }, [inviteCode]);

  const [declineModalVisible, setDeclineModalVisible] = React.useState<boolean>(false);
  const [tAndCDeclined, setTAndCDeclined] = React.useState<boolean>(false);

  if (tAndCDeclined) {
    return <Redirect to={paths.root.path} />;
  }

  const handleTAndCDeclined = () => {
    recordPreferenceEvent("Terms & Conditions Declined", `Invite Code: ${inviteCode}`);
    setTAndCDeclined(true);
  }

  return (
    <Grid className={classes.registrationContainer}>
      {declineModalVisible && (
        <ConfirmModal
          title={t('invitations.termsAndConditions.declineModalTitle')}
          message={t('invitations.termsAndConditions.declineModalMessage')}
          confirmButtonText={t('invitations.termsAndConditions.declineConfirmButton')}
          cancelButtonText={t('invitations.termsAndConditions.declineCancelButton')}
          onConfirmClick={handleTAndCDeclined}
          onCancelClick={() => setDeclineModalVisible(false)}
        />
      )}
      <TandC title={t('invitations.termsAndConditions.title')} ref={componentRef} />
      <div className={classes.tandcCheckbox}>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={<Checkbox onClick={toggleAccept} checked={isAccepted} />}
          label={t('invitations.termsAndConditions.accepted')}
        ></FormControlLabel>
      </div>
      <Grid className={classes.tandcActions}>
        <Grid className={classes.tandcButtons}>
          <Button disabled={!isAccepted} color='primary' variant='contained' href={registrationUri}>
            {t('invitations.termsAndConditions.accept')}
          </Button>

          <Button
            variant='contained'
            color='secondary'
            onClick={() => setDeclineModalVisible(true)}
          >
            {t('invitations.termsAndConditions.decline')}
          </Button>
        </Grid>
        <Print
          trigger={() => (
            <Button color='primary' className={classes.tandcPrint}>
              <icons.Print />
              {t('invitations.termsAndConditions.print')}
            </Button>
          )}
          pageStyle='padding: 3rem'
          content={() => componentRef.current}
        />
      </Grid>
    </Grid>
  );
}
