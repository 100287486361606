import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { PageError } from 'da-frontend-shared-ui-components/lib/Errors';

interface IProps {
  style?: CSSProperties;
}

export function NoEvents({ style = {} }: IProps) {
  const { t } = useTranslation();

  return <PageError title={t('errors.generators.events.none')} style={style} />;
}

export function NoAlerts({ style = {} }: IProps) {
  const { t } = useTranslation();

  return <PageError title={t('errors.generators.alerts.none')} style={style} />;
}
