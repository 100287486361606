import { actionCreatorFactory } from 'typescript-fsa';

const createAction = actionCreatorFactory('ASSET/COMMANDS');

const pending = createAction<{ commandId: string; payload?: any }>('PENDING');
const received = createAction<{ commandId: string }>('RECEIVED');
const success = createAction<{ commandId: string }>('SUCCESS');
const failed = createAction<{ commandId: string; error: Error }>('FAILED');
const timeout = createAction<{ commandId: string; error: Error }>('TIMEOUT');
const reset = createAction<{ commandId: string }>('RESET');

export { pending, received, success, failed, timeout, reset };
