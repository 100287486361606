import React, { CSSProperties } from 'react';
import T from '@material-ui/core/Typography';

const style: CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
};

export default function TitleText({ children }) {
  return <T style={style}>{children}</T>;
}
