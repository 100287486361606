import { makeStyles } from '@material-ui/styles';

import { defaultSeparation, spacing } from '~/ui/styles/defaults';

import { defaultTheme } from 'da-frontend-shared-ui-components';
import { white, grayMed } from 'da-frontend-shared-ui-components/lib/styles/colors';

const headerHeight = 110;
export const foo = { MsGridColumns: '250px auto' };

export const useStyles = makeStyles({
  container: {
    marginLeft: `${defaultSeparation}px`,
    marginRight: `${defaultSeparation}px`,

    [defaultTheme.breakpoints.down('sm')]: {
      gridTemplate: `"header" "section"`,
      gridTemplateColumns: 'auto',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    width: '100%',
    display: 'block',
    marginTop: '5rem',
    maxHeight: `calc(100vh - 110px - ${headerHeight}px - 34px)`,
    overflow: 'auto',

    [defaultTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawerNav: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing,
  },
  drawerButton: {
    display: 'none',
    borderRadius: '4px',
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    marginLeft: '-1.875rem',
    cursor: 'pointer',
    background: white,
    border: 'none',
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.5)',
    padding: '1rem 0.5rem',
    [defaultTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  buttonChevron: {
    color: grayMed,
  },
});
