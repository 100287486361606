import { DeviceModelCommand } from 'da-pcc-frontend-shared-domain';
import { useCallback } from 'react';
import { useAPI } from '~/data';
import { RealtimeConnection } from '../realtime';

export default function useCommandType(type: DeviceModelCommand) {
  const { assets } = useAPI();

  return useCallback(
    <T>(
      assetId: string,
      commandId: string,
      { subscriptionId, endpoint }: RealtimeConnection,
      payload?: T
    ) => {
      switch (type) {
        case DeviceModelCommand.StartGenset:
          return assets.startGenset({
            assetId,
            deviceCommandInput: {
              id: commandId,
              subscriptionId,
              endpoint,
            },
          });
        case DeviceModelCommand.StopGenset:
          return assets.stopGenset({
            assetId,
            deviceCommandInput: {
              id: commandId,
              subscriptionId,
              endpoint,
            },
          });
        case DeviceModelCommand.SetStandby:
          return assets.setStandby({
            assetId,
            deviceStandbyCommandInput: {
              id: commandId,
              subscriptionId,
              endpoint,
              ...(payload as any),
            },
          });
        case DeviceModelCommand.StartExercise:
          return assets.startExercise({
            assetId,
            deviceCommandInput: {
              id: commandId,
              subscriptionId,
              endpoint,
            },
          });
        case DeviceModelCommand.StopExercise:
          return assets.stopExercise({
            assetId,
            deviceCommandInput: {
              id: commandId,
              subscriptionId,
              endpoint,
            },
          });
        case DeviceModelCommand.SetExerciseSchedule: {
          return assets.setExerciseScheduleArrow({
            assetId,
            setExerciseScheduleArrowInput: {
              id: commandId,
              subscriptionId,
              endpoint,
              ...(payload as any),
            },
          });
        }
      }
    },
    [assets, type]
  );
}
