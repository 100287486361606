import { Loader } from 'da-frontend-shared-ui-components';
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import { useAPI } from '..';
import { logMe, portal } from '../logger';
import { fetchConsumerAccount } from './actions';
import reducer, { initialState } from './reducer';
import { ConsumerAccountDispatch, ConsumerAccountState } from './types';

const ConsumerAccountStateContext = createContext<ConsumerAccountState>(undefined);
const ConsumerAccountDispatchContext = createContext<ConsumerAccountDispatch>(undefined);

export function ConsumerAccountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { users, logger } = useAPI();

  const getConsumerAccount = useCallback(() => {
    const action = async () => {
      try {
        dispatch(fetchConsumerAccount.started());

        // UI logger API call
        logger.loggerControllerLogMe(logMe({
          metadata: [ portal ],
          body: {
            Request: {
              path: '/myAccount',
              params: {} 
            }
          }
        }));

        const consumerAccount = await users.getMyConsumerAccount();

        logger.loggerControllerLogMe(logMe({
          metadata: [ portal ],
          body: {
            Response: {
              path: '/myAccount',
              params: consumerAccount
            }
          }
        }));
        
        dispatch(fetchConsumerAccount.done({ result: consumerAccount }));
        return consumerAccount;
      } catch (error) {
        dispatch(fetchConsumerAccount.failed({ error }));
      }
    };
    return action();
  }, [users, dispatch]);

  useEffect(() => {
    getConsumerAccount();
  }, [getConsumerAccount]);

  if (state.loading) {
    return <Loader />;
  }

  return (
    <ConsumerAccountStateContext.Provider value={state}>
      <ConsumerAccountDispatchContext.Provider value={{}}>
        {children}
      </ConsumerAccountDispatchContext.Provider>
    </ConsumerAccountStateContext.Provider>
  );
}

export function useConsumerAccountState() {
  const context = useContext(ConsumerAccountStateContext);
  if (context === undefined) {
    throw new Error('useConsumerAccount must be used within a ConsumerAccountProvider');
  }
  return context.consumerAccount;
}

export function useConsumerAccountDispatch() {
  const context = useContext(ConsumerAccountDispatchContext);
  if (context === undefined) {
    throw new Error('useConsumerAccount must be used within a ConsumerAccountProvider');
  }
  return context;
}

export function useConsumerAccount(): [
  ConsumerAccountState['consumerAccount'],
  ConsumerAccountDispatch
] {
  return [useConsumerAccountState(), useConsumerAccountDispatch()];
}
