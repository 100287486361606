import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography as T } from '@material-ui/core';
import { ArrowExerciseSchedule, DayOfWeek } from 'da-pcc-frontend-shared-domain';
import {
  Info,
  Select,
  FormSelect,
  InputContainer,
  Label,
  defaultTheme,
} from 'da-frontend-shared-ui-components';

import { useCurrentGenerator } from '~/data/generator';
import { useDate } from '~/utils/date';
import { toSelectOptions } from '~/utils/collection';

const spacing = defaultTheme.spacing.unit;
const columnStyle: CSSProperties = {
  display: 'grid',
  gridGap: spacing,
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto 1rem auto',
};

const generateDays = (t: TFunction) => {
  return [
    { value: DayOfWeek.sunday, label: t('time.days.sunday') },
    { value: DayOfWeek.monday, label: t('time.days.monday') },
    { value: DayOfWeek.tuesday, label: t('time.days.tuesday') },
    { value: DayOfWeek.wednesday, label: t('time.days.wednesday') },
    { value: DayOfWeek.thursday, label: t('time.days.thursday') },
    { value: DayOfWeek.friday, label: t('time.days.friday') },
    { value: DayOfWeek.saturday, label: t('time.days.saturday') },
  ];
};

function generateHours(t: TFunction) {
  return [...Array(24).keys()].map((i) => ({
    label: `${i + 1}`,
    value: i + 1,
  }));
}

function generateMinutes(t: TFunction) {
  return toSelectOptions([0, 15, 30, 45]);
}

function generateRepeat(t: TFunction) {
  return [
    { value: ArrowExerciseSchedule.NEVER, label: t('exercise.options.never') },
    { value: ArrowExerciseSchedule.WEEKLY, label: t('exercise.options.weekly') },
    { value: ArrowExerciseSchedule.BIMONTHLY, label: t('exercise.options.bimonthly') },
    { value: ArrowExerciseSchedule.MONTHLY, label: t('exercise.options.monthly') },
  ];
}

export function Fields() {
  const { t } = useTranslation();
  const [{ generator }] = useCurrentGenerator();
  const { format } = useDate();
  const {
    status: { lastExercise },
  } = generator;

  const days = useMemo(() => generateDays(t), [t]);
  const hours = useMemo(() => generateHours(t), [t]);
  const minutes = useMemo(() => generateMinutes(t), [t]);
  const repeat = useMemo(() => generateRepeat(t), [t]);

  const sizeProps: any = {
    labelSize: 4,
    fieldSize: 8,
  };

  return (
    <>
      <div style={defaultTheme.typography.body1}>
        <p>{t('exercise.scheduleHeader')}</p>
        <br />
        <br />
      </div>
      <Info
        {...sizeProps}
        label={t('exercise.lastCompleted')}
        value={lastExercise ? format(lastExercise) : t('exercise.lastCompletedNever')}
      />
      <FormSelect
        {...sizeProps}
        name='day'
        label={t('exercise.day')}
        options={days}
        canSelectNone={false}
      />

      <InputContainer
        {...{
          ...sizeProps,
          name: 'at',
          label: (
            <Label>
              <T>{t('exercise.at')}</T>
            </Label>
          ),
          field: (
            <div style={columnStyle}>
              <Select
                name='atHour'
                label={t('exercise.at')}
                options={hours}
                canSelectNone={false}
              />
              <span style={{ textAlign: 'center' }}>{t('exercise.timeSeparator')}</span>
              <Select
                name='atMinute'
                label={t('exercise.at')}
                options={minutes}
                canSelectNone={false}
              />
            </div>
          ),
        }}
      />

      <FormSelect
        {...sizeProps}
        name='repeat'
        label={t('exercise.repeat')}
        options={repeat}
        canSelectNone={false}
      />
      <div style={defaultTheme.typography.body1}>
        <br />
        <br />
        <p>{t('exercise.scheduleFooter')}</p>
      </div>
    </>
  );
}
