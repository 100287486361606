import React from 'react';
import { BigRectButton } from 'da-frontend-shared-ui-components';
import { Link } from 'react-router-dom';

import { paths } from '~paths';

import { useGeneratorsList } from '~/data/generators';

import StatusIcon from '~/ui/components/status-icon';

import { useRouter } from '~/utils/hooks';

import { useNavStyles } from './styles';

export default function Navigation({ afterClick }: { afterClick?: () => void }) {
  const classes = useNavStyles({});
  const { isActivePath } = useRouter();
  const [{ generators }] = useGeneratorsList();

  if (generators.length === 0) {
    return null;
  }

  return (
    <>
      {generators.map((generator) => {
        const isActive = isActivePath(paths.dashboard.generators.show.with({ id: generator.id }));
        const {
          status: { status },
        } = generator;

        return (
          <Link
            key={generator.id}
            to={paths.dashboard.generators.show.with({ id: generator.id })}
            className={classes.link}
            onClick={afterClick}
          >
            <BigRectButton active={isActive} className={classes.navButton}>
              <>
                <StatusIcon status={status} className={classes.icon} />
                {generator.name}
              </>
            </BigRectButton>
          </Link>
        );
      })}
    </>
  );
}
