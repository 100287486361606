import React from 'react';

import ResponsiveContainer from '~/ui/components/responsive-container';

import Header from './-components/header';
import UsersTable from './-components/users-table';

export function List() {
  return (
    <ResponsiveContainer xs={12} lg={8}>
      <Header />

      <UsersTable />
    </ResponsiveContainer>
  );
}
