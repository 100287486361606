import React from 'react';
import { Typography as T } from '@material-ui/core';
import { useStyles } from './styles';

export default function DealerInfo({
  name,
  address,
  isDisabled,
}: {
  name: string;
  address: string;
  isDisabled?: boolean;
}) {
  const classes = useStyles({});

  return (
    <div className={classes.dealerInfoContainer}>
      <T
        variant='h5'
        className={`${classes.dealerInfoName} ${isDisabled ? classes.dealerInfoDisabled : ''}`}
      >
        {name}
      </T>
      <T variant='body1' className={`${isDisabled ? classes.dealerInfoDisabled : ''}`}>
        {address}
      </T>
    </div>
  );
}
