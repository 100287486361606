import React, { useContext, createContext, useMemo } from 'react';
import data from './regions.json';
import { RegionsState } from './types';

const RegionStateContext = createContext<RegionsState | undefined>(undefined);

export function RegionsProvider({ children }) {
  const state = useMemo(() => {
    return data;
  }, []);
  return <RegionStateContext.Provider value={state}>{children}</RegionStateContext.Provider>;
}

function useRegionsState() {
  const context = useContext(RegionStateContext);
  if (context === undefined) {
    throw new Error('useRegionsState must be used within a RegionsProvider');
  }
  return context;
}

export function useCountries(): { value: string; label: string }[] {
  const { countries } = useRegionsState();
  return useMemo(
    () =>
      Object.keys(countries).map((countryCode) => ({
        label: countries[countryCode],
        value: countryCode,
      })),
    [countries]
  );
}

export function useStateOrProvidence(countryCode: string): { value: string; label: string }[] {
  const { regions } = useRegionsState();
  return useMemo(
    () =>
      Object.keys(regions[countryCode] || {}).map((regionCode) => ({
        label: regions[countryCode][regionCode],
        value: regionCode,
      })),
    [countryCode, regions]
  );
}
