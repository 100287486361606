import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Grid, Typography as T, Button } from '@material-ui/core';
import queryString from 'query-string';
import { paths } from '~paths';
import { useRouter } from '~/utils/hooks';
import { useStyles } from './styles';
import Toast from '~/ui/components/toast';
import { Loader } from 'da-frontend-shared-ui-components';
import { useRegistration } from '~/data/registration';
import { useSession } from '~/data/session';
import { useGeneratorsList } from '~/data/generators';
import { recordPreferenceEvent } from '~/utils/gtm';

export default function Confirmation() {
  const [t] = useTranslation();
  const classes = useStyles({});
  const {
    location: { search },
  } = useRouter();
  const inviteCode = useMemo(() => {
    const qs = queryString.parse(search);
    return qs.code as string;
  }, [search]);
  const [{ profile }] = useSession();
  const [
    ,
    {
      confirmInvite: [confirmInviteState, confirmInvite],
      completeSignup: [completeSignupState, completeSignup],
    },
  ] = useRegistration();
  const [
    ,
    {
      fetchAll: [, fetchGenerators],
    },
  ] = useGeneratorsList();

  const [fetchGeneratorsPending, setFetchGeneratorsPending] = useState(false);

  const loading = useMemo(
    () => confirmInviteState.loading || completeSignupState.loading || fetchGeneratorsPending,
    [confirmInviteState, completeSignupState, fetchGeneratorsPending]
  );

  useEffect(() => {
    if (inviteCode) {
      recordPreferenceEvent("Invite Confirmed", `Invite Code: ${inviteCode}`);
      setFetchGeneratorsPending(true);
      confirmInvite(inviteCode)
        .then(() => fetchGenerators())
        .finally(() => setFetchGeneratorsPending(false));
    } else {
      completeSignup();
    }
  }, [completeSignup, confirmInvite, inviteCode, fetchGenerators, setFetchGeneratorsPending]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // we can't be loading here...
  // no inviteCode implies we just completed signup
  if (!inviteCode) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <Grid className={classes.registrationContainer}>
        <Grid className={classes.confirmWelcome}>
          <T align='center' component='h2' variant='h1'>
            {t('common.welcome', { firstName: profile.firstName })}
          </T>
        </Grid>

        <T align='center'>{t('invitations.account.deviceReady')}</T>
        <div className={classes.confirmButton}>
          <Link to={paths.dashboard.generators.path}>
            <Button variant='contained' color='secondary' style={{ marginTop: '2rem' }}>
              {t('invitations.account.goToDashboard')}
            </Button>
          </Link>
        </div>
      </Grid>
      <Toast
        snackbarMessage={t('invitations.account.accepted', {
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
        })}
      />
    </>
  );
}
