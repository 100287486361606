import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchMyDealers } from './actions';
import { DealerReducerState } from './types';

export const initialState: DealerReducerState = {
  myDealersLoading: true,
  managedDealer: undefined,
  requestedDealer: undefined,
  error: undefined,
};

const dealerReducer = reducerWithInitialState(initialState)
  .case(fetchMyDealers.done, (state, { result }) => ({
    ...state,
    requestedDealer: result.requestedDealer,
    managedDealer: result.managingDealer,
    myDealersLoading: false,
  }))
  .case(fetchMyDealers.failed, (state, { error }) => ({
    ...state,
    error,
    myDealersLoading: false,
  }));

export default dealerReducer;
