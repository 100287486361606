import { makeStyles } from '@material-ui/styles';
import {
  grayLight,
  grayDark,
  grayMed,
  green,
  greenBackground,
} from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles({
  container: {
    color: grayDark,
    border: '2px solid',
    padding: '0.3rem 0.5rem',
    margin: '0.2rem 0',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    textAlign: 'left',
  },
  successContainer: {
    backgroundColor: greenBackground,
    borderColor: green,
  },
  processingContainer: {
    backgroundColor: grayLight,
    borderColor: grayMed,
  },
  statusIcon: {
    marginRight: '8px',
    width: 18,
    height: 18,
    position: 'relative',
    bottom: '1px',
  },
  statusIconSuccess: {
    color: green,
  },
});
