import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MuiThemeProvider } from '@material-ui/core';
import { OverrideMaterialUICss } from 'override-material-ui-css';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { APIProvider } from '~/data';
import { i18n } from '~/utils/i18n';
import RootRoute from './routes/root';
import { connectCloudTheme } from './styles/theme';
import { SessionProvider } from '~/data/session/context';
import { useGtm } from '~/utils/gtm';

// Initialize React GTM
useGtm();

interface IProps {
  initialState?: any;
  entryComponent?: React.ComponentType;
  history?: any;
}

const Application = ({ history, entryComponent }: IProps) => {
  const Component = entryComponent ? entryComponent : RootRoute;

  if (!history) {
    history = createBrowserHistory();
  }

  return (
    <I18nextProvider i18n={i18n}>
      <APIProvider>
        <BrowserRouter>
          <MuiThemeProvider theme={connectCloudTheme}>
            <OverrideMaterialUICss>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <SessionProvider>
                  <Component />
                </SessionProvider>
              </SnackbarProvider>
            </OverrideMaterialUICss>
          </MuiThemeProvider>
        </BrowserRouter>
      </APIProvider>
    </I18nextProvider>
  );
};

export default hot(Application);
