import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import T from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Event } from 'da-pcc-frontend-shared-domain';

import { useCurrentGeneratorEvents, NoEvents } from '~/data/generator/events';

import { useDate } from '~/utils/date';

import { defaultSeparation } from '~/ui/styles/defaults';

import { dayStyle, timeStyle, eventStyle, fontStyle } from './styles';

export default function EventsList() {
  const [{ events }] = useCurrentGeneratorEvents();
  const { isToday } = useDate();
  const { t } = useTranslation();

  const todaysEvents = useMemo(
    () =>
      events.filter((event) => {
        return isToday(event.timestamp);
      }),
    [events, isToday]
  );

  const olderEvents = useMemo(
    () =>
      events.filter((event) => {
        return !isToday(event.timestamp);
      }),
    [events, isToday]
  );

  if (events.length === 0) {
    return <NoEvents style={{ marginTop: defaultSeparation }} />;
  }

  return (
    <>
      <Table events={todaysEvents} title={t('common.today')} />
      <Table events={olderEvents} title={t('common.older')} />
    </>
  );
}

function Table({ events, title }: { events: Event[]; title: string }) {
  const { format } = useDate();

  if (events.length === 0) {
    return null;
  }

  return (
    <div style={dayStyle}>
      <T variant='h3' style={timeStyle}>
        {title}
      </T>

      {events.map((event, idx) => {
        return (
          <Grid key={idx} container direction='row' justify='space-between' style={eventStyle}>
            <T style={fontStyle} variant='body1'>
              {event.message || event.severity}
            </T>
            <T style={fontStyle} variant='body1'>
              {format(event.timestamp)}
            </T>
          </Grid>
        );
      })}
    </div>
  );
}
