import { useRef, useEffect, DependencyList } from 'react';

export default function useInterval(callback: Function, delay: number, deps?: DependencyList) {
  const savedCallback = useRef<Function>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    tick();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, ...(deps || [])]);
}
