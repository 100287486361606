import React from 'react';
import { Switch } from 'react-router';
import { paths } from '~paths';
import { Route } from '~/utils/routing';
import { RegistrationProvider } from '~/data/registration';
import Confirmation from './confirmation';
import InviteIndexRoute from './invite';
import TermsConditions from './invite/terms-conditions';

export default function RegistrationIndexRoute() {
  return (
    <RegistrationProvider>
      <Switch>
        <Route exact path={paths.registration.confirmation.path} component={Confirmation} />
        <Route exact path={paths.registration.termsConditions.path} component={TermsConditions} />
        <Route path={paths.invite.show.path} component={InviteIndexRoute} />
      </Switch>
    </RegistrationProvider>
  );
}
