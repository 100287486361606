import React, { CSSProperties } from 'react';
import { useAsync } from 'react-use';
import Loader from 'da-frontend-shared-ui-components/lib/Loader';

export function AsyncWaiter<Result = any, Args extends any[] = any[]>({
  children,
  fn,
  loadingProps,
  ignoreError = false,
}: {
  children?: ({ value: Result }) => JSX.Element;
  fn: (...args: Args | []) => Promise<Result>;
  loadingProps?: { style: CSSProperties };
  ignoreError?: boolean;
}) {
  if (!fn) {
    throw new Error(`Function passed to AsyncWaiter must not be falsey`);
  }

  const state = useAsync(fn, [fn]);
  const { error, loading, value } = state;

  if (error) {
    if (ignoreError) {
      return children({ value: undefined });
    }

    throw error;
  }

  if (loading) {
    return (
      <div {...{ ...(loadingProps || {}) }}>
        <Loader />
      </div>
    );
  }

  return children({ value });
}
