import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentGenerator } from '~/data/generator';

import { iconForStatus } from '~/utils/generator/icon-for-status';

import SmallStatusPanel from '../small-status-panel';

export default function CurrentStatus(props) {
  const [t] = useTranslation();
  const [{ generator }] = useCurrentGenerator();

  const {
    status: { status },
  } = generator;

  const icon = useMemo(() => iconForStatus(status), [status]);

  return (
    <SmallStatusPanel
      {...props}
      Icon={icon}
      label={t('generator.currentStatus')}
      value={t(`generator.operationalStatus.${status}`)}
    />
  );
}
