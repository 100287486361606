import { makeStyles } from '@material-ui/styles';
import { grayMed2, teal } from 'da-frontend-shared-ui-components/lib/styles/colors';

export const useStyles = makeStyles((theme: any) => ({
  name: {
    fontWeight: 'bold',
    '&.invited': {
      color: grayMed2,
    },
  },
  email: {
    color: teal,
  },
  rowTrash: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trash: {
    cursor: 'pointer',
  },
}));
