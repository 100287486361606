import {
  ArrowExerciseSchedule,
  CommandResponse,
  DeviceStatus,
} from 'da-pcc-frontend-shared-domain';
import { AsyncState } from 'react-use/lib/useAsyncFn';

import { NGDICommandAckMessage } from '../realtime';

export interface CommandsState {}

export interface CommandPayload {
  [key: string]: any;
}

export type CommandSuccessfulFn = (status: DeviceStatus, payload?: CommandPayload) => boolean;

export interface CommandsRequestDispatch {
  increaseUpdateFrequency: [AsyncState<void>, () => void];
  subscribeToDeviceStatusAndEvents: [AsyncState<void>, () => void];
  start: [CommandInvocation, () => Promise<void>];
  stop: [CommandInvocation, () => void];
  startExercise: [CommandInvocation, () => void];
  stopExercise: [CommandInvocation, () => void];
  changeStandby: [CommandInvocation, (request: { on: boolean }) => void];
  setExercise: [
    CommandInvocation,
    (request: { date: Date; tzOffset: number; repeat: ArrowExerciseSchedule }) => Promise<void>
  ];
}

export interface CommandsResponseDispatch {
  receivedNGDICommandAckMessage: (message: NGDICommandAckMessage) => void;
}

export const CommandIdle = -1;
export interface CommandInvocation {
  id: string;
  status: CommandResponse | typeof CommandIdle;
  payload?: CommandPayload;
  loading: boolean;
  error?: Error;
}
